import pageViewStyle from 'src/components/View/Page/pageView.style';

const chatViewStyle = (theme) => ({
  root: {
    ...pageViewStyle(theme)?.root,
    height: 'calc(100vh - 88px)',
    padding: theme.spacing(0),
  },
});

export default chatViewStyle;
