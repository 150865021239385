import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg';
import { ReactComponent as SearchCloseIcon } from 'src/assets/images/search-close.svg';

function SidebarSearchField({
  searchText,
  setSearchText,
}) {
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchText('');
  };

  return (
    <Box sx={{ margin: '20px 0px 12px 0' }}>
      <OutlinedInput
        onChange={handleSearchChange}
        placeholder="Search contact"
        value={searchText}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon sx={{ color: '#546E7A' }} />
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              size="small"
              edge="end"
              disabled={!searchText}
              onClick={handleSearchClear}
            >
              <SearchCloseIcon />
            </IconButton>
          </InputAdornment>
        )}
        size="small"
        fullWidth
      />
    </Box>
  );
}

SidebarSearchField.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
};

export default SidebarSearchField;
