/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  CLEAR_CUSTOMER_DATA,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR, CLEAR_CUSTOMER_WAS_DELETED,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  SET_UPDATE_BIRTHDAY_CAMPAIGN_IN_PB,
  SET_CUSTOMER_DETAILS_TAB,
  SET_GO_TO_ORDER_DETAILS,
  CLEAR_GO_TO_ORDER_DETAILS,
  SET_GO_TO_PARTY_DETAILS,
  CLEAR_GO_TO_PARTY_DETAILS,
  SET_FORM_CREATE_UPDATE_CONTACT,
  SET_VALUES_FORM_ADD_TO_PARTY,
  SEND_ADD_PARTY_GUEST,
  SEND_ADD_PARTY_GUEST_SUCCESS,
  SEND_ADD_PARTY_GUEST_ERROR,
  SEND_REMOVE_PARTY_GUEST,
  SEND_REMOVE_PARTY_GUEST_SUCCESS,
  SEND_REMOVE_PARTY_GUEST_ERROR,
  GET_PARTIES_LIST,
  GET_PARTIES_LIST_SUCCESS,
  GET_PARTIES_LIST_ERROR,
  SET_CUSTOMER_ORDERS,
  GET_CUSTOMER_ORDERS,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_ERROR,
  SET_CUSTOMER_ORDERS_QUERY,
  SET_SELECTED_CUSTOMER_ORDERS,
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_ERROR,
  SET_PARTY_GUESTS,
  GET_PARTY_GUESTS,
  GET_PARTY_GUESTS_SUCCESS,
  GET_PARTY_GUESTS_ERROR,
  SET_PARTY_GUESTS_QUERY,
  SET_SELECTED_PARTY_GUESTS,
  SET_VISIBLE_MODAL_FORM_NUMBER,
} from 'src/actions/customerDetailsActions';

const goToOrderDetails = {
  contactId: null,
  wasReturned: false,
  query: {},
};

const goToPartyDetails = {
  contactId: null,
  wasReturned: false,
  query: {},
};

const formAddToParty = {
  loading: false,
  party: '',
};

const formAddCustomerOrder = {
  loading: false,
  order: '',
};

const formRemovePartyGuest = {
  loading: false,
};

const formRemoveCustomerOrder = {
  loading: false,
};

const initialState = {
  customer: {},
  selectedPartyGuests: [],
  selectedCustomerOrders: [],
  customerOrdersQuery: {
    sort: {
      table2: null,
      column1: 'orderDateTime',
      column2: null,
      direction1: 'desc',
      direction2: null,
    }
  },
  partyGuestsQuery: {
    sort: {
      table2: null,
      column1: 'joinedPartyDateTime',
      column2: null,
      direction1: 'asc',
      direction2: null,
    }
  },
  customerWasDeleted: false,
  updateBirthdayCampaignInPB: false,
  loading: false,
  currentNavigationTab: 'details',
  goToOrderDetails,
  goToPartyDetails,
  formCreateUpdateContact: {},
  customerOrders: [],
  customerOrdersCount: 0,
  partyGuests: [],
  partyGuestsCount: 0,
  partyGuestsLoading: false,
  parties: [],
  partiesLoading: false,
  visibleModalFormNumber: null,
  formAddToParty,
  formRemovePartyGuest,
  formAddCustomerOrder,
  formRemoveCustomerOrder,
  currentDateTime: new Date(),
};

const customerDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CUSTOMER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_CUSTOMER_SUCCESS: {
      return produce(state, (draft) => {
        draft.customer = payload?.contact || {};
        draft.loading = false;
      });
    }

    case GET_CUSTOMER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_CUSTOMER_DATA: {
      return produce(state, (draft) => {
        draft.customer = initialState.customer;
      });
    }

    case DELETE_CUSTOMER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case DELETE_CUSTOMER_SUCCESS: {
      return produce(state, (draft) => {
        draft.customer = payload ? initialState.customer : state.customer;
        draft.loading = false;
        draft.customerWasDeleted = true;
      });
    }

    case DELETE_CUSTOMER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case CLEAR_CUSTOMER_WAS_DELETED: {
      return produce(state, (draft) => {
        draft.customerWasDeleted = false;
      });
    }

    case CREATE_CUSTOMER:
    case UPDATE_CUSTOMER: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CREATE_CUSTOMER_SUCCESS:
    case CREATE_CUSTOMER_ERROR:
    case UPDATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_UPDATE_BIRTHDAY_CAMPAIGN_IN_PB: {
      return produce(state, (draft) => {
        draft.updateBirthdayCampaignInPB = payload;
      });
    }

    case SET_CUSTOMER_DETAILS_TAB: {
      return produce(state, (draft) => {
        draft.currentNavigationTab = payload || initialState.currentNavigationTab;
      });
    }

    case SET_GO_TO_ORDER_DETAILS: {
      return produce(state, (draft) => {
        draft.goToOrderDetails = {
          ...state.goToOrderDetails,
          ...payload,
        };
      });
    }

    case CLEAR_GO_TO_ORDER_DETAILS: {
      return produce(state, (draft) => {
        draft.goToOrderDetails = payload ? goToOrderDetails : {
          ...state.goToOrderDetails,
          contactId: null,
        };
      });
    }

    case SET_GO_TO_PARTY_DETAILS: {
      return produce(state, (draft) => {
        draft.goToPartyDetails = {
          ...state.goToPartyDetails,
          ...payload,
        };
      });
    }

    case CLEAR_GO_TO_PARTY_DETAILS: {
      return produce(state, (draft) => {
        draft.goToPartyDetails = payload ? goToPartyDetails : {
          ...state.goToPartyDetails,
          contactId: null,
        };
      });
    }

    case SET_FORM_CREATE_UPDATE_CONTACT: {
      return produce(state, (draft) => {
        draft.formCreateUpdateContact = payload ? {
          ...state.formCreateUpdateContact,
          ...payload,
        } : initialState.formCreateUpdateContact;
      });
    }

    case GET_PARTIES_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.partiesLoading = true;
      });
    }

    case GET_PARTIES_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.parties = payload || {};
        draft.loading = false;
        draft.partiesLoading = false;
      });
    }

    case GET_PARTIES_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.partiesLoading = false;
      });
    }

    case SET_CUSTOMER_ORDERS: {
      return produce(state, (draft) => {
        draft.customerOrders = payload || [];
        draft.selectedCustomerOrders = [];
      });
    }

    case GET_CUSTOMER_ORDERS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_CUSTOMER_ORDERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.customerOrders = payload?.customerOrders || {};
        draft.customerOrdersCount = payload?.count;
        draft.loading = false;
      });
    }

    case GET_CUSTOMER_ORDERS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_CUSTOMER_ORDERS_QUERY: {
      return produce(state, (draft) => {
        draft.customerOrdersQuery = payload ? {
          ...state.customerOrdersQuery,
          ...payload
        } : initialState.customerOrdersQuery;
      });
    }

    case SET_SELECTED_CUSTOMER_ORDERS: {
      return produce(state, (draft) => {
        draft.selectedCustomerOrders = payload || [];
      });
    }

    case GET_ORDERS_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_ORDERS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.orders = payload || {};
        draft.loading = false;
      });
    }

    case GET_ORDERS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.partyGuests = payload || [];
        draft.selectedPartyGuests = [];
      });
    }

    case GET_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.partyGuestsLoading = true;
      });
    }

    case GET_PARTY_GUESTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.partyGuests = payload?.partyGuests || {};
        draft.partyGuestsCount = payload?.count;
        draft.loading = false;
        draft.partyGuestsLoading = false;
      });
    }

    case GET_PARTY_GUESTS_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.partyGuestsLoading = false;
      });
    }

    case SET_PARTY_GUESTS_QUERY: {
      return produce(state, (draft) => {
        draft.partyGuestsQuery = payload ? {
          ...state.partyGuestsQuery,
          ...payload
        } : initialState.partyGuestsQuery;
      });
    }

    case SET_SELECTED_PARTY_GUESTS: {
      return produce(state, (draft) => {
        draft.selectedPartyGuests = payload || [];
      });
    }

    case SET_VISIBLE_MODAL_FORM_NUMBER: {
      return produce(state, (draft) => {
        draft.visibleModalFormNumber = payload || null;
      });
    }

    case SET_VALUES_FORM_ADD_TO_PARTY: {
      return produce(state, (draft) => {
        draft.formAddToParty = payload ? {
          ...state.formAddToParty,
          ...payload,
        } : formAddToParty;
      });
    }

    case SEND_ADD_PARTY_GUEST: {
      return produce(state, (draft) => {
        draft.formAddToParty.loading = true;
      });
    }

    case SEND_ADD_PARTY_GUEST_SUCCESS:
    case SEND_ADD_PARTY_GUEST_ERROR: {
      return produce(state, (draft) => {
        draft.formAddToParty.loading = false;
      });
    }

    case SEND_REMOVE_PARTY_GUEST: {
      return produce(state, (draft) => {
        draft.formRemovePartyGuest.loading = true;
      });
    }

    case SEND_REMOVE_PARTY_GUEST_SUCCESS:
    case SEND_REMOVE_PARTY_GUEST_ERROR: {
      return produce(state, (draft) => {
        draft.formRemovePartyGuest.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default customerDetailsReducer;
