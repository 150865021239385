import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { STYLE_OPTIONS } from 'src/constants';

function ChatsListFilter({
  onChange,
  options,
  value,
  ...props
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
      color="secondary"
      MenuProps={{
        PaperProps: {
          style: {
            padding: '14px 16px 8px 16px',
            borderRadius: '12px',
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '14px',
        },
        '& .MuiSelect-select': {
          paddingY: '4px',
          fontSize: '14px',
          color: STYLE_OPTIONS.COLOR_BLUE,
        },

      }}
      {...props}
    >
      <Box
        sx={{
          marginBottom: '16px',
        }}
      >
        <Typography variant="h5">Show chats</Typography>
      </Box>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            borderRadius: '4px',
            padding: '8px 6px',
            marginBottom: '10px',
            '&:last-child': {
              marginBottom: 0,
            },
          }}
        >
          <Typography variant="body2">
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

ChatsListFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired
};

export default ChatsListFilter;
