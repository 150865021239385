import React from 'react';
import { Box, Typography, SvgIcon } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

function DefaultMessagesView() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          marginBottom: '17px',
          padding: '27px',
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
        }}
      >
        <SvgIcon
          component={DescriptionIcon}
          sx={{
            fontSize: 64,
            color: '#B2AFF6',
          }}
        />
      </Box>
      <Typography
        variant="h6"
        color="textSecondary"
      >
        Start meaningful conversations!
      </Typography>
    </Box>
  );
}

export default DefaultMessagesView;
