import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

const CustomListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 16px',
  borderRadius: '8px',
  backgroundColor: active ? '#f5f5f5' : theme.palette.background.default,
  marginBottom: '8px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

function ContactChatListItem({
  active,
  children,
  ...props
}) {
  return (
    <CustomListItem active={active} {...props}>
      {children}
    </CustomListItem>
  );
}

ContactChatListItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

ContactChatListItem.defaultProps = {
  active: false,
  children: null,
};

export default ContactChatListItem;
