import * as checkHelpers from 'src/helpers/check';
import * as stringHelpers from 'src/helpers/string';
import * as transformHelpers from 'src/helpers/transform';
import * as azure from 'src/helpers/azure';
import * as load from 'src/helpers/load';
import * as formValidation from 'src/helpers/formValidation';
import * as permissions from 'src/helpers/permissions';
import * as formOptions from 'src/helpers/formOptions';

export default {
  ...checkHelpers,
  ...stringHelpers,
  transform: transformHelpers,
  azure,
  load,
  formValidation,
  permissions,
  formOptions,
};
