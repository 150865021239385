import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import helpers from 'src/helpers';
import { withStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { logout } from 'src/actions/accountActions';
import NavBar from 'src/layouts/DashboardLayout/NavBar';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import RedirectLogic from 'src/RedirectLogic';
import Notification from 'src/containers/Notification';
import TOSAndPrivacyStatement from 'src/containers/TOSAndPrivacyStatement';
import AdminSignInAs from 'src/containers/AdminSignInAs';
import ChangeCompanyTitle from 'src/containers/ChangeCompanyTitle';
import EmailTokenManager from 'src/containers/EmailTokenManager';
import ChangeCampaigns from 'src/containers/ChangeCampaigns';
import DashboardLayoutTopMessage from 'src/layouts/DashboardLayout/DashboardLayoutTopMessage';

import {
  setCurrentScrollDashboardLayoutContentPosition,
  clearMovingScrollDashboardLayoutContentToPosition,
} from 'src/actions/scrollActions';

import dashboardLayoutStyle from 'src/layouts/DashboardLayout/dashboardLayout.style';

function DashboardLayout({ children, classes }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const scrollContentRef = useRef();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavOpen, setDesktopNavOpen] = useState(false);
  const wrapperDesktopNavClass = !isDesktopNavOpen ? ' wrapperWithSmallNabBar' : '';

  const {
    currentScrollDashboardLayoutContentPosition,
    moveScrollDashboardLayoutContentToPosition,
  } = useSelector((state) => state?.scroll || {});

  const {
    shouldSave,
  } = currentScrollDashboardLayoutContentPosition || {};

  const {
    x: moveToX,
    y: moveToY,
    shouldStart: shouldStartMoveTo,
  } = moveScrollDashboardLayoutContentToPosition || {};

  const setLogout = async (toDoSomethingInTry = null) => {
    try {
      await dispatch(logout());
      history.push('/');
      if (toDoSomethingInTry) {
        toDoSomethingInTry();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    // call dispatch(saveCurrentScrollDashboardLayoutContentPosition(true));
    // dispatch(saveCurrentScrollPerfectScrollbarTablePosition(true));
    // if you want to save current position
    if (shouldSave && scrollContentRef) {
      const x = helpers.transform.toInt10(scrollContentRef.current.scrollLeft);
      const y = helpers.transform.toInt10(scrollContentRef.current.scrollTop);
      dispatch(setCurrentScrollDashboardLayoutContentPosition(x, y));
    }
  }, [shouldSave, scrollContentRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldStartMoveTo && scrollContentRef) {
      scrollContentRef.current.scrollLeft = helpers.transform.toInt10(moveToX || 0);
      scrollContentRef.current.scrollTop = helpers.transform.toInt10(moveToY || 0);
      dispatch(clearMovingScrollDashboardLayoutContentToPosition());
    }
  }, [shouldStartMoveTo, scrollContentRef]);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        logout={setLogout}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isDesktopNavOpen={isDesktopNavOpen}
        setDesktopNavOpen={setDesktopNavOpen}
        logout={setLogout}
      />
      <div className={`${classes.wrapper}${wrapperDesktopNavClass}`}>
        <div className={classes.contentContainer}>
          <div
            id="firstContentScroll"
            ref={scrollContentRef}
            className={classes.content}
          >
            <DashboardLayoutTopMessage />
            {children}
          </div>
        </div>
      </div>
      <RedirectLogic />
      <AdminSignInAs logout={setLogout} />
      <ChangeCompanyTitle logout={setLogout} />
      <EmailTokenManager />
      <ChangeCampaigns classes={classes} logout={setLogout} />
      <TOSAndPrivacyStatement logout={setLogout} />
      <Notification />
    </div>
  );
}

DashboardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default withStyles(DashboardLayout, dashboardLayoutStyle, { withTheme: true });
