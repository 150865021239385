import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import PerfectScrollbarTable from 'src/components/PerfectScrollbarTable';
import ChatListFilter from 'src/views/management/ChatView/ChatSidebar/ChatList/ChatsListFilter';
import ChatItem from 'src/views/management/ChatView/ChatSidebar/ChatList/ChatItem';
import helpers from 'src/helpers';

const FILTER_TYPES = {
  all: 'all',
  unread: 'unread',
};

function filterChats(chats, contacts, searchText, filterType) {
  let filteredChats = [...chats.allKeys];

  if (searchText) {
    filteredChats = filteredChats.filter((chatKey) => {
      const chat = chats.byKey[chatKey];

      return chat.participantIds.some((id) => {
        const contact = contacts.byId[id];
        const firstName = contact?.firstName || '';
        const lastName = contact?.lastName || '';
        const fullName = `${firstName} ${lastName}`.trim().toLowerCase();

        return fullName.includes(searchText.toLowerCase());
      });
    });
  }

  if (filterType === FILTER_TYPES.unread) {
    filteredChats = filteredChats.filter((chatKey) => {
      const chat = chats.byKey[chatKey];
      return chat.unreadCount > 0;
    });
  }

  return filteredChats;
}

function sortChatsByDate(chats, chatKeys) {
  return chatKeys.sort((a, b) => {
    const chatA = chats.byKey[a];
    const chatB = chats.byKey[b];

    const lastMessageA = chatA.messages?.at(-1);
    const lastMessageB = chatB.messages?.at(-1);

    const timestampMessageA = lastMessageA?.receivedAt || lastMessageA?.scheduledAt;
    const timestampMessageB = lastMessageB?.receivedAt || lastMessageB?.scheduledAt;

    const dateA = timestampMessageA ? moment(timestampMessageA) : moment(0);
    const dateB = timestampMessageB ? moment(timestampMessageB) : moment(0);

    return dateB.diff(dateA);
  });
}

function ChatList({ searchText }) {
  const [selectedFilterOption, setSelectedFilterOption] = useState(FILTER_TYPES.all);
  const { chats, chatsContacts } = useSelector((state) => state?.chat || {});

  const filterOptions = [
    { value: FILTER_TYPES.all, label: 'All' },
    { value: FILTER_TYPES.unread, label: 'Unread' },
  ];

  const filteredChats = filterChats(chats, chatsContacts, searchText, selectedFilterOption);
  const sortedChats = sortChatsByDate(chats, filteredChats);

  return (
    <PerfectScrollbarTable
      options={{ suppressScrollX: true }}
    >
      <Box sx={{ padding: '0 16px' }}>
        <List
          subheader={(
            <ListSubheader
              sx={{
                display: 'flex',
                paddingX: 0,
                paddingBottom: '12px',
              }}
            >
              <ChatListFilter
                value={selectedFilterOption}
                options={filterOptions}
                onChange={(e) => setSelectedFilterOption(e.target.value)}
              />
            </ListSubheader>
          )}
        >
          {helpers.isEmpty(sortedChats) && (
            <Typography
              color="textSecondary"
              sx={{
                padding: '12px',
                textAlign: 'center',
              }}
            >
              No chats found
            </Typography>
          )}
          {!helpers.isEmpty(sortedChats) && sortedChats.map((chatKey) => (
            <ChatItem
              key={chatKey}
              chat={chats.byKey[chatKey]}
            />
          ))}
        </List>
      </Box>
    </PerfectScrollbarTable>
  );
}

ChatList.propTypes = {
  searchText: PropTypes.string.isRequired,
};

export default ChatList;
