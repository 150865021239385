import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
} from '@mui/material';
import ContactChatListItem from 'src/views/management/ChatView/ChatSidebar/styled/ContactChatListItem';
import PhonesList from 'src/views/management/ChatView/ChatSidebar/PhonesList';
import helpers from 'src/helpers';
import {
  ROUTES,
} from 'src/constants';

function ContactItem({
  contact,
  onClick,
}) {
  const contactPhones = helpers.transform
    .toArray([contact?.phone1, contact?.phone2, contact?.phone3])
    .filter((phone) => !helpers.isEmpty(phone));

  return (
    <ContactChatListItem
      component={RouterLink}
      to={`${ROUTES.APP_MANAGEMENT_CHAT}/${contact.id}`}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: '4px',
        }}
      >
        <Typography
          variant="h5"
          color="textPrimary"
        >
          {helpers.transform.arrayToStringWithoutEmpty([contact?.firstName, contact?.lastName], ' ')}
        </Typography>
      </Box>
      <PhonesList phoneNumbers={contactPhones} />
    </ContactChatListItem>
  );
}

ContactItem.propTypes = {
  contact: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContactItem;
