import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ErrorHandler from 'src/components/ErrorHandler';
import {
  setEmailTokenError,
} from 'src/actions/accountActions';
import GetUsersEmailToken from '../GetUsersEmailToken';

function EmailTokenManager({
  isVisible: externalIsVisible,
  emailAddress: externalEmailAddress,
  emailProvider: externalEmailProvider,
  useExternalProps = false,
}) {
  const dispatch = useDispatch();
  const {
    getUsersEmailToken,
    user,
    emailProvider,
    message: errorTitle,
    screenMessage: errorMessage,
  } = useSelector((state) => state.account) || {};

  const email = user?.email;

  // Handle backend errors
  const setBackBackEndError = (error) => {
    dispatch(setEmailTokenError({
      errorTitle: error?.errorTitle || 'An error occurred',
      errorMessage: error?.errorMessage || 'Please try again or contact support.',
    }));
  };

  // Determine which props to use
  const isVisible = useExternalProps ? externalIsVisible : getUsersEmailToken;
  const finalEmailAddress = useExternalProps ? externalEmailAddress : email;
  const finalEmailProvider = useExternalProps ? externalEmailProvider : emailProvider;

  // Create error component
  const showError = useMemo(() => (
    <ErrorHandler
      nonFormError={{
        errorTitle,
        errorMessage,
      }}
    />
  ), [errorTitle, errorMessage]);

  return (
    <GetUsersEmailToken
      isVisible={isVisible}
      emailAddress={finalEmailAddress}
      emailProvider={finalEmailProvider}
      showError={showError}
      setBackBackEndError={setBackBackEndError}
    />
  );
}

EmailTokenManager.propTypes = {
  isVisible: PropTypes.bool,
  emailAddress: PropTypes.string,
  emailProvider: PropTypes.string,
  useExternalProps: PropTypes.bool
};

EmailTokenManager.defaultProps = {
  isVisible: false,
  emailAddress: '',
  emailProvider: '',
  useExternalProps: false
};

export default EmailTokenManager;
