export const SOCKET_EVENTS = {
  CHAT_NEW_MESSAGE: 'receive:chat:new_message',
  CHAT_NEW_SCHEDULED_MESSAGE: 'receive:chat:new_scheduled_message',
  CHAT_MESSAGE_READ: 'receive:chat:message_read',
  CHAT_SCHEDULED_MESSAGE_UPDATE: 'receive:chat:scheduled_message_update',
  CHAT_MESSAGE_DELETE: 'receive:chat:message_delete',
  CHAT_SCHEDULED_MESSAGE_DELETE: 'receive:chat:scheduled_message_delete',
  CHAT_MESSAGE_SENT: 'receive:chat:message_sent',
  CHAT_MESSAGE_DELIVERED: 'receive:chat:message_delivered',
  CHAT_SCHEDULED_MESSAGE_SENT: 'receive:chat:scheduled_message_sent',
  CHAT_TOTAL_UNREAD_COUNT: 'receive:chat:total_unread_count',
};
