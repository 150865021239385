import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import { addNotification } from 'src/actions/notificationActions';
import { NOTIFICATION_STATUS, POST_HEADER } from 'src/constants';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const RESET_PASSWORD = '@account/reset-password';
export const UPDATE_PROFILE = '@account/update-profile';
export const AGREE_TO_TERMS_OF_SERVICE = '@account/agree-to-terms-of-service';
export const GET_ACCOUNT_INFO = '@account/get-account-info';

export const GET_USERS_LIST = '@account/get-users-list';
export const GET_USERS_LIST_SUCCESS = '@account/get-users-list-success';
export const GET_USERS_LIST_ERROR = '@account/get-users-list-error';

export const SIGN_IN_AS_USER = '@account/sign-in-as-user';
export const SIGN_IN_AS_USER_SUCCESS = '@account/sign-in-as-user-success';
export const SIGN_IN_AS_USER_ERROR = '@account/sign-in-as-user-error';

export const SET_USER_WAS_SHOWN_ERRORS = '@account/set-user-was-shown-errors';

export const CHANGE_COMPANY_TITLE = '@account/change-company-title';
export const CHANGE_COMPANY_TITLE_SUCCESS = '@account/change-company-title-success';
export const CHANGE_COMPANY_TITLE_ERROR = '@account/change-company-title-error';

export const GET_EMAIL_TOKEN = '@account/get-email-token';
export const GET_EMAIL_TOKEN_SUCCESS = '@account/get-email-token-success';
export const GET_EMAIL_TOKEN_ERROR = '@account/get-email-token-error';

export const SET_GET_USERS_EMAIL_TOKEN = '@account/set-get-users-email-token';

export const SET_EMAIL_TOKEN_ERROR_MESSAGE = '@account/set-email-token-error-message';

export const CHANGE_CAMPAIGNS = '@account/change-campaigns';
export const CHANGE_CAMPAIGNS_SUCCESS = '@account/change-campaigns-success';
export const CHANGE_CAMPAIGNS_ERROR = '@account/change-campaigns-error';

export const getAccountInfo = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/account/me');
    const error = response?.data?.error;
    if (!error) {
      dispatch({
        type: GET_ACCOUNT_INFO,
        payload: response?.data,
      });
      return;
    }
    dispatch(addNotification(GET_ACCOUNT_INFO, response?.data?.error || 'Server error', NOTIFICATION_STATUS.ERROR));
  } catch (e) {
    dispatch(addNotification(GET_ACCOUNT_INFO, 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export function login(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const {
        email,
        password,
        confirmPassword,
      } = values || {};

      const payload = await authService.loginWithEmailAndPassword(email, password, !!confirmPassword);

      dispatch({
        type: LOGIN_SUCCESS,
        payload
      });
      dispatch(getAccountInfo());
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export const resetPassword = (accountChanges, tryMessage, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    const response = await axios.post('/api/account/pwdchange', accountChanges, POST_HEADER);
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(RESET_PASSWORD, error, NOTIFICATION_STATUS.ERROR));
      return;
    }

    dispatch(addNotification(RESET_PASSWORD, tryMessage, NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(RESET_PASSWORD, error || 'Try again later', NOTIFICATION_STATUS.ERROR));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export function setUserData(payload) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload,
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
}

// export const getAccountInfo = () => async (dispatch) => {
//   try {
//     const response = await axios.get('/api/account/me');
//     const error = response?.data?.error;
//     if (!error) {
//       dispatch({
//         type: GET_ACCOUNT_INFO,
//         payload: response?.data,
//       });
//       return;
//     }
//     dispatch(addNotification(GET_ACCOUNT_INFO, response?.data?.error || 'Server error', NOTIFICATION_STATUS.ERROR));
//   } catch (e) {
//     dispatch(addNotification(GET_ACCOUNT_INFO, 'Server error', NOTIFICATION_STATUS.ERROR));
//   }
// };

export const getUsersList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_LIST,
    });
    const response = await axios.get('/api/account/accountlist');
    const { error, message } = response?.data || {};
    if (error || message) {
      dispatch(addNotification(GET_USERS_LIST_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_USERS_LIST_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_USERS_LIST_SUCCESS,
      payload: response?.data?.users || [],
    });
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(GET_USERS_LIST_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_USERS_LIST_ERROR,
    });
  }
};

export const agreeToTermsOfService = () => async (dispatch) => {
  try {
    const response = await axios.post('/api/account/tosps', {});
    if (response?.data?.success) {
      dispatch(getAccountInfo());
      return;
    }
    dispatch(addNotification(AGREE_TO_TERMS_OF_SERVICE, response?.data?.error || 'Server error', NOTIFICATION_STATUS.ERROR));
  } catch (e) {
    dispatch(addNotification(GET_ACCOUNT_INFO, 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const signInAsUser = (accountChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SIGN_IN_AS_USER,
    });
    const response = await axios.post('/api/account/signinasuser', accountChanges);
    const { error, message } = response?.data || {};

    if (error || message) {
      dispatch(addNotification(SIGN_IN_AS_USER_ERROR, error || message, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SIGN_IN_AS_USER_ERROR,
      });
      return;
    }

    const { accessToken } = response?.data || {};
    authService.setSession(accessToken);

    dispatch({
      type: SIGN_IN_AS_USER_SUCCESS,
      payload: response?.data,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(SIGN_IN_AS_USER_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SIGN_IN_AS_USER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const changeCompanyTitle = (sendData, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_COMPANY_TITLE,
    });
    const {
      companyTitle,
    } = sendData || {};
    const response = await axios.post('/api/accountSettings/changeCompanyTitle', { companyTitle });
    const { error, message } = response?.data || {};

    if (error || message) {
      dispatch(addNotification(CHANGE_COMPANY_TITLE_ERROR, error || message || 'Could not update your title. Try again or Contact Support.', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CHANGE_COMPANY_TITLE_ERROR,
      });
      return;
    }

    dispatch({
      type: CHANGE_COMPANY_TITLE_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(CHANGE_COMPANY_TITLE_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CHANGE_COMPANY_TITLE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const changeCampaign = (sendData, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_CAMPAIGNS,
    });
    const response = await axios.post('/api/accountSettings/changeCampaigns', { sendData });
    const { error, message } = response?.data || {};

    if (error || message) {
      dispatch(addNotification(CHANGE_CAMPAIGNS_ERROR, error || message || 'Could not update your campaigns. Try again or Contact Support.', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CHANGE_CAMPAIGNS_ERROR,
      });
      return;
    }

    dispatch({
      type: CHANGE_CAMPAIGNS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const { error, message } = e?.response?.data || {};
    dispatch(addNotification(CHANGE_CAMPAIGNS_ERROR, error || message || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CHANGE_CAMPAIGNS_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getEmailTokenFromAccessCode = (sendData, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EMAIL_TOKEN,
    });
    const response = await axios.post('/api/accountSettings/emailAccessCode', sendData);
    const { error, message } = response?.data || {};

    if (error || message) {
      dispatch(addNotification(GET_EMAIL_TOKEN_ERROR, error || message || 'Could not set up your email. Try again or Contact Support.', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_EMAIL_TOKEN_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_EMAIL_TOKEN_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      screenMessage,
    } = e?.response?.data || {};
    const messages = {
      screenMessage,
      message,
    };
    dispatch(addNotification(GET_EMAIL_TOKEN_ERROR, message || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_EMAIL_TOKEN_ERROR,
      payload: messages || null,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setGetUsersEmailToken = (show) => ({
  type: SET_GET_USERS_EMAIL_TOKEN,
  payload: show,
});

export const setEmailTokenError = () => ({
  type: SET_EMAIL_TOKEN_ERROR_MESSAGE,
});

export const setUserWasShownErrors = (errors) => (dispatch) => dispatch({
  type: SET_USER_WAS_SHOWN_ERRORS,
  payload: errors,
});
