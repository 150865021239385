import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from 'src/components/ModalDialogMUI6/styled/Dialog';
import ButtonV1 from 'src/components/ModalDialogMUI6/styled/ButtonV1';
import PopConfirm from 'src/components/PopConfirm';
import helpers from 'src/helpers';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function ModalDialogMUI6({
  onClose,
  onOk,
  okButtonProps,
  onClickThirdButton,
  thirdButtonProps,
  isVisible,
  content,
  loading,
  title,
  titleOptions,
  footer,
  closeText,
  okText,
  thirdButtonText,
  closeOnClickOnlyTheButton,
  isHeaderVisible,
  okStyleType,
  closeStyleType,
  thirdButtonStyleType,
  popConfirmProps,
  buttonsListOrder,
  largeModalType,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getTitle = useCallback((options) => isHeaderVisible && (
    <DialogTitle>
      <Box
        component="div"
        sx={{
          padding: '0px 8px',
        }}
      >
        {!options?.isCloseIconHid && (
          <Box
            component="div"
            sx={{
              display: 'block',
              textAlign: 'right',
              width: '100%',
              // alignItems: 'flex-start',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'inline-block',
                marginRight: '-16px',
              }}
            >
              <IconButton
                aria-label="close"
                onClick={onClose}
                size="large"
              >
                <CloseIcon style={{ color: '#C7C7C7' }} />
              </IconButton>
            </Box>
          </Box>
        )}
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              fontSize: '28px',
              fontWeight: 500,
              marginTop: '5px',
              color: '#263238',
            }}
          >
            {title}
          </Box>
        </Box>
      </Box>
    </DialogTitle>
  ), [isHeaderVisible, title, onClose]);

  const closeButton = useMemo(() => (
    <ButtonV1
      key="closeButton"
      onClick={onClose}
      color="primary"
      styleType={closeStyleType}
    >
      {closeText}
    </ButtonV1>
  ), [onClose, closeText, closeStyleType]);

  const okButton = useMemo(() => {
    if (!onOk) {
      return null;
    }

    const okBtn = (
      <ButtonV1
        key="okButton"
        onClick={onOk}
        color="primary"
        disabled={loading}
        styleType={okStyleType || 'ok'}
        {...okButtonProps}
      >
        {okText}
      </ButtonV1>
    );

    return !helpers.isEmpty(popConfirmProps) ? (
      <PopConfirm
        {...popConfirmProps}
      >
        {okBtn}
      </PopConfirm>
    ) : okBtn;
  }, [
    onOk,
    okStyleType,
    okButtonProps,
    okText,
    popConfirmProps,
    loading
  ]);

  const thirdButton = useMemo(() => (
    <ButtonV1
      key="thirdButton"
      onClick={onClickThirdButton}
      color="primary"
      disabled={loading}
      styleType={thirdButtonStyleType || 'white'}
      {...thirdButtonProps}
    >
      {thirdButtonText}
    </ButtonV1>
  ), [
    loading,
    onClickThirdButton,
    thirdButtonStyleType,
    thirdButtonProps,
    thirdButtonText,
  ]);

  const actionButtons = useMemo(() => (helpers.transform.toArray(buttonsListOrder).map((item) => {
    switch (item) {
      case 'close':
        return closeButton;
      case 'ok':
        return okButton;
      case 'third':
        return thirdButton;
      default:
        return null;
    }
  })), [buttonsListOrder, closeButton, okButton, thirdButton]);

  const dialogActions = useMemo(() => (
    <Box
      component="div"
      sx={{
        textAlign: 'center',
        padding: '0px 25px 16px 25px',
        width: '100%',
      }}
    >
      {actionButtons}
    </Box>
  ), [actionButtons]);

  return (
    <Dialog
      fullScreen={fullScreen}
      largeModalType={largeModalType}
      open={isVisible}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      {...!closeOnClickOnlyTheButton ? { onClose } : {}}
    >
      {getTitle(titleOptions)}
      <Box
        component={DialogContent}
        sx={{
          '& .center': {
            textAlign: 'center',
          },
        }}
      >
        <Box
          component="div"
          sx={{
            padding: '0px 8px',
          }}
        >
          {content}
        </Box>
      </Box>
      <DialogActions>
        {footer || dialogActions}
      </DialogActions>
    </Dialog>
  );
}

ModalDialogMUI6.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  titleOptions: PropTypes.object,
  footer: PropTypes.node,
  onOk: PropTypes.func,
  okButtonProps: PropTypes.object,
  onClickThirdButton: PropTypes.func,
  thirdButtonProps: PropTypes.object,
  closeText: PropTypes.string,
  okText: PropTypes.string,
  thirdButtonText: PropTypes.string,
  closeOnClickOnlyTheButton: PropTypes.bool,
  isHeaderVisible: PropTypes.bool,
  okStyleType: PropTypes.oneOf([
    'close',
    'ok',
    'white',
  ]),
  closeStyleType: PropTypes.oneOf([
    'close',
    'ok',
    'white',
  ]),
  thirdButtonStyleType: PropTypes.oneOf([
    'close',
    'ok',
    'white',
  ]),
  popConfirmProps: PropTypes.object,
  buttonsListOrder: PropTypes.array,
  largeModalType: PropTypes.string,
};

ModalDialogMUI6.defaultProps = {
  loading: false,
  title: null,
  titleOptions: {
    top: false,
    isCloseIconHid: false,
  },
  footer: null,
  onOk: null,
  okButtonProps: {},
  onClickThirdButton: null,
  thirdButtonProps: {},
  closeText: 'Close',
  okText: 'Ok',
  closeOnClickOnlyTheButton: false,
  isHeaderVisible: true,
  closeStyleType: 'close',
  popConfirmProps: null,
  largeModalType: null,
  buttonsListOrder: [
    'close',
    'ok',
    // 'third',
  ],
};

export default ModalDialogMUI6;
