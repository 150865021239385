import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import {
  Box,
  Button,
  Chip,
  Popover,
  MenuItem,
  Typography,
} from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogConfirmation from 'src/components/DialogConfarmation';
import MessageBox from 'src/views/management/ChatView/ChatDetails/styled/MessageBox';
import MessageBody from 'src/views/management/ChatView/ChatDetails/styled/MessageBody';
import ModalEditScheduledDateTime from 'src/views/management/ChatView/modals/ModalEditScheduledDateTime';
import ModalEditScheduledMessage from 'src/views/management/ChatView/modals/ModalEditScheduledMessage';
import {
  deleteChatMessage,
  sendOrEditScheduledMessage,
  setCurrentMessage,
} from 'src/actions/chatActions';
import {
  PERMISSIONS,
  STORAGE,
  STYLE_OPTIONS,
} from 'src/constants';
import helpers from 'src/helpers';

const COLOR_GREY_700 = '#8D8D8D';
const { PERMISSIONS_SYSTEM_CODE } = PERMISSIONS;

function ScheduledMessage({
  message,
  userRolePermissions,
  userCanDeleteTexts,
}) {
  const [editScheduleModalVisible, setEditScheduleModalVisible] = useState(false);
  const [updateMessageModalVisible, setUpdateMessageModalVisible] = useState(false);
  const [openedFile, setOpenedFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state?.account || {});

  const { chatKey } = useParams();
  const dispatch = useDispatch();

  const imgSrc = helpers.azure.getImageFileURL(message?.attachment, user?.id, azAccountName, STORAGE.sas, databaseBeingUsed, message?.messageAttachmentContainer, true);
  const isUserMessage = true;
  const isScheduledMessage = true;

  const handleClickEdit = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEdit = () => {
    setAnchorEl(null);
  };

  const handleUpdateScheduledMessage = () => {
    dispatch(setCurrentMessage(message));
    setUpdateMessageModalVisible(true);
    handleCloseEdit();
  };

  const handleOpenEditScheduleModal = () => {
    dispatch(setCurrentMessage(message));
    setEditScheduleModalVisible(true);
    handleCloseEdit();
  };

  const sendNowScheduledMessage = () => {
    const sendData = {
      ...message,
      sendNow: true,
    };
    dispatch(sendOrEditScheduledMessage(sendData, chatKey));
    handleCloseEdit();
  };

  const handleDeleteMessage = () => {
    dispatch(deleteChatMessage(chatKey, message.id, isScheduledMessage));
    handleCloseEdit();
  };

  return (
    <>
      <MessageBox isUser={isUserMessage}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '12px',
                lineHeight: '14px',
              }}
            >
              Scheduled to be sent to
            </Typography>
            <Chip
              size="small"
              icon={<LocalPhoneOutlinedIcon />}
              label={helpers.transform.toPhoneNumber(message?.phone || '')}
              sx={{
                padding: '3px',
                fontSize: '12px',
                backgroundColor: '#F7F8FB',
              }}
            />
          </Box>
          <MessageBody
            isUser={isUserMessage}
            isScheduled={isScheduledMessage}
          >
            <Box
              sx={{
                padding: '8px 8px 8px 16px',
              }}
            >
              {!helpers.isEmpty(message?.attachment) && (
                <Box
                  sx={{
                    height: '200px',
                    width: 'auto',
                    textAlign: 'start',
                    marginBottom: '8px',
                  }}
                  onClick={() => setOpenedFile(imgSrc)}
                >
                  <img
                    alt="Attachment"
                    style={{
                      cursor: 'pointer',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      borderRadius: '8px',
                    }}
                    src={imgSrc}
                  />
                </Box>
              )}
              <Typography
                color={!message?.body?.trim() ? COLOR_GREY_700 : 'inherit'}
                variant="body1"
                sx={{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  fontStyle: !message?.body?.trim() ? 'italic' : 'normal',
                }}
              >
                {(helpers.isEmpty(message?.attachment) && !message?.body?.trim()) ? 'Empty Message' : message?.body}
              </Typography>
            </Box>
          </MessageBody>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              gap: '4px',
              mt: '8px',
              color: COLOR_GREY_700,
            }}
          >
            <ScheduleSendIcon />
            <Typography
              noWrap
              variant="caption"
            >
              {message?.scheduledAt ? `Message scheduled for: ${moment(message.scheduledAt).format('MMM D, h:mm a')}` : ''}
            </Typography>
            <Button
              size="small"
              variant="text"
              color="secondary"
              sx={{
                minWidth: '40px',
                textTransform: 'none',
                padding: '0',
              }}
              onClick={handleClickEdit}
              disableRipple
            >
              Edit
            </Button>
          </Box>
        </Box>
      </MessageBox>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseEdit}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          root: {
            sx: {
              '& .MuiMenuItem-root': {
                padding: '8px 6px',
                borderRadius: '4px',
                marginBottom: '10px',
                '&:last-child': { marginBottom: 0 },
              }
            }
          },
          paper: {
            sx: {
              borderRadius: '12px',
              padding: '12px'
            }
          }
        }}
      >
        <Box
          sx={{ marginBottom: '16px' }}
        >
          <Typography variant="h5">Scheduled message</Typography>
          <Typography
            variant="caption"
            color={COLOR_GREY_700}
          >
            {message?.scheduledAt ? moment(message.scheduledAt).format('MMM D, h:mm a') : ''}
          </Typography>
        </Box>
        {helpers.permissions.doesUserHavePermission(PERMISSIONS_SYSTEM_CODE.CHAT_MESSAGE_UPDATE, userRolePermissions) && (
          <>
            <MenuItem onClick={handleUpdateScheduledMessage}>Update message</MenuItem>
            <MenuItem onClick={handleOpenEditScheduleModal}>Edit schedule</MenuItem>
            <DialogConfirmation
              header="Send Scheduled Message Now"
              title="Are you sure you want to send this scheduled message now?"
              okText="Send now"
              onConfirm={sendNowScheduledMessage}
              onCancel={handleCloseEdit}
            >
              <MenuItem>Send now</MenuItem>
            </DialogConfirmation>
          </>
        )}
        {userCanDeleteTexts && (
          <DialogConfirmation
            header="Delete Scheduled Message"
            title="Are you sure you want to delete this scheduled message?"
            okText="Delete"
            icon={<DeleteOutlineIcon htmlColor={STYLE_OPTIONS.COLOR_RED} />}
            iconType="warning"
            onConfirm={handleDeleteMessage}
            onCancel={handleCloseEdit}
          >
            <MenuItem>Delete message</MenuItem>
          </DialogConfirmation>
        )}
      </Popover>
      {openedFile && (
        <Lightbox
          large={openedFile}
          onClose={() => setOpenedFile(null)}
          hideDownload
          showRotate
        />
      )}
      <ModalEditScheduledDateTime
        isModalVisible={editScheduleModalVisible}
        chatKey={chatKey}
        onCloseModal={() => setEditScheduleModalVisible(false)}
      />
      <ModalEditScheduledMessage
        isModalVisible={updateMessageModalVisible}
        chatKey={chatKey}
        onCloseModal={() => setUpdateMessageModalVisible(false)}
      />
    </>
  );
}

ScheduledMessage.propTypes = {
  message: PropTypes.object.isRequired,
  userRolePermissions: PropTypes.array,
  userCanDeleteTexts: PropTypes.bool,
};

ScheduledMessage.defaultProps = {
  userRolePermissions: [],
  userCanDeleteTexts: false,
};

export default ScheduledMessage;
