import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { STYLE_OPTIONS } from 'src/constants';

const color = STYLE_OPTIONS.COLOR_TEXT;
const fontFamily = STYLE_OPTIONS.FONT_FAMILY;

const CustomDiv = styled('div')(({ theme }) => ({
  padding: '25px 0px',
  width: '100%',
  overflow: 'hidden',

  '& .disabledBox .MuiInputBase-root.Mui-disabled': {
    backgroundColor: '#F4F4F4',
  },

  '& .disabledBox .MuiInputBase-input.Mui-disabled': {
    color,
  },

  '& .disabledBox .MuiInputBase-root.Mui-disabled fieldset': {
    border: 0,
  },

  [theme.breakpoints.down('lg')]: {
    padding: '15px 15px',
    '& label.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      '& .MuiCheckbox-root': {
        marginTop: -9
      }
    }
  },

  [theme.breakpoints.down('sm')]: {
    padding: '12px 0px'
  },

  '& .center': {
    textAlign: 'center'
  },

  '& .formGridGrope': {
    paddingTop: '22px'
  },

  '& .formGridGrope.padding31': {
    paddingTop: '31px'
  },

  '& .formGridGrope.padding38': {
    paddingTop: '38px'
  },

  '& .formGridGropePhone': {
    paddingTop: '7px'
  },

  '& .MuiFormLabel-root': {
    color: '#858585'
  },

  '& div.MuiFormLabel-root': {
    color,
    fontSize: '16px',
    fontWeight: 400,
    fontFamily
  },

  '& .MuiFormLabel-root.Mui-error': {
    color: '#f44336'
  },

  '& .MuiInputLabel-outlined': {
    fontSize: '13px',
    paddingTop: 2
  },

  '& .MuiOutlinedInput-root legend': {
    fontSize: '10px'
  }
}));

function DivForm({
  children,
  ...props
}) {
  return (
    <CustomDiv {...props}>{children}</CustomDiv>
  );
}

DivForm.propTypes = {
  children: PropTypes.node,
};

DivForm.defaultProps = {
  children: null,
};

export default DivForm;
