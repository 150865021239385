import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DialogMUI from '@mui/material/Dialog';
import { STYLE_OPTIONS } from 'src/constants';

function Dialog({
  children,
  largeModalType,
  ...restProps
}) {
  const CustomDialog = useMemo(() => styled(DialogMUI)((props) => ({
    // eslint-disable-next-line react/prop-types
    [props.theme.breakpoints.up('md')]: {
      '& > .MuiDialog-container > .MuiPaper-root': {
        ...largeModalType === '1' ? {
          width: '90%',
          maxWidth: '987px',
        } : {
          width: '706px',
          maxWidth: '800px',
        },
        borderRadius: '20px',
        fontFamily: STYLE_OPTIONS.FONT_FAMILY
      }
    }
  })), [largeModalType]);

  return (
    <CustomDialog {...restProps}>{children}</CustomDialog>
  );
}

Dialog.propTypes = {
  children: PropTypes.node,
  largeModalType: PropTypes.string,
};

Dialog.defaultProps = {
  children: null,
  largeModalType: null,
};

export default Dialog;
