import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@mui/material';
import { STYLE_OPTIONS } from 'src/constants';

function DialogConfirmation({
  onConfirm,
  onCancel,
  children,
  icon,
  iconType,
  header,
  title,
  note,
  okText,
  cancelText,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  const getIconBackgroundColor = () => {
    switch (iconType) {
      case 'warning':
        return '#FDECEC';
      case 'info':
        return '#5850EC';
      default:
        return 'transparent';
    }
  };
  return (
    <>
      {children && React.cloneElement(children, { onClick: handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            padding: '24px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {icon && (
            <Box
              sx={{
                minWidth: 40,
                minHeight: 40,
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: getIconBackgroundColor(),
                borderRadius: '50%',
              }}
            >
              {icon}
            </Box>
          )}
          <Box
            sx={{
              padding: '0 0 0 16px',
              width: '100%',
              textAlign: icon ? 'left' : 'center',
            }}
          >
            <DialogTitle
              sx={{
                fontSize: '18px',
                padding: 0,
              }}
            >
              {header}
            </DialogTitle>
            <Typography
              variant="subtitle1"
              color="#546E7A"
            >
              {title}
            </Typography>
            {note && (
              <Typography variant="body2">
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="medium"
                >
                  NOTE:
                </Typography>
                {' '}
                {note}
              </Typography>
            )}
          </Box>
        </Box>
        <DialogActions
          sx={{
            padding: 0,
            marginTop: '16px',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              ...STYLE_OPTIONS.BUTTON_PRIMARY_REVERSED,
              minWidth: '105px',
            }}
          >
            {cancelText}
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              ...STYLE_OPTIONS.BUTTON_PRIMARY,
              minWidth: '105px',
              marginLeft: '10px',
            }}
          >
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DialogConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.node,
  iconType: PropTypes.oneOf(['warning', 'info']),
  header: PropTypes.string,
  title: PropTypes.string,
  note: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

DialogConfirmation.defaultProps = {
  children: null,
  icon: null,
  iconType: 'warning',
  header: 'Please confirm',
  title: 'Are you sure?',
  note: '',
  okText: 'Confirm',
  cancelText: 'Cancel',
  onCancel: () => { },
};

export default DialogConfirmation;
