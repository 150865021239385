import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GlobalStyles } from 'tss-react';
import Auth from 'src/components/Auth';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { WebSocketProvider } from 'src/context/WebSocketContext';

const history = createBrowserHistory();

const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0
  },
  html: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    height: '100%',
    width: '100%'
  },
  body: {
    height: '100%',
    width: '100%',
  },
  '#root': {
    height: '100%',
    width: '100%'
  }
};

function App() {
  const { settings } = useSettings();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(settings)}>
        <GlobalStyles
          styles={globalStyles}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <WebSocketProvider>
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <ScrollReset />
                    <GoogleAnalytics />
                    <Routes />
                  </GoogleOAuthProvider>
                </WebSocketProvider>
              </Auth>
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
