import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import moment from 'moment';
import {
  Badge,
  Box,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ContactChatListItem from 'src/views/management/ChatView/ChatSidebar/styled/ContactChatListItem';
import PhonesList from 'src/views/management/ChatView/ChatSidebar/PhonesList';
import { markChatAsUnread } from 'src/actions/chatActions';
import helpers from 'src/helpers';
import {
  ROUTES,
} from 'src/constants';

function contactSelector(state, currentChat) {
  const { chatsContacts } = state?.chat || {};
  const contactId = currentChat.participantIds[0];

  return chatsContacts.byId[contactId];
}

const getLastMessageInfo = (lastMessage) => {
  if (!lastMessage) return '';

  const trimmedLastMessageBody = lastMessage?.body?.trim() || '';
  const hasAttachment = !helpers.isEmpty(lastMessage?.attachment);
  const isMessageDeleted = lastMessage?.deletedAt !== null;
  const isEmptyMessage = helpers.isEmpty(trimmedLastMessageBody);
  if (isMessageDeleted) {
    return 'Message Deleted';
  }

  if (isEmptyMessage && hasAttachment) {
    return 'Sent a photo';
  }

  if (isEmptyMessage && !hasAttachment) {
    return <em>Empty Message</em>;
  }

  return lastMessage?.body || '';
};

const getLastMessageTime = (timestamp) => {
  const now = moment();
  const lastMessage = moment(timestamp);

  const diffInHours = now.diff(lastMessage, 'hours');
  const diffInDays = now.diff(lastMessage, 'days');

  if (diffInHours < 2) {
    return lastMessage.format('h:mm a');
  }

  if (diffInDays < 1) {
    return `${diffInHours}h`;
  }

  if (diffInDays < 7) {
    return `${diffInDays}d`;
  }

  if (diffInDays < 365) {
    return lastMessage.format('D MMM');
  }

  return lastMessage.format('D MMM YY');
};

function ChatItem({ chat }) {
  const [contextMenu, setContextMenu] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const contact = useSelector((state) => contactSelector(state, chat));
  const {
    key,
    messages,
    scheduledMessages,
    unreadCount,
  } = chat || {};
  const chatUnreadCount = useMemo(() => (unreadCount > 0 ? unreadCount : 0), [unreadCount]);
  const lastMessage = messages?.at(-1);
  const isChatActive = location.pathname === `${ROUTES.APP_MANAGEMENT_CHAT}/${key}`;
  const statusObj = helpers.transform.jsonToObject(lastMessage?.status);
  const isSendingError = !!statusObj?.error;

  const contactPhones = helpers.transform
    .toArray([contact?.phone1, contact?.phone2, contact?.phone3,])
    .filter((phone) => !helpers.isEmpty(phone));

  const goTo = useCallback((url) => history.push(url), [history]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleMarkChatAsUnread = () => {
    const doWhenTry = () => {
      if (isChatActive) {
        goTo(ROUTES.APP_MANAGEMENT_CHAT);
      }
    };
    dispatch(markChatAsUnread(key, doWhenTry()));
    handleClose();
  };

  return (
    <div
      onContextMenu={(event) => {
        handleContextMenu(event);
      }}
      style={{ cursor: 'context-menu' }}
    >
      <ContactChatListItem
        component={RouterLink}
        to={`${ROUTES.APP_MANAGEMENT_CHAT}/${key}`}
        active={isChatActive}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            marginBottom: '6px',
          }}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {helpers.transform.arrayToStringWithoutEmpty([contact?.firstName, contact?.lastName], ' ')}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            {!helpers.isEmpty(lastMessage) ? getLastMessageTime(lastMessage?.scheduledAt || lastMessage?.receivedAt) : ''}
          </Typography>
        </Box>
        {!helpers.isEmpty(contactPhones) && <PhonesList phoneNumbers={contactPhones} />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: chatUnreadCount ? '20px' : 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {isSendingError && (
              <ErrorIcon
                sx={{
                  marginRight: '4px',
                  fontSize: '14px',
                  color: '#F15642',
                }}
              />
            )}
            <Typography
              variant="body2"
              sx={{
                textOverflow: 'ellipsis',
                color: '#546E7A',
              }}
              noWrap
            >
              {getLastMessageInfo(lastMessage)}
            </Typography>
          </Box>
          <Badge
            badgeContent={chatUnreadCount}
            invisible={!chatUnreadCount}
            color="secondary"
            sx={{
              marginLeft: '30px',
              '& .MuiBadge-badge': {
                right: 10,
                top: 10,
              }
            }}
          />
        </Box>
        {!helpers.isEmpty(scheduledMessages) && (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              fontStyle: 'italic',
              color: '#8D8D8D',
            }}
          >
            <ScheduleSendIcon fontSize="small" />
            <Typography
              variant="caption"
              sx={{
                lineHeight: '24px',
              }}
            >
              Scheduled message
            </Typography>
          </Box>
        )}
      </ContactChatListItem>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          disabled={chatUnreadCount > 0}
          onClick={handleMarkChatAsUnread}
        >
          Mark as unread
        </MenuItem>
      </Menu>
    </div>
  );
}

ChatItem.propTypes = {
  chat: PropTypes.object.isRequired,
};

export default ChatItem;
