// // @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Grid,
  SvgIcon,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
// import googleSignInButton from 'src/assets/images/google-sign-in-button.svg';
// import microsoftSignInButton from 'src/assets/images/microsoft-sign-in-button.svg';
import { ReactComponent as GoogleSignInButton } from 'src/assets/images/google-sign-in-button.svg';
import { ReactComponent as MicrosoftSignInButton } from 'src/assets/images/microsoft-sign-in-button.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import { StyledBox } from './styles';

function GrantEmailAccess({
  loading,
  emailAddress,
  emailProvider,
  companyName,
  calledFrom,
  showError,
  onAuthSuccess,
  setBackEndError,
  errorMessage,
}) {
  const handleError = (error) => {
    setBackEndError({
      errorTitle: error.message || 'An error occurred',
      errorMessage: error.screenMessage || 'Please try again or contact support.',
    });
  };

  const shouldShowContactButton = errorMessage && errorMessage.includes('Please contact support through our website');

  const handleContactSupport = () => {
    window.open('https://connectionpointsoftware.com/connect-with-us', '_blank');
  };

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (response) => {
      onAuthSuccess(response);
    },
    onError: (error) => {
      handleError(error);
    },
    scope: [
      'https://www.googleapis.com/auth/gmail.readonly'
    ].join(' ')
  });

  const handleGoogleLogin = () => {
    loginWithGoogle();
  };

  const handleAuthCode = async (code) => {
    try {
      const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/microsoft/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code })
      });

      if (result.ok) {
        const response = await result.json();
        onAuthSuccess(response);
      } else {
        throw new Error('Failed to authenticate with backend');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      const state = Math.random().toString(36).substring(7);
      const authUrlToUse = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
      const params = new URLSearchParams({
        client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
        response_type: 'code',
        redirect_uri: `${window.location.origin}/auth/microsoft/callback`,
        scope: 'Mail.Read offline_access User.Read',
        state,
        prompt: 'consent'
      });

      const fullUrl = `${authUrlToUse}?${params.toString()}`;
      const popup = window.open(fullUrl, 'Microsoft Login', 'width=600,height=600');

      const handleMessage = (event) => {
        try {
          // console.log(`\nevent.data = ${event.data ? JSON.stringify(event.data, null, 2) : 'null'}\n`);
          if (event.origin !== window.location.origin) {
            return;
          }

          // try {
          const data = JSON.parse(event.data);
          if (data.source !== 'react-devtools-bridge') {
            const code = new URLSearchParams(data).get('code');
            if (code) {
              handleAuthCode(code);
              popup.close();
              window.removeEventListener('message', handleMessage);
            }
          }
        } catch (error) {
          // Ignore non-JSON messages
        }
      };

      window.addEventListener('message', handleMessage);

      const interval = setInterval(() => {
        try {
          if (popup.closed) {
            // console.log('Inside setInterval, if popup.closed');
            clearInterval(interval);
            window.removeEventListener('message', handleMessage);
          } else {
            // console.log('Inside setInterval, else popup.closed');
            const url = popup.location.href;
            // console.log(`\nInside setInterval, url = ${url}\n`);
            const code = new URL(url).searchParams.get('code');
            // console.log(`\nInside setInterval, code = ${code}\n`);
            if (code) {
              // console.log('\nInside setInterval, if code.  About to call onAuthSuccess.\n');
              onAuthSuccess(code);
              popup.close();
              clearInterval(interval);
            }
          }
        } catch (error) {
          // console.log(`\nInside setInterval, catch error:\n${error}\n`);
          // Ignore cross-origin errors
        }
      }, 100); // 1/10th of a second.  1 second is far too long.
    } catch (error) {
      // console.log(`\nInside handleMicrosoftLogin, catch error:\n${error}\n`);
      handleError(error);
    }
  };

  const signInToEmailPrompt = () => {
    if (emailProvider === 'Gmail') {
      handleGoogleLogin();
    } else if (emailProvider === 'Microsoft') {
      handleMicrosoftLogin();
    }
  };

  const emailDomain = emailAddress
    ? emailAddress.split('@')[1].split('.')[0].charAt(0).toUpperCase()
    + emailAddress.split('@')[1].split('.')[0].slice(1)
    : '';

  const getHeaderText = () => {
    switch (calledFrom) {
      case 'Signup':
        return `Access Your ${emailDomain} Email`;
      case 'SignIn':
        return `Sign In With ${emailDomain}`;
      case 'Change Email Address':
        return `Verify Your ${emailDomain} Account`;
      default:
        return `Access Your ${emailDomain} Email`;
    }
  };

  const getInstructionsText = () => {
    switch (calledFrom) {
      case 'Signup':
        return (
          <>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: '#333333',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
              }}
            >
              {`Please sign in to your ${emailDomain} email to continue.`}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: '#333333',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
                fontStyle: 'italic',
                mb: 1,
              }}
            >
              {`To complete your signup, we need to verify you are a consultant with ${companyName || 'the company you selected'}.`}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#666666',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
                mb: 2,
                '& br': {
                  display: 'block',
                  content: '""',
                  marginTop: '0.5em'
                }
              }}
            >
              {`We will then use this sign-in to access your ${emailDomain} emails to pull in your orders, parties, and new customer and team member information.`}
              <br />
              This provides the automation that so many have come to LOVE about Connection Point!
            </Typography>
          </>
        );
      case 'SignIn':
        return (
          <>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: '#333333',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
                mb: 2,
              }}
            >
              Please sign in to your email to continue.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: '#333333',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
                fontStyle: 'italic',
                mb: 2,
              }}
            >
              CP now receives your orders, parties, new team members, and other automation information by pulling your emails rather than you needing to forward them!
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#666666',
                textAlign: 'center',
                maxWidth: '480px',
                mx: 'auto',
                mb: 4,
                '& br': {
                  display: 'block',
                  content: '""',
                  marginTop: '0.5em'
                }
              }}
            >
              We will pull in your order, parties, new customer, and team member emails.
              <br />
              This will provide a much, much better experience for everyone!
            </Typography>
          </>
        );
      // Add other cases as needed
      default:
        return null;
    }
  };

  // Determine which button to render based on errorMessage
  const renderButton = () => {
    if (shouldShowContactButton) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleContactSupport}
            disabled={loading}
            startIcon={<LaunchIcon />}
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            Contact Support...
          </Button>
        </Box>
      );
    }

    // Default email sign-in button
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          disabled={loading}
          onClick={() => signInToEmailPrompt()}
          sx={{
            p: 0,
            minWidth: '90px',
            width: '90px',
            bgcolor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              bgcolor: 'transparent'
            }
          }}
        >
          <SvgIcon
            component={emailProvider === 'Gmail' ? GoogleSignInButton : MicrosoftSignInButton}
            viewBox={emailProvider === 'Gmail' ? '0 0 60 60' : '0 0 90 90'}
            sx={{
              height: emailProvider === 'Gmail' ? '60px' : '90px',
              width: 'auto',
              position: 'relative',
              left: emailProvider === 'Gmail' ? '-45%' : '-65%',
              margin: 'auto'
            }}
          />
        </Button>
      </Box>
    );
  };

  return (
    <StyledBox>
      <Box>
        <Typography
          align="center"
          sx={{
            fontSize: '1.125rem',
            fontWeight: 600,
            color: 'primary.main',
            mb: 2
          }}
        >
          {getHeaderText()}
        </Typography>

        {getInstructionsText()}

        {showError && (
          <Grid
            xs={12}
            item
          >
            {showError}
          </Grid>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {/* <Button
            disabled={loading}
            onClick={() => signInToEmailPrompt()}
            sx={{
              p: 0,
              // minWidth: 'auto',
              minWidth: '90px',
              width: '90px',
              bgcolor: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'transparent'
              }
            }}
          >
            <SvgIcon
              component={emailProvider === 'Gmail' ? GoogleSignInButton : MicrosoftSignInButton}
              // viewBox="0 0 90 90"
              viewBox={emailProvider === 'Gmail' ? '0 0 60 60' : '0 0 90 90'}
              // sx={{ height: '90px', width: 'auto' }}
              sx={{
                height: emailProvider === 'Gmail' ? '60px' : '90px',
                width: 'auto',
                position: 'relative',
                left: emailProvider === 'Gmail' ? '-45%' : '-65%',
                margin: 'auto'
              }}
            />
          </Button> */}
          {renderButton()}
        </Box>
      </Box>

    </StyledBox>
  );
}

GrantEmailAccess.propTypes = {
  loading: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string.isRequired,
  emailProvider: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  calledFrom: PropTypes.string.isRequired,
  showError: PropTypes.node,
  onAuthSuccess: PropTypes.func.isRequired,
  setBackEndError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

GrantEmailAccess.defaultProps = {
  companyName: '',
  errorMessage: '',
};

export default GrantEmailAccess;
