import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Typography,
  Zoom,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ErrorIcon from '@mui/icons-material/Error';
import ImputEndAdornment from 'src/views/management/ChatView/NewMessage/ImputEndAdornment';
import ModalScheduleMessage from 'src/views/management/ChatView/modals/ModalScheduleMessage';
import PhoneSelector from 'src/views/management/ChatView/PhoneSelector';
import AttachedFilePrewiew from 'src/views/management/ChatView/NewMessage/AttachedFilePrewiew';
import {
  addMessage,
  clearFilteredChatMessages,
  setFileUploadToMessage,
} from 'src/actions/chatActions';
import { addNotification } from 'src/actions/notificationActions';
import {
  PERMISSIONS,
  NOTIFICATION_STATUS,
  STORAGE,
  STYLE_OPTIONS
} from 'src/constants';
import helpers from 'src/helpers';

const VALIDATE_MESSAGE = {
  validateFileSize: 'File size must be less than 1.4Mb',
  validateFileType: 'Must be a jpg, jpeg, png, or gif file',
};

const { PERMISSIONS_SYSTEM_CODE } = PERMISSIONS;

function MessageAdd({
  children,
  chat,
  showScrollButton,
  setShowScheduledMessages,
  scrollMessagesToBottom,
  userRolePermissions,
}) {
  const dispatch = useDispatch();
  const [manuallySelectedNumber, setManuallySelectedNumber] = useState('');
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state.account);
  const { customer: contact } = useSelector((state) => state?.customerDetails || {});
  const { currentMessage, messagesFilteredByPhone, isMessageSending } = useSelector((state) => state?.chat || {});
  const {
    fileUpload = false,
  } = currentMessage || {};

  const lastChatMessage = chat.messages.at(-1);
  const contactPhones = helpers.transform
    .toArray([contact?.phone1, contact?.phone2, contact?.phone3])
    .filter((phone) => !helpers.isEmpty(phone));
  const isPhonesListEmpty = !!contact?.id && helpers.isEmpty(contactPhones);

  const { getInputProps, open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
  });

  const getLastMessagePhoneNumber = () => {
    if (lastChatMessage && contactPhones.includes(lastChatMessage?.phone)) {
      return lastChatMessage.phone;
    }
    return null;
  };

  const initialValues = useMemo(() => ({
    contactId: contact.id,
    number: manuallySelectedNumber || getLastMessagePhoneNumber() || contact?.phone1 || contact?.phone2 || contact?.phone3,
    message: '',
    attachment: null,
    messageAttachmentContainer: null,
    scheduledDateTime: null,
  }), [chat.key, lastChatMessage, contact, manuallySelectedNumber]);

  const validationSchema = Yup.object().shape({
    number: Yup.string(),
    message: Yup.string(),
    scheduledDateTime: Yup.date('Invalid date')
      .nullable(true)
      .typeError('Invalid date')
      .min(new Date(), 'Date must be in the future')
      .max(new Date(2100, 1, 1, 0, 0, 0), 'Date must be before 2100'),
    attachment: Yup.string()
      .nullable()
      .test('invalidAttachmentSize', VALIDATE_MESSAGE.validateFileSize, () => helpers.azure.validateFileSize(fileUpload))
      .test('invalidAttachmentType', VALIDATE_MESSAGE.validateFileType, () => helpers.azure.validateFileType(fileUpload)),
  });

  const fileWasNotUpload = () => {
    dispatch(addNotification('file upload', 'Your file wasn\'t uploaded', NOTIFICATION_STATUS.ERROR));
  };

  const fileWasNotDelete = () => {
    dispatch(addNotification('file delete', 'Your file wasn\'t deleted', NOTIFICATION_STATUS.ERROR));
  };

  const handleSetFocusField = useCallback((fieldName) => {
    if (fieldName) {
      const inputElements = document.getElementsByName(fieldName);
      if (inputElements.length > 0) {
        inputElements[0].focus(); // {preventScroll: true} doesn't work
      }
    }
  }, []);

  const onSubmit = async (values, {
    resetForm,
    setErrors,
    setStatus,
    setSubmitting,
  }) => {
    setSubmitting(true);
    const doWhenTry = (isScheduled) => {
      if (!isScheduled) {
        setShowScheduledMessages(false);
      }
      dispatch(setFileUploadToMessage(false));
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      scrollMessagesToBottom();
      handleSetFocusField('message');
    };
    const doWhenCatch = (error, errorMessage) => {
      setStatus({ success: false });
      setErrors({ submit: errorMessage || error?.message || 'Try again later' });
      setSubmitting(false);
    };

    const newValuesAttachment = await helpers.azure.loadFileToAzureAndGetStatusCode(fileUpload, values.attachment, user.id, azAccountName, STORAGE.sas, databaseBeingUsed, values.messageAttachmentContainer, fileWasNotUpload, fileWasNotDelete, false, 'EO-ID');
    if (newValuesAttachment === null) {
      dispatch(addNotification('saveFile', 'There are some problems with Azure service.', NOTIFICATION_STATUS.ERROR));
      return;
    }

    const messageAttachmentContainer = helpers.isEmpty(newValuesAttachment)
      ? null
      : values.messageAttachmentContainer || helpers.azure.getUserContainerName(databaseBeingUsed, user.id);

    const isScheduledMessage = !helpers.isEmpty(values.scheduledDateTime);

    const sendData = {
      contactId: values.contactId,
      phone: values.number,
      body: values.message,
      attachment: newValuesAttachment,
      messageAttachmentContainer,
      scheduledAt: !helpers.isEmpty(values.scheduledDateTime) ? moment(values.scheduledDateTime).utc() : null,
    };
    dispatch(addMessage(isScheduledMessage, sendData, chat.key, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    values,
    status,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const isLoading = isSubmitting || isMessageSending;

  const validateFileInput = (uploadFileData) => {
    if (!helpers.azure.validateFileSize(uploadFileData)) {
      dispatch(addNotification('attachment-size', VALIDATE_MESSAGE.validateFileSize, NOTIFICATION_STATUS.ERROR));
      return false;
    }
    if (!helpers.azure.validateFileType(uploadFileData)) {
      dispatch(addNotification('attachment-type', VALIDATE_MESSAGE.validateFileType, NOTIFICATION_STATUS.ERROR));
      return false;
    }
    return true;
  };

  const updateFileUploadToMessage = (value) => {
    dispatch(setFileUploadToMessage(value));
    setFieldValue('messageAttachmentContainer', null, false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
      if (!values?.message?.trim() && !fileUpload) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleOnSelectedFile = async (event) => {
    const { target: { files, value } } = event;
    if (!files || !value) {
      return;
    }
    const uploadFileData = {
      file: files[0],
      value
    };
    if (validateFileInput(uploadFileData)) {
      dispatch(setFileUploadToMessage(uploadFileData));
    }
  };

  const handleChangePhoneNumber = (e) => {
    const selectedPhoneNumber = e.target.value;
    if (!helpers.isEmpty(messagesFilteredByPhone)) {
      dispatch(clearFilteredChatMessages());
    }
    setManuallySelectedNumber(selectedPhoneNumber);
  };

  useEffect(() => {
    if (!isSubmitting && status?.success) {
      handleSetFocusField('message');
    }
  }, [isSubmitting, status]);

  useEffect(() => {
    if (!helpers.isEmpty(messagesFilteredByPhone)) {
      setManuallySelectedNumber('');
      setFieldValue('number', messagesFilteredByPhone);
    }
  }, [messagesFilteredByPhone]);

  return (
    <form
      id="chatMessageForm"
      onSubmit={handleSubmit}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: '0 16px', md: '0 36px' },
      }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            marginY: '8px',
          }}
        >
          <Box
            sx={{
              marginRight: '12px',
            }}
          >
            {children}
            {(!helpers.isEmpty(contactPhones) && contactPhones.length > 1) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  marginTop: '8px',
                }}
              >
                <Typography
                  htmlFor="number"
                  variant="h6"
                  color="textPrimary"
                >
                  Send to:
                </Typography>
                <PhoneSelector
                  id="number"
                  name="number"
                  value={values.number}
                  options={contactPhones}
                  onChange={handleChangePhoneNumber}
                />
              </Box>
            )}
          </Box>
          {fileUpload && (
            <AttachedFilePrewiew
              fileName={helpers.azure.getImageFileURL(values.attachment, user.id, azAccountName, STORAGE.sas, databaseBeingUsed, values.messageAttachmentContainer)}
              fileUpload={fileUpload}
              isLoading={isLoading}
              validateFileInput={validateFileInput}
              setFileUploadToMessage={updateFileUploadToMessage}
            />
          )}
          <Zoom
            in={showScrollButton}
            sx={{
              marginLeft: 'auto',
              border: '1px solid',
              '&:hover': {
                backgroundColor: '#A1A1F5',
                color: '#fff',
              },
            }}
          >
            <IconButton
              aria-label="scroll chat to bottom"
              size="small"
              color="secondary"
              onClick={scrollMessagesToBottom}
            >
              <ArrowBackIosNewIcon
                fontSize="inherit"
                sx={{
                  transform: 'rotate(-90deg)',
                }}
              />
            </IconButton>
          </Zoom>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '16px',
          }}
        >
          <OutlinedInput
            name="message"
            color="secondary"
            placeholder="Write something..."
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={values.message}
            disabled={isPhonesListEmpty || isLoading || !helpers.permissions.doesUserHavePermission(PERMISSIONS_SYSTEM_CODE.CHAT_MESSAGE_CREATE, userRolePermissions)}
            size="small"
            maxRows={4}
            endAdornment={(
              <ImputEndAdornment
                isLoading={isLoading || !helpers.permissions.doesUserHavePermission(PERMISSIONS_SYSTEM_CODE.CHAT_MESSAGE_CREATE, userRolePermissions)}
                isUploadDisabled={isPhonesListEmpty}
                isScheduleDisabled={!values.message?.trim() && !fileUpload}
                openUploadDialog={open}
                openScheduleDialog={() => setScheduleModalVisible(true)}
              />
            )}
            autoFocus
            fullWidth
            multiline
          />
          <input {...getInputProps({ onChange: handleOnSelectedFile })} />
          <Button
            type="submit"
            disabled={(!values.message?.trim() && !fileUpload) || isLoading || !helpers.permissions.doesUserHavePermission(PERMISSIONS_SYSTEM_CODE.CHAT_MESSAGE_CREATE, userRolePermissions)}
            sx={{
              ...STYLE_OPTIONS.BUTTON_PRIMARY,
              width: '80px',
              height: '38px',
              fontWeight: 500,
            }}
          >
            Send
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            minHeight: '20px',
            marginTop: '4px',
          }}
        >
          {isPhonesListEmpty && (
            <>
              <ErrorIcon
                sx={{
                  marginRight: '4px',
                  fontSize: '16px',
                  color: '#F15642',
                }}
              />
              <Typography
                variant="caption"
                color="#F15642"
              >
                The contact does not have a phone number.
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <ModalScheduleMessage
        isModalVisible={scheduleModalVisible}
        header="Set Scheduled Date & Time"
        onCloseModal={() => setScheduleModalVisible(false)}
        formik={formik}
      />
    </form>
  );
}

MessageAdd.propTypes = {
  chat: PropTypes.object.isRequired,
  scrollMessagesToBottom: PropTypes.func.isRequired,
  setShowScheduledMessages: PropTypes.func.isRequired,
  children: PropTypes.node,
  showScrollButton: PropTypes.bool,
  userRolePermissions: PropTypes.array,
};

MessageAdd.defaultProps = {
  children: null,
  showScrollButton: false,
  userRolePermissions: [],
};

export default MessageAdd;
