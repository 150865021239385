import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Checkbox } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldFormik from 'src/components/TextFieldFormik/';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import helpers from 'src/helpers';

import autocompleteFieldFormikStyle from 'src/components/AutocompleteFieldFormik/autocompleteFieldFormik.style';

function AutocompleteFieldFormik({
  classes,
  setFieldValue,
  showInfo,
  isShowInfoV2,
  touchedValue,
  errorValue,
  handleChange,
  handleBlur,
  disabledBox,
  values,
  filterOptions,
  name,
  label,
  disabled,
  options,
  inlineTextValidation,
  handleFocus,
  multiple,
  disableCloseOnSelect,
  renderOption,
  required,
  ...props
}) {
  const [fieldStateError, setFieldStateError] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const optionList = helpers.transform.arrayStringToObjectLabelValue(options);

  const focusStart = (event) => {
    if (handleFocus) {
      const newEvent = {
        ...event,
        dataName: name,
      };
      handleFocus(newEvent);
    }
  };

  const validateField = (value) => {
    if (!inlineTextValidation) {
      return;
    }
    Yup.reach(inlineTextValidation, name).validate(helpers.transform.toArray(value))
      .then(() => {
        setFieldStateError(false);
      })
      .catch((e) => {
        setFieldStateError(helpers.transform.toArray(e?.errors)[0]);
      });
  };

  const handleChangeAutocomplete = (event, value) => {
    setFieldValue(name, value);
    validateField(value);
  };

  const handleChangeTextInAutocomplete = (event) => {
    const { value = '' } = event?.target || {};
    handleChange(event);
    validateField(value);
  };

  const handleBlurTextInAutocomplete = (event) => {
    handleBlur(event);
    setFieldStateError(false);
  };

  const handleFocusTextInAutocomplete = (event) => {
    setFieldStateError(false);
    focusStart(event);
  };

  const renderOptionFunc = (renderProps, option, { selected }) => (
    <li key={option} {...renderProps}>
      <Checkbox
        className={classes.checkbox}
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
        color="secondary"
      />
      {option.label}
    </li>
  );

  const getOptionLabel = (option) => {
    if (option) {
      if (option.label) {
        return option.label;
      } if (option.length !== 0) {
        return option;
      }
      return '';
    }
    return option?.label || option;
  };

  const filterOptionsOrNot = filterOptions ? {
    filterOptions,
  } : {};

  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      options={optionList}
      {...filterOptionsOrNot}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption || renderOptionFunc}
      renderInput={(params) => (
        <TextFieldFormik
          touchedValue={touchedValue || errorValue || fieldStateError}
          errorValue={errorValue || fieldStateError}
          handleChange={handleChangeTextInAutocomplete}
          handleBlur={handleBlurTextInAutocomplete}
          onFocus={handleFocusTextInAutocomplete}
          label={label}
          showInfo={showInfo}
          disabledBox={disabledBox}
          isShowInfoV2={isShowInfoV2}
          required={required}
          {...params}
        />
      )}
      value={values}
      onChange={handleChangeAutocomplete}
      required={required}
      {...props}
    />
  );
}

AutocompleteFieldFormik.propTypes = {
  classes: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  filterOptions: PropTypes.func,
  disabled: PropTypes.bool,
  touchedValue: PropTypes.any,
  errorValue: PropTypes.any,
  label: PropTypes.string,
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  isShowInfoV2: PropTypes.bool,
  disabledBox: PropTypes.bool,
  inlineTextValidation: PropTypes.any,
  handleFocus: PropTypes.func,
  multiple: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  renderOption: PropTypes.func,
  required: PropTypes.bool,
};

AutocompleteFieldFormik.defaultProps = {
  disabled: false,
  touchedValue: null,
  errorValue: null,
  values: [],
  filterOptions: null,
  showInfo: null,
  isShowInfoV2: false,
  disabledBox: false,
  inlineTextValidation: null,
  handleFocus: null,
  multiple: false,
  disableCloseOnSelect: false,
  renderOption: null,
  required: false,
};

export default withStyles(AutocompleteFieldFormik, autocompleteFieldFormikStyle);
