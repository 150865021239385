import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import {
  Box,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogConfirmation from 'src/components/DialogConfarmation';
import TooltipMUI6 from 'src/components/TooltipMUI6';
import MessageBox from 'src/views/management/ChatView/ChatDetails/styled/MessageBox';
import MessageBody from 'src/views/management/ChatView/ChatDetails/styled/MessageBody';
import { deleteChatMessage } from 'src/actions/chatActions';
import { STORAGE, STYLE_OPTIONS } from 'src/constants';
import helpers from 'src/helpers';

const COLOR_GREY_700 = '#8D8D8D';
const COLOR_RED_ERROR = '#F15642';
const FONT_14 = '14px';
const TIMESTAMP_FORMAT_MESSAGE = 'MM/DD/YYYY h:mm a';
const TIMESTAMP_FORMAT_MESSAGE_SHORT = 'h:mm a';
const TIMESTAMP_FORMAT_DELETED_MESSAGE = 'MMM D, h:mm A';

const getMessageTimeStamp = (message) => {
  if (message?.isReceived) {
    return message?.receivedAt ? moment(message.receivedAt).format(TIMESTAMP_FORMAT_MESSAGE) : '';
  }
  return message?.scheduledAt ? moment(message.scheduledAt).format(TIMESTAMP_FORMAT_MESSAGE) : '';
};

const getMessageStatusIcon = (message) => {
  const style = {
    fontSize: FONT_14,
    color: COLOR_GREY_700
  };

  const getFormattedStatusTimestamp = (timestamp) => (
    moment(timestamp).isSame(message?.scheduledAt, 'day')
      ? moment(timestamp).format(TIMESTAMP_FORMAT_MESSAGE_SHORT)
      : moment(timestamp).format(TIMESTAMP_FORMAT_MESSAGE));

  const statusObj = helpers.transform.jsonToObject(message?.status);
  const isSendingError = !!statusObj?.error;

  const sentAt = message?.sentAt ? getFormattedStatusTimestamp(message.sentAt) : 'Not Sent Yet';
  const deliveredAt = message?.deliveredAt ? getFormattedStatusTimestamp(message.deliveredAt) : 'Not Delivered Yet';
  const readAt = message?.readAt ? getFormattedStatusTimestamp(message.readAt) : 'Not Read Yet';

  if (!helpers.isEmpty(message?.readAt)) {
    return (
      <TooltipMUI6
        title={(
          <>
            {`Delivered at: ${deliveredAt}`}
            <br />
            {`Read at: ${readAt}`}
          </>
        )}
      >
        <DoneAllIcon
          color="secondary"
          sx={{ fontSize: FONT_14 }}
        />
      </TooltipMUI6>
    );
  }

  if (!helpers.isEmpty(message?.deliveredAt)) {
    return (
      <TooltipMUI6
        title={`Delivered  at: ${deliveredAt}`}
      >
        <DoneAllIcon sx={style} />
      </TooltipMUI6>
    );
  }

  if (!helpers.isEmpty(message?.sentAt) && !isSendingError) {
    return (
      <TooltipMUI6
        title={`Sent at: ${sentAt}`}
      >
        <CheckIcon sx={style} />
      </TooltipMUI6>
    );
  }

  if (isSendingError) {
    return (
      <>
        <Typography
          variant="caption"
          color={COLOR_RED_ERROR}
        >
          {statusObj.error}
        </Typography>
        <ErrorIcon
          sx={{
            fontSize: FONT_14,
            color: COLOR_RED_ERROR,
          }}
        />
      </>
    );
  }

  return null;
};

const getDeleteConfirmationNote = (message) => {
  if (message?.isReceived) {
    return 'it will not be deleted from the sender`s chat history.';
  }

  if (!helpers.isEmpty(message?.sentAt)) {
    return 'it will not be deleted from the recipient`s chat history.';
  }

  return '';
};

function Message({
  message,
  showPhoneNumber,
  userCanDeleteTexts,
}) {
  const [openedFile, setOpenedFile] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state?.account || {});
  const { chatKey } = useParams();
  const dispatch = useDispatch();

  const imgSrc = helpers.azure.getImageFileURL(message?.attachment, user?.id, azAccountName, STORAGE.sas, databaseBeingUsed, message?.messageAttachmentContainer, true);
  const statusObj = helpers.transform.jsonToObject(message?.status);
  const isUserMessage = !message?.isReceived;
  const isMessageDeleted = !!message?.deletedAt;
  const isMessageSendingError = !!statusObj?.error;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDeleteMessage = () => {
    dispatch(deleteChatMessage(chatKey, message.id));
    handleClose();
  };

  return (
    <>
      <MessageBox isUser={isUserMessage}>
        <Box>
          {showPhoneNumber && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '12px',
                  lineHeight: '14px',
                }}
              >
                {isUserMessage ? 'Sent to:' : 'Received from: '}
              </Typography>
              <Chip
                size="small"
                icon={<LocalPhoneOutlinedIcon />}
                label={helpers.transform.toPhoneNumber(message?.phone || 'Unknown number')}
                sx={{
                  padding: '3px',
                  fontSize: '12px',
                  backgroundColor: '#F7F8FB',
                }}
              />
            </Box>
          )}
          <div
            onContextMenu={(event) => {
              if (!isMessageDeleted && userCanDeleteTexts) {
                handleContextMenu(event);
              }
            }}
            style={{ cursor: 'context-menu' }}
          >
            <MessageBody
              isUser={isUserMessage}
              isError={isMessageSendingError}
            >
              <Box
                sx={{
                  padding: isUserMessage ? '8px 8px 8px 16px' : '8px 16px 8px 8px',
                }}
              >
                {(!helpers.isEmpty(message?.attachment) && !isMessageDeleted) && (
                  <Box
                    sx={{
                      height: '200px',
                      width: 'auto',
                      textAlign: 'start',
                      marginBottom: '8px',
                    }}
                    onClick={() => setOpenedFile(imgSrc)}
                  >
                    <img
                      alt="Attachment"
                      style={{
                        cursor: 'pointer',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        borderRadius: '8px',
                      }}
                      src={imgSrc}
                    />
                  </Box>
                )}
                {isMessageDeleted ? (
                  <TooltipMUI6
                    title={(
                      <>
                        <Box>
                          <Typography variant="h5">Deleted message </Typography>
                          <Typography
                            variant="caption"
                            color={COLOR_GREY_700}
                          >
                            {moment(message.deletedAt).format(TIMESTAMP_FORMAT_DELETED_MESSAGE)}
                          </Typography>
                        </Box>
                        {isUserMessage && (
                          <Box
                            sx={{ marginTop: '10px' }}
                          >
                            <Typography variant="body2">
                              {message?.sentAt ? `- Sent at: ${moment(message.sentAt).format(TIMESTAMP_FORMAT_DELETED_MESSAGE)}` : '- Not Sent Yet'}
                            </Typography>
                            <Typography variant="body2">
                              {message?.deliveredAt ? `- Received at: ${moment(message.deliveredAt).format(TIMESTAMP_FORMAT_DELETED_MESSAGE)}` : '- Not Received Yet'}
                            </Typography>
                            <Typography variant="body2">
                              {message?.readAt ? `- Read at: ${moment(message.readAt).format(TIMESTAMP_FORMAT_DELETED_MESSAGE)}` : '- Not Read Yet'}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '4px',
                        color: COLOR_GREY_700,
                      }}
                    >
                      <DeleteForeverIcon />
                      <Typography
                        variant="body1"
                        fontStyle="italic"
                      >
                        {`This message was deleted${(message?.sentAt || message?.receivedAt) ? ' only for you' : ''}`}
                      </Typography>
                    </Box>
                  </TooltipMUI6>
                ) : (
                  <Typography
                    color={!message?.body?.trim() ? COLOR_GREY_700 : 'inherit'}
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                      fontStyle: !message?.body?.trim() ? 'italic' : 'normal',
                    }}
                  >
                    {(helpers.isEmpty(message?.attachment) && !message?.body?.trim()) ? 'Empty Message' : message?.body}
                  </Typography>
                )}
              </Box>
            </MessageBody>
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <DialogConfirmation
                header="Delete Message"
                title="Are you sure you want to delete this message?"
                note={getDeleteConfirmationNote(message)}
                okText="Delete"
                icon={<DeleteOutlineIcon htmlColor={STYLE_OPTIONS.COLOR_RED} />}
                iconType="warning"
                onConfirm={handleDeleteMessage}
                onCancel={handleClose}
              >
                <MenuItem>Delete message</MenuItem>
              </DialogConfirmation>
            </Menu>
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
              alignItems: 'center',
              gap: '4px',
              mt: '8px',
            }}
          >
            <Typography
              color={COLOR_GREY_700}
              variant="caption"
            >
              {getMessageTimeStamp(message)}
            </Typography>
            {!message?.isReceived && !message?.deletedAt && getMessageStatusIcon(message)}
          </Box>
        </Box>
      </MessageBox>
      {openedFile && (
        <Lightbox
          large={openedFile}
          onClose={() => setOpenedFile(null)}
          hideDownload
          showRotate
        />
      )}
    </>
  );
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  showPhoneNumber: PropTypes.bool,
  userCanDeleteTexts: PropTypes.bool
};

Message.defaultProps = {
  showPhoneNumber: false,
  userCanDeleteTexts: false,
};

export default Message;
