import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { socketChatTotalUnreadCount } from 'src/actions/chatActions';
import { useWebSocket } from 'src/context/WebSocketContext';
import Badge from '@mui/material/Badge';
import { SOCKET_EVENTS } from 'src/constants/socket';

function ChatBadge({ children, className }) {
  const dispatch = useDispatch();
  const socketService = useWebSocket();
  const { totalUnreadCount } = useSelector((state) => state?.chat || {});

  const badgeContent = useMemo(() => (totalUnreadCount > 0 ? totalUnreadCount : 0), [totalUnreadCount]);

  const handleChatTotalUnreadCount = (message) => {
    dispatch(socketChatTotalUnreadCount(message));
  };

  useEffect(() => {
    if (socketService) {
      socketService.on(SOCKET_EVENTS.CHAT_TOTAL_UNREAD_COUNT, handleChatTotalUnreadCount);
    }

    return () => {
      if (socketService) {
        socketService.off(SOCKET_EVENTS.CHAT_TOTAL_UNREAD_COUNT, handleChatTotalUnreadCount);
      }
    };
  }, [socketService]);

  return (
    <Badge
      className={className}
      badgeContent={badgeContent}
      color="secondary"
      sx={{
        '& .MuiBadge-badge':
        {
          fontSize: '10px',
          width: '15px',
          minWidth: '15px',
          height: '15px',
        },
      }}
    >
      {children}
    </Badge>
  );
}

ChatBadge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ChatBadge;
