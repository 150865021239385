import {
  isEmpty,
  isArray,
} from 'src/helpers/check';

export const doesUserHavePermission = (permissionToCheck, userPermissionList) => {
  if (isEmpty(permissionToCheck) || !isArray(userPermissionList)) {
    return false;
  }

  return !!userPermissionList.includes(permissionToCheck);
};

export const doesUserHaveOneOfPermissions = (permissionToCheckList, userPermissionList) => {
  if (!isArray(permissionToCheckList) || !isArray(userPermissionList)) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const permission of permissionToCheckList) {
    if (doesUserHavePermission(permission, userPermissionList)) {
      return true;
    }
  }

  return false;
};
