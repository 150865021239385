/* eslint-disable no-param-reassign */
import produce from 'immer';
import objFromArray from 'src/utils/objFromArray';
import helpers from 'src/helpers';
/**
 * Updates messages in both the main chat and the filtered chat (if they exist).
 * Chats are filtered by phone number on the backend, and this filtering does not affect the main chat list.
 * As a result, filtered chat is stored separately and must be updated independently, alongside the main chat.
 *
 * @param {Object} draft - The current state being modified using Immer.
 * @param {string} chatKey - The key of the chat to search for in `draft.chats.byKey` and `draft.filteredChats.byKey`.
 * @param {Function} updateFn - The function that is applied to the chat (or filtered chat) to update its messages.
 *
 * This function searches for the chat by `chatKey` in two places:
 * - The main chat list (`draft.chats.byKey`).
 * - The filtered chat list (`draft.filteredChats.byKey`), if it exists.
 *
 * If a chat is found in either of these lists, the `updateFn` function is applied to it to update its data.
 */
const updateChatMessages = (draft, chatKey, updateFn) => {
  const chat = draft?.chats?.byKey?.[chatKey];
  const filteredChat = draft?.filteredChats?.byKey?.[chatKey];

  if (chat) {
    draft.chats.byKey[chatKey] = produce(chat, updateFn);
  }

  if (filteredChat) {
    draft.filteredChats.byKey[chatKey] = produce(filteredChat, updateFn);
  }
};

const updateChatMessagesProcessFnAddNew = (messages, scheduledMessages) => (draft) => {
  if (messages.length) {
    messages.forEach((message) => {
      if (!draft.messages.find((item) => item?.id && item.id === message?.id)) {
        draft.messages.push(message);
        if (message?.isReceived) {
          const unreadCount = helpers.transform.toInt10(draft.unreadCount) + 1;
          draft.unreadCount = unreadCount > 0 ? unreadCount : 0;
        }
      }
    });
  }
  if (scheduledMessages.length) {
    draft.scheduledMessages.push(...scheduledMessages);
    draft.scheduledNextCursor = draft.scheduledMessages.at(-1)?.scheduledAt;
    if (draft.scheduledMessages.length > 1) {
      draft.scheduledMessages.sort((a, b) => new Date(a?.scheduledAt || 0) - new Date(b?.scheduledAt || 0));
    }
  }
};

const updateChatMessagesProcessFnDelete = (messageId, isScheduledMessage, updates) => (draft) => {
  if (isScheduledMessage) {
    draft.scheduledMessages = draft.scheduledMessages.filter((message) => message.id !== messageId);
  } else {
    draft.messages = draft.messages.map((message) => (
      updates[message.id] ? { ...message, ...updates[message.id] } : message
    ));
  }
};

const updateChatMessagesProcessFnUpdate = (messageId, updates) => (draft) => {
  draft.messages = draft.messages.map((message) => (
    updates[message.id] ? { ...message, ...updates[message.id] } : message
  ));
};

const updateChatScheduledMessagesProcessFnUpdate = (isSendNow, updatedMessage) => (draft) => {
  if (isSendNow) {
    draft.scheduledMessages = draft.scheduledMessages.filter((message) => message.id !== updatedMessage?.id);
    draft.messages.push(updatedMessage);
  } else {
    draft.scheduledMessages = draft.scheduledMessages.map((message) => (
      message.id === updatedMessage?.id ? updatedMessage : message
    ));
    if (draft.scheduledMessages.length > 1 && !isSendNow) {
      draft.scheduledMessages.sort((a, b) => new Date(a?.scheduledAt || 0) - new Date(b?.scheduledAt || 0));
    }
  }
};

const addChatMessagesIfNotExistingChat = (draft, payloadOptions) => {
  const {
    chatKey: chatKeyOption,
    messages,
    scheduledMessages,
    receivedMessagesCount,
    contact,
  } = payloadOptions || {};
  const contactId = contact?.id;
  const chatKey = chatKeyOption || contactId;

  if (!chatKey || !contactId) {
    return;
  }

  const existingChat = {
    key: chatKey,
    messages,
    scheduledMessages,
    participantIds: [contactId],
    unreadCount: receivedMessagesCount || 0,
  };

  draft.chats.byKey[chatKey] = existingChat;
  draft.chats.allKeys.push(chatKey);
  if (contact?.id) {
    draft.chatsContacts.allIds.push(contact.id);
    draft.chatsContacts.byId[contact.id] = contact;
  }
  if (draft.messagesFilteredByPhone) {
    draft.filteredChats.byKey[chatKey] = existingChat;
  }
};

/* ********************************************************************************** */

export const getMoreChatMessagesSuccessHandlers = (state, payload) => {
  const {
    contactId,
    data
  } = payload;
  const {
    messages = [],
    nextCursor = null,
  } = data || {};

  return produce(state, (draft) => {
    updateChatMessages(draft, contactId, (chat) => {
      chat.messages.unshift(...messages);
      chat.nextCursor = nextCursor;
    });
    draft.isMessagesLoading = false;
  });
};

export const addMessageSuccessHandlers = (state, payload) => {
  const {
    chatKey,
    messages,
    scheduledMessages,
    // contact,
  } = payload;

  return produce(state, (draft) => {
    if (!draft?.chats?.byKey?.[chatKey]) {
      addChatMessagesIfNotExistingChat(draft, payload);
    } else {
      updateChatMessages(draft, chatKey, updateChatMessagesProcessFnAddNew(messages, scheduledMessages));
    }
    draft.isMessageSending = false;
  });
};

export const socketChatNewMessageHandlers = (state, payload) => produce(state, (draft) => {
  const newPayload = Array.isArray(payload?.contacts) ? payload.contacts : [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of newPayload) {
    const {
      id: chatKey,
      messages: messagesList,
      ...restOfContact
    } = item || {};

    const messages = Array.isArray(messagesList) ? messagesList.filter(({ isScheduled }) => !isScheduled) : [];
    const scheduledMessages = Array.isArray(messagesList) ? messagesList.filter(({ isScheduled }) => !!isScheduled) : [];
    const receivedMessagesCount = Array.isArray(messagesList) ? messagesList.filter(({ isReceived }) => !!isReceived).length : 0;

    if (!draft?.chats?.byKey?.[chatKey]) {
      addChatMessagesIfNotExistingChat(
        draft,
        {
          chatKey,
          messages,
          scheduledMessages,
          receivedMessagesCount,
          contact: {
            ...restOfContact,
            id: chatKey,
          },
        }
      );
    } else {
      updateChatMessages(draft, chatKey, updateChatMessagesProcessFnAddNew(messages, scheduledMessages));
    }
  }
});

export const socketChatMessageDeleteHandlers = (state, payload) => produce(state, (draft) => {
  const newPayload = Array.isArray(payload?.contacts) ? payload.contacts : [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of newPayload) {
    const {
      id: chatKey,
      messages,
    } = item || {};
    const updates = objFromArray(messages);

    // eslint-disable-next-line no-restricted-syntax
    for (const message of messages) {
      const {
        id: messageId,
        isScheduled,
      } = message;
      updateChatMessages(draft, chatKey, updateChatMessagesProcessFnDelete(messageId, isScheduled, updates));
    }
  }
});

export const socketChatScheduledMessageDeleteHandlers = (state, payload) => produce(state, (draft) => {
  const newPayload = Array.isArray(payload?.deletedMessageIds) ? payload.deletedMessageIds : [];
  const updates = objFromArray(newPayload);

  // eslint-disable-next-line no-restricted-syntax
  for (const item of newPayload) {
    const {
      id: messageId,
      contactId: chatKey,
      isScheduled,
    } = item || {};
    updateChatMessages(draft, chatKey, updateChatMessagesProcessFnDelete(messageId, isScheduled, updates));
  }
});

export const sendNowOrEditScheduledMessageSuccessHandlers = (state, payload) => {
  const {
    chatKey,
    isSendNow,
    updatedMessage,
  } = payload;

  return produce(state, (draft) => {
    updateChatMessages(draft, chatKey, updateChatScheduledMessagesProcessFnUpdate(isSendNow, updatedMessage));
    draft.isMessageSending = false;
  });
};

export const socketChatScheduledMessageUpdateHandlers = (state, payload) => produce(state, (draft) => {
  const newPayload = Array.isArray(payload?.contacts) ? payload.contacts : [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of newPayload) {
    const {
      id: chatKey,
      messages,
    } = item || {};

    // eslint-disable-next-line no-restricted-syntax
    for (const message of messages) {
      const {
        isScheduled,
      } = message;
      updateChatMessages(draft, chatKey, updateChatScheduledMessagesProcessFnUpdate(!isScheduled, message));
    }
  }
});

export const socketChatMessageUpdateHandlers = (state, payload) => produce(state, (draft) => {
  const {
    contacts,
    isMessageReadStatusChanged,
  } = payload || {};
  const newPayload = Array.isArray(contacts) ? contacts : [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of newPayload) {
    const {
      id: chatKey,
      messages,
    } = item || {};
    const updates = objFromArray(messages);

    if (isMessageReadStatusChanged) {
      const isMessageRead = Array.isArray(messages) ? !!messages[0]?.readAt : false;
      const messagesCount = Array.isArray(messages) ? messages.length : 0;
      updateChatMessages(draft, chatKey, (draftChat) => {
        const unreadCount = isMessageRead
          ? helpers.transform.toInt10(draftChat.unreadCount) - messagesCount
          : helpers.transform.toInt10(draftChat.unreadCount) + messagesCount;
        draftChat.unreadCount = unreadCount >= 0 ? unreadCount : 0;
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const message of messages) {
      const {
        id: messageId,
        // isScheduled,
      } = message;
      updateChatMessages(draft, chatKey, updateChatMessagesProcessFnUpdate(messageId, updates));
    }
  }
});

export const deleteChatMessageSuccessHandlers = (state, payload) => {
  const {
    chatKey,
    messageId,
    isScheduledMessage,
    updatedMessages
  } = payload;
  const updates = objFromArray(updatedMessages);

  return produce(state, (draft) => {
    updateChatMessages(draft, chatKey, updateChatMessagesProcessFnDelete(messageId, isScheduledMessage, updates));
    draft.isMessageUpdating = false;
  });
};
