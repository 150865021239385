export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const SCREEN = {
  SM: 600,
  MD: 960
};

export const STORAGE = {
  // sas: '?sv=2020-02-10&ss=bf&srt=sco&sp=rwdlacx&se=2022-04-02T00:08:09Z&st=2021-04-01T16:08:09Z&spr=https&sig=5j09uEMaQCWq7tCRNwgiqw7MUb5Ryux7cH7MUqKEUfk%3D',
  // sas: '?sv=2020-08-04&ss=bf&srt=sco&sp=rwdlacitfx&se=2025-03-08T08:44:00Z&st=2022-03-08T04:00:00Z&spr=https&sig=HOL%2FI71Oiauyg3nEbJ3i5opBOZfL%2FW7Fgow38iQLiQE%3D',
  sas: '?sv=2022-11-02&ss=bf&srt=sco&sp=rwdlaciytfx&se=2026-03-09T00:42:03Z&st=2025-03-07T16:42:03Z&spr=https&sig=vxsNmdPge%2B0hPNd%2FoXhPC8AVKsV%2FdmPtXRDud6IQiAE%3D',
  container: '-salesperson-',
};

export const POST_HEADER = {
  headers: {
    apiKey: process.env.REACT_APP_DATA_API_KEY,
  }
};

export const FORM_POST_HEADER = {
  headers: {
    apiKey: process.env.NEXT_PUBLIC_FORM_KEY || process.env.REACT_APP_FORM_KEY,
  }
};

export const DATE_TIME_FORMAT = 'MM/DD/yyyy hh:mma';

export const REQUIRE_SUBSCRIPTION_DATE = '2022-06-06';
