import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  Container,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useWebSocket } from 'src/context/WebSocketContext';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import ChatSidebar from 'src/views/management/ChatView/ChatSidebar';
import ChatDetails from 'src/views/management/ChatView/ChatDetails';
import {
  getChatsList,
  getContacts,
  socketChatNewMessage,
  socketChatNewScheduledMessage,
  socketChatMessageDelete,
  socketChatScheduledMessageDelete,
  socketChatScheduledMessageUpdate,
  socketChatMessageSent,
  socketChatMessageRead,
  socketChatScheduledMessageSent,
  socketChatMessageDelivered,
} from 'src/actions/chatActions';
import { SOCKET_EVENTS } from 'src/constants/socket';
import chatViewStyle from 'src/views/management/ChatView/chatView.style';

function ChatView({ classes }) {
  const { isChatsLoading } = useSelector((state) => state?.chat || {});
  const { chatKey } = useParams();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const socketService = useWebSocket();

  const handleChatNewMessage = (message) => {
    dispatch(socketChatNewMessage(message));
  };

  const handleChatNewScheduledMessage = (message) => {
    dispatch(socketChatNewScheduledMessage(message));
  };

  const handleChatMessageDelete = (message) => {
    dispatch(socketChatMessageDelete(message));
  };

  const handleChatScheduledMessageDelete = (message) => {
    dispatch(socketChatScheduledMessageDelete(message));
  };

  const handleChatScheduledMessageUpdate = (message) => {
    dispatch(socketChatScheduledMessageUpdate(message));
  };

  const handleChatMessageSent = (message) => {
    dispatch(socketChatMessageSent(message));
  };

  const handleChatMessageRead = (message) => {
    dispatch(socketChatMessageRead(message));
  };

  const handleChatMessageDelivered = (message) => {
    dispatch(socketChatMessageDelivered(message));
  };

  const handleChatScheduledMessageSent = (message) => {
    dispatch(socketChatScheduledMessageSent(message));
  };

  useEffect(() => {
    dispatch(getChatsList());
    dispatch(getContacts());
  }, []);

  useEffect(() => {
    if (socketService) {
      socketService.on(SOCKET_EVENTS.CHAT_NEW_MESSAGE, handleChatNewMessage);
      socketService.on(SOCKET_EVENTS.CHAT_NEW_SCHEDULED_MESSAGE, handleChatNewScheduledMessage);
      socketService.on(SOCKET_EVENTS.CHAT_MESSAGE_DELETE, handleChatMessageDelete);
      socketService.on(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_DELETE, handleChatScheduledMessageDelete);
      socketService.on(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_UPDATE, handleChatScheduledMessageUpdate);
      socketService.on(SOCKET_EVENTS.CHAT_MESSAGE_SENT, handleChatMessageSent);
      socketService.on(SOCKET_EVENTS.CHAT_MESSAGE_READ, handleChatMessageRead);
      socketService.on(SOCKET_EVENTS.CHAT_MESSAGE_DELIVERED, handleChatMessageDelivered);
      socketService.on(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_SENT, handleChatScheduledMessageSent);
    }

    return () => {
      if (socketService) {
        socketService.off(SOCKET_EVENTS.CHAT_NEW_MESSAGE, handleChatNewMessage);
        socketService.off(SOCKET_EVENTS.CHAT_NEW_SCHEDULED_MESSAGE, handleChatNewScheduledMessage);
        socketService.off(SOCKET_EVENTS.CHAT_MESSAGE_DELETE, handleChatMessageDelete);
        socketService.off(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_DELETE, handleChatScheduledMessageDelete);
        socketService.off(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_UPDATE, handleChatScheduledMessageUpdate);
        socketService.off(SOCKET_EVENTS.CHAT_MESSAGE_SENT, handleChatMessageSent);
        socketService.off(SOCKET_EVENTS.CHAT_MESSAGE_READ, handleChatMessageRead);
        socketService.off(SOCKET_EVENTS.CHAT_MESSAGE_DELIVERED, handleChatMessageDelivered);
        socketService.off(SOCKET_EVENTS.CHAT_SCHEDULED_MESSAGE_SENT, handleChatScheduledMessageSent);
      }
    };
  }, [socketService]);

  return (
    <Page
      title="Chat"
      className={classes.root}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          padding: {
            xs: '0px',
            md: '0 0 4px 24px',
          }
        }}
        maxWidth={false}
      >
        {(!isMobileDevice || !chatKey) && (
          <Card
            sx={{
              width: isMobileDevice ? '100%' : '30%',
              minWidth: isMobileDevice ? '100%' : '30%',
              padding: '20px 0',
            }}
          >
            <Spinner
              loading={isChatsLoading}
              positionType="absolute"
            />
            <ChatSidebar />
          </Card>
        )}
        {((isMobileDevice && chatKey) || !isMobileDevice) && (
          <ChatDetails key={chatKey} />
        )}
      </Container>
    </Page>
  );
}

ChatView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ChatView, chatViewStyle, { withTheme: true });
