/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  GET_FORM,
  GET_FORM_SUCCESS,
  GET_FORM_ERROR,
  GET_FORM_OPT_IN,
  GET_FORM_OPT_IN_SUCCESS,
  GET_FORM_OPT_IN_ERROR,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
  SET_FORM_SUBMIT_FORM,
} from 'src/actions/formsActions';

const formOptIn = {
  loading: false,
  data: {},
};

const initialState = {
  form: {},
  azAccountName: null,
  databaseBeingUsed: null,
  loading: false,
  formSubmitForm: {},
  submittedSuccess: false,
  formOptIn,
};

const formsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case GET_FORM: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_FORM_SUCCESS: {
      return produce(state, (draft) => {
        draft.azAccountName = payload?.azAccountName || null;
        draft.databaseBeingUsed = payload?.databaseBeingUsed || null;
        draft.form = payload?.form || {};
        draft.formMeta = payload?.formMeta || {};
        draft.loading = false;
      });
    }

    case GET_FORM_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case GET_FORM_OPT_IN: {
      return produce(state, (draft) => {
        draft.formOptIn.loading = true;
      });
    }

    case GET_FORM_OPT_IN_SUCCESS:
    case GET_FORM_OPT_IN_ERROR: {
      return produce(state, (draft) => {
        draft.formOptIn.loading = false;
        draft.formOptIn.data = payload || formOptIn.data;
      });
    }

    case SUBMIT_FORM: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case SUBMIT_FORM_SUCCESS: {
      return produce(state, (draft) => {
        draft.submittedSuccess = true;
        draft.loading = false;
      });
    }

    case SUBMIT_FORM_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case SET_FORM_SUBMIT_FORM: {
      return produce(state, (draft) => {
        draft.formSubmitForm = payload ? {
          ...state.formSubmitForm,
          ...payload,
        } : initialState.formSubmitForm;
      });
    }

    default: {
      return state;
    }
  }
};

export default formsReducer;
