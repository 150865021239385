import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import helpers from 'src/helpers';

//  When the backend has finished its part of the work, it is necessary to return
//   to the implementation of the contact icon ( CP or AccountBox icon)
//
// const getPhoneTypeIcon = (phoneType) => {
//   switch (phoneType) {
//     case 'cp':
//       return <ConnectionPointIcon />;
//     case 'presonal':
//       return <AccountBoxIcon color="#B5B5B5" />;
//     default:
//       return null;
//   }
// };

function PhonesList({ phoneNumbers }) {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginBottom: '8px',
        alignItems: 'center',
        color: '#AC9CF1'
      }}
    >
      {!helpers.isEmpty(phoneNumbers) && phoneNumbers.map((phone, index) => (
        <React.Fragment key={phone}>
          {index > 0 && '•'}
          <Grid>
            <Chip
              size="small"
              icon={<LocalPhoneOutlinedIcon />}
              label={(
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {helpers.transform.toPhoneNumber(phone)}
                  {/* {getPhoneTypeIcon(phone.type)} */}
                </span>
              )}
              sx={{
                padding: '3px',
                fontSize: '12px'
              }}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

PhonesList.propTypes = {
  phoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PhonesList;
