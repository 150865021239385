import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box } from '@mui/material';
import SidebarHeader from 'src/views/management/ChatView/ChatSidebar/SidebarHeader';
import SidebarSearchField from 'src/views/management/ChatView/ChatSidebar/SidebarSearchField';
import ContactsList from 'src/views/management/ChatView/ChatSidebar/ContactsList';
import ChatList from 'src/views/management/ChatView/ChatSidebar/ChatList';
import { getContacts, setContactsQuery } from 'src/actions/chatActions';

function ChatSidebar() {
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [contactsSearchText, setContactsSearchText] = useState('');
  const [chatsSearchText, setChatsSearchText] = useState('');
  const { query } = useSelector((state) => state?.chat?.allContactsList || {});
  const { permissions: userRolePermissions } = useSelector((state) => state.account?.user?.userRole || {});
  const dispatch = useDispatch();

  const {
    search,
  } = query || {};

  const handleContactsSearch = useCallback(
    debounce((searchValue) => {
      dispatch(setContactsQuery({ search: searchValue, page: 1 }));
      dispatch(getContacts());
    }, 800),
    [dispatch]
  );

  const handleContactsSearchChange = (value) => {
    setContactsSearchText(value);
    handleContactsSearch(value);
  };

  useEffect(() => {
    setContactsSearchText(search);
    setChatsSearchText('');
  }, [isCreatingNewChat]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%'
      }}
    >
      <Box sx={{ padding: '0 16px' }}>
        <SidebarHeader
          isCreateNewChat={isCreatingNewChat}
          setIsCreateNewChat={setIsCreatingNewChat}
          userRolePermissions={userRolePermissions}
        />
        <SidebarSearchField
          searchText={isCreatingNewChat ? contactsSearchText : chatsSearchText}
          setSearchText={isCreatingNewChat ? handleContactsSearchChange : setChatsSearchText}
        />
      </Box>
      {isCreatingNewChat ? (
        <ContactsList
          searchText={contactsSearchText}
          setIsCreateNew={setIsCreatingNewChat}
        />
      ) : (
        <ChatList searchText={chatsSearchText} />
      )}
    </Box>
  );
}

export default ChatSidebar;
