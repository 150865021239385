import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const COLOR_VIOLET_50 = '#EEEEFD';
const COLOR_GREY_100 = '#EEEEEE';
const COLOR_RED_WITH_TRANSPARENCY = '#f156421c';

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) => !['isUser', 'isDeleted', 'isError', 'isScheduled'].includes(prop),
})(({
  isError,
  isScheduled,
  isUser,
  theme,
}) => {
  let backgroundColor;

  if (isScheduled) {
    backgroundColor = COLOR_GREY_100;
  } else if (isError) {
    backgroundColor = COLOR_RED_WITH_TRANSPARENCY;
  } else {
    backgroundColor = isUser ? COLOR_VIOLET_50 : 'white';
  }

  return {
    backgroundColor,
    borderRadius: isUser ? '8px 0px 8px 8px' : '0px 8px 8px 8px',
    boxShadow: theme.shadows[1],
  };
});

function MessageBody({
  isUser,
  isError,
  isScheduled,
  children,
  ...props
}) {
  return (
    <CustomBox
      isUser={isUser}
      isError={isError}
      isScheduled={isScheduled}
      {...props}
    >
      {children}
    </CustomBox>
  );
}

MessageBody.propTypes = {
  children: PropTypes.node,
  isError: PropTypes.bool,
  isScheduled: PropTypes.bool,
  isUser: PropTypes.bool
};

MessageBody.defaultProps = {
  children: null,
  isError: false,
  isScheduled: false,
  isUser: false
};

export default MessageBody;
