import moment from 'moment';
import {
  isEmpty,
  isString,
  isNumber,
  isArray,
  isObject,
} from 'src/helpers/check';

export const toFloat = (value) => (isEmpty(value)
  ? 0
  : isString(value)
    ? parseFloat(value ? value.replace(/,/, '.').replace(/[^\d-.]/g, '') : '0')
    : isNumber(value)
      ? parseFloat(value)
      : 0);

export const toInt = (value) => (isEmpty(value) ? 0 : isNumber(parseInt(value)) ? parseInt(value) : 0);

export const toInt10 = (value) => (isEmpty(value) ? 0 : isNumber(parseInt(value, 10)) ? parseInt(value, 10) : 0);

export const toBool = (value) => (isEmpty(value) ? false : !!value);

export const toString = (value) => (isEmpty(value) ? '' : value.toString());

export const toArray = (value) => (isEmpty(value) ? [] : isArray(value) ? value : [value]);

export const jsonToObject = (value) => {
  if (isEmpty(value)) return {};

  if (isString(value)) {
    try {
      const parsed = JSON.parse(value);
      return isObject(parsed) ? parsed : {};
    } catch {
      return {};
    }
  }
  return isObject(value) ? value : {};
};

export const phoneToString = (value) => (isEmpty(value) ? '' : `${value}`.replace(/\D/g, ''));

export const phoneToInt = (value) => toInt10(phoneToString(value));

export const phoneToIntWithNull = (value) => (isEmpty(value) || !value ? null : toInt10(phoneToString(value)));

export const toPhoneNumber = (value) => (isEmpty(value) || value.length !== 10 ? value : value.replace(/^(?<s1>\d{3})(?<s2>\d{3})(?<s3>\d{4})$/, '($<s1>) $<s2>-$<s3>'));

export const toLowerCase = (value) => (isEmpty(value) ? '' : value.toLowerCase());

export const toUpperCase = (value) => (isEmpty(value) ? '' : value.toUpperCase());

export const arrayToString = (list, separator = ', ') => toArray(list).join(separator);

export const arrayToStringWithoutEmpty = (list, separator = ', ') => toArray(list).filter((item) => !isEmpty(item)).join(separator);

export const arrayStringToObjectLabelValue = (list) => toArray(list).map((item) => (!item?.label ? {
  label: item,
  value: item,
} : item));

export const stringFromObjectLabelValue = (value) => value?.value || value;

export const stringFromObjectLabelLabel = (value) => value?.label || value;

export const arrayStringFromObjectLabelValue = (list) => toArray(list).map(stringFromObjectLabelValue);

export const arrayStringFromObjectLabelLabel = (list) => toArray(list).map(stringFromObjectLabelLabel);

export const stringToNull = (value) => (value === '' ? null : value);

export const stringToArrayWithoutEmpty = (value, separator = ',') => toArray(toString(value).split(separator)).filter((item) => item !== '');

export const intToNull = (value) => (value === '' ? null : toInt10(value));

export const toFormInitValue = (value) => (isEmpty(value) && value !== 0 ? '' : value);

export const numberToCurrency = (value) => (toFloat(value).toFixed(2));

export const numberToCurrencyAndInt = (value) => (toFloat(value).toFixed(2).replace(/\.00$/gui, ''));

export const toDayDifferent = (timestamp1, timestamp2) => moment(timestamp1).utc().diff(moment(timestamp2).utc(), 'days');

export const toSortFieldObj = (sortValues) => {
  const valuesObj = {};
  toArray(sortValues).forEach((item) => {
    if (item.length === 2) {
      const [item0, item1] = item || [];
      if (item0 && item1) {
        valuesObj[item0] = item1;
      }
    } else if (item.length === 3) {
      const [item0, , item2] = item || [];
      if (item0 && item2) {
        valuesObj[item0] = item2;
      }
    }
  });
  if (isEmpty(valuesObj)) {
    return null;
  }

  return valuesObj;
};

export const toMinDate = (date) => moment(date).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

export const toMaxDate = (date) => moment(date).set({
  hour: 23,
  minute: 59,
  second: 59,
  millisecond: 999,
});

export const numberToString000 = (value) => `000${toString(value).replace(/\D/gui, '')}`.slice(-3);

export const toMessageCode = (code1, code2) => `${code1}${numberToString000(code2)}`;

export const toDateTime = (date) => moment(date).format('MMM DD YYYY | hh:mm a');

export const csvStringToArrayWithoutEmptyRows = (csvContent) => csvContent.replace(/"[^"]*"/g, (match) => match.replace(/\n/g, ' ')).split(/\r?\n/).map((row) => row.trim()).filter((row) => row !== '');
