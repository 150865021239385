import React, { useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import { withStyles, useStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChatBadge from 'src/layouts/DashboardLayout/NavBar/ChatBage';

import navItemStyle from 'src/layouts/DashboardLayout/NavBar/navItem.style';

function NavItem({
  title,
  href,
  depth,
  classes,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  click,
  isPageBlocked,
  isShownWhenOtherHidden,
  additionalOptions,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);
  const { cx } = useStyles();
  const { isChat } = additionalOptions || {};

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (click) {
      click();
    }
  };

  const handleListItemClick = () => {
    if (click) {
      click();
    }
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  const listItemIcon = useMemo(() => {
    const IconComponent = Icon && (
      <Icon
        className={`${classes.icon} navItemIcon`}
        size="20"
      />
    );

    if (isChat) {
      return (
        <ChatBadge className="navItemChatBage">
          {IconComponent}
        </ChatBadge>
      );
    }

    return IconComponent;
  }, [isChat]);

  const listItemImageAndText = (
    <>
      {listItemIcon}
      <span className={`${classes.title} navItemText`}>
        {title}
      </span>
    </>
  );

  if (children) {
    return (
      <ListItem className={cx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
          disabled={isPageBlocked && !isShownWhenOtherHidden}
        >
          {listItemImageAndText}
          {open ? <ExpandLessIcon size="small" color="inherit" /> : <ExpandMoreIcon size="small" color="inherit" />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={cx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={cx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
        onClick={handleListItemClick}
        disabled={isPageBlocked && !isShownWhenOtherHidden}
      >
        {listItemImageAndText}
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  isPageBlocked: PropTypes.bool.isRequired,
  isShownWhenOtherHidden: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  click: PropTypes.any,
  additionalOptions: PropTypes.object,
};

NavItem.defaultProps = {
  open: false,
  isShownWhenOtherHidden: null,
  click: null,
  additionalOptions: null,
};

export default withStyles(NavItem, navItemStyle, { withTheme: true });
