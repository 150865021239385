import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as BackButtonIcon } from 'src/assets/images/back-button.svg';
import {
  PERMISSIONS,
  STYLE_OPTIONS,
} from 'src/constants';
import helpers from 'src/helpers';

const { PERMISSIONS_SYSTEM_CODE } = PERMISSIONS;

function SidebarHeader({
  isCreateNewChat,
  setIsCreateNewChat,
  userRolePermissions,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '46px',
        justifyContent: isCreateNewChat ? 'flex-start' : 'space-between',
        alignItems: 'center',
      }}
    >
      {isCreateNewChat && (
        <Tooltip
          title="Return to Chats"
          placement="right-start"
        >
          <Button
            onClick={() => { setIsCreateNewChat(false); }}
            sx={{
              ...STYLE_OPTIONS.BUTTON_EMPTY_STYLE,
              marginRight: '6px',
              '&:hover svg': STYLE_OPTIONS.SVG_COLOR_BLUE
            }}
          >
            <SvgIcon
              className="svgIcon"
              component={BackButtonIcon}
              viewBox="0 0 28 28"
            />
          </Button>
        </Tooltip>
      )}
      <Typography
        variant="h3"
        color="textPrimary"
      >
        {isCreateNewChat ? 'New Chat' : 'Chats'}
      </Typography>
      {!isCreateNewChat && (
        <Button
          variant="contained"
          onClick={() => { setIsCreateNewChat(true); }}
          disabled={!helpers.permissions.doesUserHavePermission(PERMISSIONS_SYSTEM_CODE.CHAT_MESSAGE_CREATE, userRolePermissions)}
          sx={{
            ...STYLE_OPTIONS.BUTTON_PRIMARY,
            padding: '11px 20px',
          }}
        >
          <AddIcon />
          Create New
        </Button>
      )}
    </Box>
  );
}

SidebarHeader.propTypes = {
  isCreateNewChat: PropTypes.bool.isRequired,
  setIsCreateNewChat: PropTypes.func.isRequired,
  userRolePermissions: PropTypes.array,
};

SidebarHeader.defaultProps = {
  userRolePermissions: [],
};

export default SidebarHeader;
