import React from 'react';
import PropTypes from 'prop-types';
import ErrorBox from './styled/ErrorBox';

function ErrorHandler({
  nonFormError,
  formError,
  getFieldNameForError,
  handleUpdateInvalidField
}) {
  if (nonFormError?.errorTitle || nonFormError?.errorMessage) {
    return (
      <ErrorBox
        errorTitle={nonFormError.errorTitle || 'Error'}
        errorMessage={nonFormError.errorMessage || 'An error occurred'}
        maxWidth="366px"
      />
    );
  }

  if (formError?.popUpError) {
    const {
      errorInTheField,
      errorTitle,
      errorMessage,
    } = formError.popUpError;

    return (
      <ErrorBox
        errorTitle={errorTitle}
        errorMessage={errorMessage}
        buttonMessage={getFieldNameForError(errorInTheField, 'Update ')}
        onClick={handleUpdateInvalidField(errorInTheField)}
        maxWidth="366px"
      />
    );
  }

  if (formError?.submit) {
    return (
      <ErrorBox
        errorTitle="Submission Error"
        errorMessage={formError.submit}
        maxWidth="366px"
      />
    );
  }

  return null;
}

ErrorHandler.propTypes = {
  formError: PropTypes.shape({
    popUpError: PropTypes.shape({
      errorInTheField: PropTypes.string,
      errorTitle: PropTypes.string,
      errorMessage: PropTypes.string
    }),
    submit: PropTypes.string
  }),
  nonFormError: PropTypes.shape({
    errorTitle: PropTypes.string,
    errorMessage: PropTypes.string
  }),
  getFieldNameForError: PropTypes.func,
  handleUpdateInvalidField: PropTypes.func
};

export default ErrorHandler;
