import {
  NAV_LABEL_OPTIONS,
  ROUTES,
  PERMISSIONS,
} from 'src/constants';
import {
  Folder as FolderIcon,
  Users as UsersIcon
} from 'react-feather';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

const navConfig = (logout) => ([
  {
    subheader: 'View & Manage',
    permissions: [
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.EVENT_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.CONTACT_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.CHAT_SIDEBAR,
    ],
    items: [
      {
        title: 'Order Follow-Up',
        icon: FolderIcon,
        href: '/app/management/followUp',
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SIDEBAR],
      },
      {
        title: 'Events',
        icon: EventAvailableOutlinedIcon,
        href: '/app/management/events',
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.EVENT_SIDEBAR],
      },
      {
        title: 'Contacts',
        icon: UsersIcon,
        href: '/app/management/customers',
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.CONTACT_SIDEBAR],
      },
      {
        title: 'Chat',
        icon: QuestionAnswerOutlinedIcon,
        href: ROUTES.APP_MANAGEMENT_CHAT,
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.CHAT_SIDEBAR],
        additionalOptions: {
          isChat: true,
        },
      },
    ]
  },
  {
    subheader: 'Settings',
    href: '/app/pages',
    permissions: [
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SETTINGS_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.MESSAGE_TEMPLATES_SIDEBAR,
      PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_SIDEBAR,
    ],
    items: [
      {
        title: 'Order Follow-Up Settings',
        href: '/app/order-settings',
        icon: FolderIcon,
        isShownWhenOtherHidden: true,
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SETTINGS_SIDEBAR],
      },
      {
        title: 'Events Settings',
        href: '/app/event-settings',
        icon: SettingsApplicationsOutlinedIcon,
        isShownWhenOtherHidden: true,
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_SIDEBAR],
      },
      {
        title: 'Message Templates',
        href: '/app/settings/messages',
        icon: MessageOutlinedIcon,
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.MESSAGE_TEMPLATES_SIDEBAR],
      },
      {
        title: 'Account & General Settings',
        href: '/app/account',
        icon: SettingsIcon,
        isShownWhenOtherHidden: true,
        permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_SIDEBAR],
      },
    ]
  },
  {
    subheader: 'Help & Information',
    isLink: true,
    href: ROUTES.APP_HELP_AND_INFORMATION,
    icon: HelpOutlineIcon,
    isShownWhenOtherHidden: true,
    permissions: [PERMISSIONS.PERMISSIONS_SYSTEM_CODE.HELP_INFORMATION_SIDEBAR],
    items: []
  },
  {
    subheader: NAV_LABEL_OPTIONS.LOGOUT,
    isLink: true,
    href: '/',
    click: logout,
    icon: ExitToAppIcon,
    isShownWhenOtherHidden: true,
    items: []
  },
]);

export default navConfig;
