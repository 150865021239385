import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})(({ isUser, theme }) => ({
  display: 'flex',
  maxWidth: '500px',
  marginLeft: isUser ? 'auto' : 0,
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  marginBottom: '32px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90%',
  },
}));

function MessageBox({ isUser, children, ...props }) {
  return (
    <CustomBox isUser={isUser} {...props}>
      {children}
    </CustomBox>
  );
}

MessageBox.propTypes = {
  isUser: PropTypes.bool,
  children: PropTypes.node,
};

MessageBox.defaultProps = {
  isUser: false,
  children: null,
};

export default MessageBox;
