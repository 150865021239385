import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
} from '@mui/material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TooltipMUI6 from 'src/components/TooltipMUI6';
import { STYLE_OPTIONS } from 'src/constants';

const inputAdornmentStyle = {
  color: '#B5B5B5',
  background: 'none',
  '&:hover, &:active, & .MuiTouchRipple-root': {
    background: 'none',
  },
  '&:hover svg': STYLE_OPTIONS.SVG_COLOR_BLUE,
};

function ImputEndAdornment({
  isLoading,
  isUploadDisabled,
  isScheduleDisabled,
  openUploadDialog,
  openScheduleDialog,
}) {
  return (
    <InputAdornment
      position="end"
      sx={{
        margin: 'auto 0px 11px 8px',
      }}
    >
      {openUploadDialog && (
        <TooltipMUI6 title="Attach file">
          <span>
            <IconButton
              size="small"
              onClick={openUploadDialog}
              disabled={isUploadDisabled || isLoading}
              sx={{
                ...inputAdornmentStyle,
              }}
            >
              <AttachFileIcon />
            </IconButton>
          </span>
        </TooltipMUI6>
      )}
      {openScheduleDialog && (
        <TooltipMUI6 title="Schedule a message">
          <span>
            <IconButton
              size="small"
              onClick={openScheduleDialog}
              disabled={isScheduleDisabled || isLoading}
              sx={{
                ...inputAdornmentStyle,
              }}
            >
              <ScheduleSendIcon />
            </IconButton>
          </span>
        </TooltipMUI6>
      )}
    </InputAdornment>
  );
}

ImputEndAdornment.propTypes = {
  isLoading: PropTypes.bool,
  isUploadDisabled: PropTypes.bool,
  isScheduleDisabled: PropTypes.bool,
  openUploadDialog: PropTypes.func,
  openScheduleDialog: PropTypes.func,
};

ImputEndAdornment.defaultProps = {
  isLoading: false,
  isUploadDisabled: false,
  isScheduleDisabled: false,
};

export default ImputEndAdornment;
