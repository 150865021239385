import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Divider,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Toolbar from 'src/views/management/ChatView/ChatDetails/Toolbar';
import DefaultMessagesView from 'src/views/management/ChatView/ChatDetails/DefaultMessagesView';
import LoadMoreButton from 'src/views/management/ChatView/styled/LoadMoreButton';
import Message from 'src/views/management/ChatView/ChatDetails/Message';
import ScheduledMessagesList from 'src/views/management/ChatView/ChatDetails/ScheduledMessagesList';
import MessageAdd from 'src/views/management/ChatView/NewMessage';
import { clearCustomerData, getCustomer } from 'src/actions/customerDetailsActions';
import {
  clearFilteredChatMessages,
  getMoreChatMessages,
  getMoreScheduledChatMessages,
  markChatAsSeen,
  setFileUploadToMessage,
} from 'src/actions/chatActions';
import { MESSAGES } from 'src/constants';
import helpers from 'src/helpers';

function chatSelector(state, chatKey) {
  const { chats, filteredChats, messagesFilteredByPhone } = state.chat;
  const chatSource = messagesFilteredByPhone && filteredChats ? filteredChats : chats;
  const chat = chatSource.byKey[chatKey];

  // This allows to work with chats before they are created.
  // If it is new, we don't have it in the store. instead, we return an `empty` chat
  if (!chat) {
    return {
      key: chatKey,
      messages: [],
      scheduledMessages: [],
      unreadCount: 0,
      nextCursor: null,
      scheduledNextCursor: null,
    };
  }

  return chat;
}

function ChatDetails() {
  const dispatch = useDispatch();
  const { chatKey } = useParams();
  const [showScrollToBottomButton, setShowScrollToBottomButton] = useState(false);
  const [showScheduledMessages, setShowScheduledMessages] = useState(false);
  const messagesRef = useRef(null);
  const scrollPositionRef = useRef(null);

  const chat = useSelector((state) => chatSelector(state, chatKey));
  const {
    isMessagesLoading,
    messagesFilteredByPhone,
    userCanDeleteTexts,
  } = useSelector((state) => state?.chat || {});
  const { customer: contact } = useSelector((state) => state?.customerDetails || {});
  const { permissions: userRolePermissions } = useSelector((state) => state.account?.user?.userRole || {});

  const messages = chat.messages || [];
  const scheduledMessages = chat.scheduledMessages || [];
  const contactPhones = helpers.transform
    .toArray([contact?.phone1, contact?.phone2, contact?.phone3])
    .filter((phone) => !helpers.isEmpty(phone));

  const canLoadMoreMessages = !helpers.isEmpty(messages)
    && messages.length >= MESSAGES.CHAT_MESSAGES_LIMIT_PER_REQUEST
    && !helpers.isEmpty(chat.nextCursor);

  const canLoadMoreScheduledMessages = !helpers.isEmpty(scheduledMessages)
    && scheduledMessages.length >= MESSAGES.CHAT_MESSAGES_LIMIT_PER_REQUEST
    && !helpers.isEmpty(chat.scheduledNextCursor);

  const isMoreThanOnePhoneNumber = contactPhones.length > 1;

  const getScrollToBottomVisibility = useCallback((container) => {
    if (!container) return;

    const { scrollHeight, scrollTop, clientHeight } = container;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    setShowScrollToBottomButton(distanceFromBottom > 1000);
  }, []);

  const scrollMessagesToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  const handleLoadMoreMessages = useCallback(() => {
    if (chat.nextCursor) {
      if (messagesRef.current) {
        scrollPositionRef.current = messagesRef.current?.scrollHeight;
      }
      dispatch(getMoreChatMessages(chat.nextCursor, chatKey));
    }
  }, [chat.nextCursor, chatKey]);

  const handleLoadMoreScheduledMessages = useCallback(() => {
    if (chat.scheduledNextCursor) {
      dispatch(getMoreScheduledChatMessages(chat.scheduledNextCursor, chatKey));
    }
  }, [chat.nextCursor, chatKey]);

  const handleShowHideScheduled = () => {
    setShowScheduledMessages((prev) => !prev);
  };

  useEffect(() => {
    if (chatKey) {
      dispatch(getCustomer(chatKey));
    }
    return () => {
      dispatch(clearFilteredChatMessages());
      dispatch(clearCustomerData());
      dispatch(setFileUploadToMessage(false));
    };
  }, [dispatch, chatKey]);

  useEffect(() => {
    if (chat.unreadCount) {
      const unreadMessageIds = messages
        .filter((message) => message.isReceived && message.readAt === null)
        .map((message) => message.id);

      if (!helpers.isEmpty(unreadMessageIds)) {
        dispatch(markChatAsSeen(chatKey, unreadMessageIds));
      }
    }
  }, [dispatch, chatKey, chat.unreadCount, messages]);

  useEffect(() => {
    if (messagesRef.current?.scrollHeight && scrollPositionRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight - scrollPositionRef.current;
    }
  }, [chat.nextCursor]);

  useEffect(() => {
    if (!helpers.isEmpty(messages)) {
      scrollMessagesToBottom();
    }
  }, [messages.at(-1), isMoreThanOnePhoneNumber]);

  if (!chatKey) {
    return <DefaultMessagesView />;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar chat={chat} />
      <Divider />
      <Box
        component={PerfectScrollbar}
        options={{ suppressScrollX: true }}
        containerRef={(container) => {
          messagesRef.current = container;
        }}
        onScrollY={getScrollToBottomVisibility}
        sx={{
          padding: {
            xs: '24px 16px',
            md: '24px 36px',
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {canLoadMoreMessages && (
            <LoadMoreButton
              startIcon={<KeyboardArrowUpIcon />}
              disabled={isMessagesLoading || helpers.isEmpty(chat.nextCursor)}
              onClick={handleLoadMoreMessages}
              sx={{
                marginBottom: '24px',
              }}
            >
              Load previous messages
            </LoadMoreButton>
          )}
        </Box>
        {!helpers.isEmpty(messages) ? messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            showPhoneNumber={isMoreThanOnePhoneNumber}
            userCanDeleteTexts={userCanDeleteTexts}
          />
        )) : (
          <DefaultMessagesView />
        )}
        {showScheduledMessages && (
          <ScheduledMessagesList
            scheduledMessages={scheduledMessages}
            userRolePermissions={userRolePermissions}
            userCanDeleteTexts={userCanDeleteTexts}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {canLoadMoreScheduledMessages && messagesFilteredByPhone && (
                <LoadMoreButton
                  startIcon={<KeyboardArrowDownIcon />}
                  disabled={isMessagesLoading || helpers.isEmpty(chat.scheduledNextCursor)}
                  onClick={handleLoadMoreScheduledMessages}
                >
                  Show more scheduled messages
                </LoadMoreButton>
              )}
            </Box>
          </ScheduledMessagesList>
        )}
      </Box>
      <MessageAdd
        chat={chat}
        showScrollButton={showScrollToBottomButton}
        setShowScheduledMessages={setShowScheduledMessages}
        scrollMessagesToBottom={scrollMessagesToBottom}
        userRolePermissions={userRolePermissions}
      >
        {!helpers.isEmpty(scheduledMessages) && (
          <Button
            size="small"
            variant="text"
            color="secondary"
            sx={{
              textTransform: 'none',
              padding: '0',
              ':hover': {
                background: 'none',
              },
            }}
            onClick={handleShowHideScheduled}
            disableRipple
          >
            {`Scheduled messages (${scheduledMessages.length})`}
            <ExpandMoreIcon
              sx={{
                transition: 'transform 0.3s ease',
                transform: showScheduledMessages ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </Button>
        )}
      </MessageAdd>
    </Box>
  );
}

export default ChatDetails;
