import React, { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import PhoneSelector from 'src/views/management/ChatView/PhoneSelector';
import { clearFilteredChatMessages, getFilteredChatMessages } from 'src/actions/chatActions';
import {
  setBackToChatsList,
  setBackToPartyGuestList,
  setBackToCustomerList,
} from 'src/actions/customersActions';
import { ReactComponent as BackButtonIcon } from 'src/assets/images/back-button.svg';
import {
  ROUTES,
  STYLE_OPTIONS,
} from 'src/constants';
import helpers from 'src/helpers';

function Toolbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customer: contact } = useSelector((state) => state?.customerDetails || {});
  const { messagesFilteredByPhone } = useSelector((state) => state?.chat || {});

  const contactPhones = helpers.transform
    .toArray([contact?.phone1, contact?.phone2, contact?.phone3])
    .filter((phone) => !helpers.isEmpty(phone));

  const handleChangeFilterByPhone = (e) => {
    const filterByPhone = e.target.value;
    if (filterByPhone) {
      dispatch(getFilteredChatMessages({ contactId: contact.id, phone: filterByPhone }));
    } else {
      dispatch(clearFilteredChatMessages());
    }
  };

  const setCopyValuesToBack = () => {
    dispatch(setBackToCustomerList());
    dispatch(setBackToPartyGuestList());
    dispatch(setBackToChatsList({ active: true }));
  };

  const onClickContactDetails = useCallback((id) => history.push(`${ROUTES.APP_MANAGEMENT_CUSTOMERS}/${id}`), [history]);

  const handleClick = () => {
    const { id } = contact || {};
    if (id) {
      setCopyValuesToBack();
      onClickContactDetails(id);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 64,
        flexShrink: 0,
        padding: '10px 16px',
      }}
    >
      <Box
        sx={{
          display: {
            xs: 'flex',
            md: 'none',
          },
        }}
      >
        <Tooltip
          title="Return to Chats list"
          placement="right-start"
        >
          <Button
            component={Link}
            to={ROUTES.APP_MANAGEMENT_CHAT}
            sx={{
              ...STYLE_OPTIONS.BUTTON_EMPTY_STYLE,
              '&:hover svg': STYLE_OPTIONS.SVG_COLOR_BLUE
            }}
          >
            <SvgIcon
              className="svgIcon"
              component={BackButtonIcon}
              viewBox="0 0 28 28"
            />
          </Button>
        </Tooltip>
      </Box>
      {!helpers.isEmpty(contact) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: { xs: '6px', md: '36px' },
            marginRight: { xs: '6px', md: '24px' },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            {helpers.transform.arrayToStringWithoutEmpty([contact?.firstName, contact?.lastName], ' ')}
          </Typography>
        </Box>
      )}
      {(!helpers.isEmpty(contactPhones) && contactPhones.length > 1) && (
        <PhoneSelector
          isToolbarSelector
          value={messagesFilteredByPhone}
          options={contactPhones}
          onChange={handleChangeFilterByPhone}
        />
      )}
      {!helpers.isEmpty(contact) && (
        <IconButton
          color="secondary"
          aria-label="Contact details"
          onClick={handleClick}
          sx={{
            width: '40px',
            minWidth: '40px',
            marginLeft: 'auto',
          }}
        >
          <ModeIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default Toolbar;
