import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import TextFieldFormik from 'src/components/TextFieldFormik';
import DivForm from 'src/components/ModalDialogMUI6/styled/DivForm';
import {
  setCurrentMessage,
  sendOrEditScheduledMessage,
} from 'src/actions/chatActions';
import { STYLE_OPTIONS } from 'src/constants';
import helpers from 'src/helpers';

const SCHEDULE_FIELD = 'scheduledDateTime';

function ModalEditScheduledDateTime({
  isModalVisible,
  chatKey,
  onCloseModal,
}) {
  const { currentMessage } = useSelector((state) => state?.chat || {});
  const dispatch = useDispatch();

  const initialValues = {
    scheduledDateTime: currentMessage?.scheduledAt || null,
  };

  const validationSchema = Yup.object().shape({
    scheduledDateTime: Yup.date('Invalid date')
      .nullable(true)
      .typeError('Invalid date')
      .min(new Date(), 'Date must be in the future')
      .max(new Date(2100, 1, 1, 0, 0, 0), 'Date must be before 2100'),
  });

  const onSubmit = async (values, {
    resetForm,
    setErrors,
    setStatus,
    setSubmitting,
  }) => {
    const doWhenTry = () => {
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      dispatch(setCurrentMessage({}));
    };
    const doWhenCatch = (error, errorMessage) => {
      setStatus({ success: false });
      setErrors({ submit: errorMessage || error?.message || 'Try again later' });
      setSubmitting(false);
    };

    const sendData = {
      ...currentMessage,
      scheduledAt: !helpers.isEmpty(values.scheduledDateTime) ? moment(values.scheduledDateTime).utc() : null,
    };
    dispatch(sendOrEditScheduledMessage(sendData, chatKey, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    initialTouched: { scheduledDateTime: true },
    validateOnMount: true,
  });

  const handleChangeDate = (name, date) => {
    if (moment(date).isValid()) {
      formik.setFieldValue(name, moment(date), true);
      return;
    }
    formik.setFieldValue(name, date, true);
  };

  const handleSchedule = () => {
    formik.handleSubmit();
    if (!formik.errors.scheduledDateTime) {
      onCloseModal();
    }
  };

  const handleClose = () => {
    dispatch(setCurrentMessage({}));
    onCloseModal();
  };

  return (
    <form
      id="edit-scheduled-date-time"
      onSubmit={formik.handleSubmit}
    >
      <Dialog
        open={isModalVisible}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            padding: '24px',
          },
        }}
      >
        <Box
          sx={{
            padding: '0 0 0 16px',
            textAlign: 'center',
          }}
        >
          <DialogTitle
            sx={{
              fontSize: '18px',
              padding: 0,
            }}
          >
            Edit Schedule
          </DialogTitle>
          <Typography
            variant="subtitle1"
            color="#546E7A"
          >
            Update Scheduled Date & Time
          </Typography>
        </Box>
        <DivForm>
          <TextFieldFormik
            touchedValue={formik.touched.scheduledDateTime}
            errorValue={formik.errors.scheduledDateTime}
            name={SCHEDULE_FIELD}
            handleChange={(date) => { handleChangeDate(SCHEDULE_FIELD, date); }}
            handleBlur={formik.handleBlur}
            value={formik.values.scheduledDateTime}
            label="Schedule Date & Time to Send"
            fieldType="dateTime"
            minDate={new Date().toISOString()}
          />
        </DivForm>
        <DialogActions
          sx={{
            padding: 0,
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              ...STYLE_OPTIONS.BUTTON_PRIMARY_REVERSED,
              minWidth: '105px',
              marginRight: '14px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSchedule}
            disabled={!formik.values.scheduledDateTime}
            sx={{
              ...STYLE_OPTIONS.BUTTON_PRIMARY,
              minWidth: '105px',
            }}
          >
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

ModalEditScheduledDateTime.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  chatKey: PropTypes.string.isRequired
};

export default ModalEditScheduledDateTime;
