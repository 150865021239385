/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import UserPermissionGuard from 'src/components/UserPermissionGuard';
import MessageView from 'src/views/Settings/MessageView/index';
import HelpAndInformation from 'src/views/pages/HelpAndInformation';
import ChatView from 'src/views/management/ChatView';
import RedirectLogic from 'src/RedirectLogic';

import {
  ROUTES,
  PERMISSIONS,
} from 'src/constants';

const { PERMISSIONS_SYSTEM_CODE } = PERMISSIONS;

const routesConfig = [
  {
    exact: true,
    path: '/f/:formId',
    component: lazy(() => import('src/forms'))
  },
  {
    exact: true,
    path: '/signupnow',
    component: lazy(() => import('src/signupMUI6'))
  },
  {
    exact: true,
    path: '/signupnowv2',
    component: lazy(() => import('src/signupMUI6'))
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => (
          <RedirectLogic
            defaultRedirects={[
              {
                url: '/app/management/FollowUp',
                permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_VIEW],
              },
              {
                url: ROUTES.APP_MANAGEMENT_CHAT,
                permissions: [PERMISSIONS_SYSTEM_CODE.CHAT_VIEW],
              },
              {
                url: ROUTES.APP_ACCOUNT,
                permissions: [PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_YOUR_INFO],
              },
            ]}
          />
        ),
      },
      {
        exact: true,
        path: ROUTES.APP_ACCOUNT,
        guard: UserPermissionGuard,
        permissions: [
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_YOUR_INFO,
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_CAMPAIGNS_TAGS,
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_OTHER,
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_PB_CUSTOM_FIELDS,
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_SUBSCRIPTION,
          PERMISSIONS_SYSTEM_CODE.ACCOUNT_GENERAL_SETTINGS_PASSWORD,
        ],
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: ROUTES.APP_EVENT_SETTINGS,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_VIEW],
        component: lazy(() => import('src/views/pages/PartySettingsView'))
      },
      {
        exact: true,
        path: ROUTES.APP_ORDER_SETTINGS,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SETTINGS_VIEW_AND_CRUD],
        component: lazy(() => import('src/views/pages/OrderSettingsView'))
      },
      {
        exact: true,
        path: '/app/order-settings/follow-ups/:followUpId',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_SETTINGS_VIEW_AND_CRUD],
        component: lazy(() => import('src/views/pages/OrderSettingsView/TabFollowUpActions/FollowUpDetails'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CONTACT_VIEW],
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/create',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CONTACT_CREATE],
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/import',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CONTACT_IMPORT],
        component: lazy(() => import('src/views/management/CustomerImportView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CONTACT_UPDATE],
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CONTACT_DETAILS_VIEW],
        component: lazy(() => import('src/views/management/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/events',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENT_VIEW],
        // pathToRedirect: ROUTES.APP_PERMISSION_DENIED, // use pathToRedirect to redirect to another page
        component: lazy(() => import('src/views/management/PartyListView'))
      },
      {
        exact: true,
        path: '/app/management/events/create',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENT_CREATE],
        component: lazy(() => import('src/views/management/PartyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/events/:partyId/edit',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENT_UPDATE],
        component: lazy(() => import('src/views/management/PartyEditView'))
      },
      {
        exact: true,
        path: '/app/management/events/:partyId',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENT_DETAILS_VIEW],
        // pathToRedirect: ROUTES.APP_PERMISSION_DENIED, // use pathToRedirect to redirect to another page
        component: lazy(() => import('src/views/management/PartyDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/events/smart-actions/:partyId/:actionGroupId/create',
        component: lazy(() => import('src/views/management/PartyDetailsView/SmartActions/SmartActionCreateView'))
      },
      {
        exact: true,
        path: '/app/management/events/smart-actions/:smartActionId',
        component: lazy(() => import('src/views/management/PartyDetailsView/SmartActions/SmartActionDetails'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-venues',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_VIEW],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/TabPartyVenuesListView'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-venues/create',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_CREATE],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueCreate'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-venues/:partyVenueId/edit',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_UPDATE],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueEdit'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-venues/:partyVenueId',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_DETAILS_VIEW],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyVenues/PartyVenueDetails'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-types',
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/TabPartyTypesListView'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-types/create',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_CREATE],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeCreate'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-types/:partyTypeId/edit',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_UPDATE],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeEdit'))
      },
      {
        exact: true,
        path: '/app/event-settings/event-types/:partyTypeId',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.EVENTS_SETTINGS_DETAILS_VIEW],
        component: lazy(() => import('src/views/pages/PartySettingsView/TabPartyTypes/PartyTypeDetails'))
      },
      {
        exact: true,
        path: '/app/smart-action-groups/smart-actions/:partyTypeId/:actionGroupId/create',
        component: lazy(() => import('src/views/pages/SmartActionGroupView/SmartActionCreateView'))
      },
      {
        exact: true,
        path: '/app/smart-action-groups/smart-actions/:smartActionId',
        component: lazy(() => import('src/views/pages/SmartActionGroupView/SmartActionDetails'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:partyTypeId/:formDefinitionId/create-image',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateImageView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:partyTypeId/:formDefinitionId/create-text',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateTextView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:partyTypeId/:formDefinitionId/create-submitted-image',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateSubmittedImageView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:partyTypeId/:formDefinitionId/create-submitted-text',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionCreateSubmittedTextView'))
      },
      {
        exact: true,
        path: '/app/form-definition/form-lines/:formLineDefinitionId',
        component: lazy(() => import('src/views/pages/FormsDefinitionView/FormDetails/FormLineDefinitionDetails'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:partyId/:formId/create-image',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateImageView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:partyId/:formId/create-text',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateTextView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:partyId/:formId/create-submitted-image',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateSubmittedImageView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:partyId/:formId/create-submitted-text',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineCreateSubmittedTextView'))
      },
      {
        exact: true,
        path: '/app/form/form-lines/:formLineId',
        component: lazy(() => import('src/views/management/PartyDetailsView/JoinForm/FormLineDetails'))
      },
      {
        exact: true,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_VIEW],
        // pathToRedirect: '/404', // use pathToRedirect to use redirect
        path: '/app/management/followUp',
        component: lazy(() => import('src/views/management/FollowUp'))
      },
      {
        exact: true,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_VIEW],
        // pathToRedirect: '/404', // use pathToRedirect to use redirect
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_CREATE],
        path: '/app/management/orders/create',
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_DETAILS_VIEW],
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.ORDER_FOLLOW_UP_UPDATE],
        path: '/app/management/orders/:orderId/edit',
        component: lazy(() => import('src/views/management/OrderEditFullView'))
      },
      {
        exact: true,
        path: [
          ROUTES.APP_MANAGEMENT_CHAT,
          `${ROUTES.APP_MANAGEMENT_CHAT}/:chatKey`
        ],
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.CHAT_VIEW],
        component: () => <ChatView />
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/events" />
      },
      {
        exact: true,
        path: '/app/settings/messages',
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.MESSAGE_TEMPLATES_VIEW_AND_CRUD],
        component: () => <MessageView />
      },
      {
        exact: true,
        path: '/app/settings',
        component: () => <Redirect to="/app/account" />
      },
      {
        exact: true,
        path: '/app/event-settings',
        component: () => <Redirect to="/app/event-settings" />
      },
      {
        exact: true,
        path: '/app/order-settings',
        component: () => <Redirect to="/app/order-settings" />
      },
      {
        exact: true,
        path: ROUTES.APP_HELP_AND_INFORMATION,
        guard: UserPermissionGuard,
        permissions: [PERMISSIONS_SYSTEM_CODE.HELP_INFORMATION_VIEW],
        component: () => <HelpAndInformation />
      },
      {
        exact: true,
        path: ROUTES.APP_PERMISSION_DENIED,
        component: lazy(() => import('src/views/pages/Error403View')),
      },
      {
        exact: true,
        path: ROUTES.APP_404,
        component: lazy(() => import('src/views/pages/Error404View')),
      },
      {
        component: () => <Redirect to={ROUTES.APP_404} />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const {
          guard,
          layout,
          component: Component,
          permissions,
          pathToRedirect,
        } = route || {};
        const Guard = guard || Fragment;
        const Layout = layout || Fragment;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard
                {...permissions ? { permissions } : {}}
                {...pathToRedirect ? { pathToRedirect } : {}}
              >
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
