import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';

const FormCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(4)
  }
}));

export default FormCard;
