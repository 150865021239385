export const PERMISSIONS_SYSTEM_CODE = {
  ORDER_FOLLOW_UP_SIDEBAR: 'ORDER_FOLLOW_UP_SIDEBAR',
  ORDER_FOLLOW_UP_VIEW: 'ORDER_FOLLOW_UP_VIEW',
  ORDER_FOLLOW_UP_CREATE: 'ORDER_FOLLOW_UP_CREATE',
  ORDER_FOLLOW_UP_UPDATE: 'ORDER_FOLLOW_UP_UPDATE',
  ORDER_FOLLOW_UP_DELETE: 'ORDER_FOLLOW_UP_DELETE',
  ORDER_FOLLOW_UP_PROCESS: 'ORDER_FOLLOW_UP_PROCESS',
  ORDER_FOLLOW_UP_DETAILS_VIEW: 'ORDER_FOLLOW_UP_DETAILS_VIEW',
  EVENT_SIDEBAR: 'EVENT_SIDEBAR',
  EVENT_VIEW: 'EVENT_VIEW',
  EVENT_DETAILS_VIEW: 'EVENT_DETAILS_VIEW',
  EVENT_CREATE: 'EVENT_CREATE',
  EVENT_UPDATE: 'EVENT_UPDATE',
  EVENT_DELETE: 'EVENT_DELETE',
  CONTACT_VIEW: 'CONTACT_VIEW',
  CONTACT_DETAILS_VIEW: 'CONTACT_DETAILS_VIEW',
  CONTACT_CREATE: 'CONTACT_CREATE',
  CONTACT_UPDATE: 'CONTACT_UPDATE',
  CONTACT_DELETE: 'CONTACT_DELETE',
  CONTACT_SIDEBAR: 'CONTACT_SIDEBAR',
  CONTACT_IMPORT: 'CONTACT_IMPORT',
  ORDER_FOLLOW_UP_SETTINGS_SIDEBAR: 'ORDER_FOLLOW_UP_SETTINGS_SIDEBAR',
  ORDER_FOLLOW_UP_SETTINGS_VIEW_AND_CRUD: 'ORDER_FOLLOW_UP_SETTINGS_VIEW_AND_CRUD',
  MESSAGE_TEMPLATES_SIDEBAR: 'MESSAGE_TEMPLATES_SIDEBAR',
  MESSAGE_TEMPLATES_VIEW_AND_CRUD: 'MESSAGE_TEMPLATES_VIEW_AND_CRUD',
  EVENTS_SETTINGS_VIEW: 'EVENTS_SETTINGS_VIEW',
  EVENTS_SETTINGS_DETAILS_VIEW: 'EVENTS_SETTINGS_DETAILS_VIEW',
  EVENTS_SETTINGS_SIDEBAR: 'EVENTS_SETTINGS_SIDEBAR',
  EVENTS_SETTINGS_CREATE: 'EVENTS_SETTINGS_CREATE',
  EVENTS_SETTINGS_UPDATE: 'EVENTS_SETTINGS_UPDATE',
  ACCOUNT_GENERAL_SETTINGS_SIDEBAR: 'ACCOUNT_GENERAL_SETTINGS_SIDEBAR',
  ACCOUNT_GENERAL_SETTINGS_YOUR_INFO: 'ACCOUNT_GENERAL_SETTINGS_YOUR_INFO',
  ACCOUNT_GENERAL_SETTINGS_CAMPAIGNS_TAGS: 'ACCOUNT_GENERAL_SETTINGS_CAMPAIGNS_TAGS',
  ACCOUNT_GENERAL_SETTINGS_OTHER: 'ACCOUNT_GENERAL_SETTINGS_OTHER',
  ACCOUNT_GENERAL_SETTINGS_PB_CUSTOM_FIELDS: 'ACCOUNT_GENERAL_SETTINGS_PB_CUSTOM_FIELDS',
  ACCOUNT_GENERAL_SETTINGS_SUBSCRIPTION: 'ACCOUNT_GENERAL_SETTINGS_SUBSCRIPTION',
  ACCOUNT_GENERAL_SETTINGS_PASSWORD: 'ACCOUNT_GENERAL_SETTINGS_PASSWORD',
  HELP_INFORMATION_SIDEBAR: 'HELP_INFORMATION_SIDEBAR',
  HELP_INFORMATION_VIEW: 'HELP_INFORMATION_VIEW',
  YOUR_REFERRAL_CODE_SIDEBAR: 'YOUR_REFERRAL_CODE_SIDEBAR',
  CHAT_SIDEBAR: 'CHAT_SIDEBAR',
  CHAT_VIEW: 'CHAT_VIEW',
  CHAT_MESSAGE_CREATE: 'CHAT_MESSAGE_CREATE',
  CHAT_MESSAGE_UPDATE: 'CHAT_MESSAGE_UPDATE',
  CHAT_MESSAGE_DELETE: 'CHAT_MESSAGE_DELETE',
};
