import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { socketChatTotalUnreadCount } from 'src/actions/chatActions';
import { addNotification } from 'src/actions/notificationActions';
import {
  NOTIFICATION_STATUS,
  SOCKET,
  PERMISSIONS,
} from 'src/constants';
import helpers from 'src/helpers';

const { SOCKET_EVENTS } = SOCKET;
const { PERMISSIONS_SYSTEM_CODE } = PERMISSIONS;

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const [isConnected, setConnected] = useState(false);
  const dispatch = useDispatch();
  const socket = useRef(null);

  const {
    id,
    userRole,
  } = useSelector((state) => state?.account?.user || {});

  const { permissions: userRolePermissions } = userRole || {};

  const token = localStorage.getItem('accessToken');

  const handleChatTotalUnreadCount = (message) => {
    dispatch(socketChatTotalUnreadCount(message));
    socket.current.off(SOCKET_EVENTS.CHAT_TOTAL_UNREAD_COUNT, handleChatTotalUnreadCount);
  };

  const socketErrorMessage = (message) => {
    dispatch(addNotification('SOCKET_ERROR', message, NOTIFICATION_STATUS.ERROR));
  };

  const handleSocketError = () => {
    socketErrorMessage('Wrong connection. Please reload this page.');
  };

  useEffect(() => {
    if (
      !isConnected
      && token
      && id
      && helpers.permissions.doesUserHaveOneOfPermissions([PERMISSIONS_SYSTEM_CODE.CHAT_VIEW], userRolePermissions)
    ) {
      socket.current = io(process.env.REACT_APP_API_BASE_URL, {
        transports: ['websocket', 'webtransport'],
        reconnection: true,
        reconnectionAttempts: 3,
        auth: { token },
      });

      socket.current.on('connect', () => {
        setConnected(true);
      });

      socket.current.on('connect_error', handleSocketError);
      socket.current.on('connect_timeout', handleSocketError);
      socket.current.on('reconnect_error', handleSocketError);

      // We need it because BE side sends this event on socket init
      socket.current.on(SOCKET_EVENTS.CHAT_TOTAL_UNREAD_COUNT, handleChatTotalUnreadCount);
    }

    return () => {
      if (socket.current) {
        socket.current.close();
        setConnected(false);
      }
    };
  }, [id]);

  return (
    <WebSocketContext.Provider value={socket.current}>
      {children}
    </WebSocketContext.Provider>
  );
}

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useWebSocket = () => useContext(WebSocketContext);
