import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Typography,
} from '@mui/material';
import Spinner from 'src/components/Spinner/Spinner';
import ImputEndAdornment from 'src/views/management/ChatView/NewMessage/ImputEndAdornment';
import AttachedFilePrewiew from 'src/views/management/ChatView/NewMessage/AttachedFilePrewiew';
import {
  sendOrEditScheduledMessage,
  setCurrentMessage,
  setFileUploadToMessage,
} from 'src/actions/chatActions';
import { addNotification } from 'src/actions/notificationActions';
import helpers from 'src/helpers';
import {
  NOTIFICATION_STATUS,
  STORAGE,
  STYLE_OPTIONS,
} from 'src/constants';

const isMessageEdit = true;

const MAX_TEXT_MESSAGE_LENGTH = 1024;
const MIN_TEXT_MESSAGE_LENGTH = 2;

const VALIDATE_MESSAGE = {
  validateFileSize: 'File size must be less than 1.4Mb',
  validateFileType: 'Must be a jpg, jpeg, png, or gif file',
};

function ModalEditScheduledMessage({
  isModalVisible,
  chatKey,
  onCloseModal,
}) {
  const { user, azAccountName, databaseBeingUsed } = useSelector((state) => state?.account || {});
  const { isMessageSending, currentMessage } = useSelector((state) => state?.chat || {});
  const {
    id,
    scheduledAt,
    body,
    attachment,
    messageAttachmentContainer,
    fileToReplace = false,
  } = currentMessage || {};
  const dispatch = useDispatch();

  const initialValues = {
    message: body || '',
    attachment: attachment || '',
    messageAttachmentContainer: messageAttachmentContainer || null,
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .min(MIN_TEXT_MESSAGE_LENGTH, `Text Message to Send must be at least ${MIN_TEXT_MESSAGE_LENGTH} characters`)
      .max(MAX_TEXT_MESSAGE_LENGTH, `Text Message to Send must be at most ${MAX_TEXT_MESSAGE_LENGTH} characters`),
    attachment: Yup.string()
      .test('invalidAttachmentSize', VALIDATE_MESSAGE.validateFileSize, () => helpers.azure.validateFileSize(fileToReplace))
      .test('invalidAttachmentType', VALIDATE_MESSAGE.validateFileType, () => helpers.azure.validateFileType(fileToReplace)),
  });

  const fileWasNotUpload = () => {
    dispatch(addNotification('file upload', 'Your file wasn\'t uploaded', NOTIFICATION_STATUS.ERROR));
  };

  const fileWasNotDelete = () => {
    dispatch(addNotification('file delete', 'Your file wasn\'t deleted', NOTIFICATION_STATUS.ERROR));
  };

  const onSubmit = async (values, {
    resetForm, setStatus, setSubmitting
  }) => {
    setSubmitting(true);
    const doWhenTry = () => {
      dispatch(setCurrentMessage({}));
      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      onCloseModal();
    };
    const doWhenCatch = () => {
      setStatus({ success: false });
      setSubmitting(false);
    };

    // Determining the attachment container based on file changes:
    // - If the file was deleted (fileToReplace === null), set container to null.
    // - If a new file was attached (fileToReplace is an object), generate a new container name.
    // - Otherwise, keep the existing container.
    const attachmentContainerToSend = fileToReplace === null
      ? null
      : fileToReplace
        ? helpers.azure.getUserContainerName(databaseBeingUsed, user.id)
        : values.messageAttachmentContainer;

    const newValuesAttachment = await helpers.azure.loadFileToAzureAndGetStatusCode(fileToReplace, attachment, user.id, azAccountName, STORAGE.sas, databaseBeingUsed, attachmentContainerToSend, fileWasNotUpload, fileWasNotDelete, false, 'EO-ID', false);
    if (newValuesAttachment === null) {
      dispatch(addNotification('saveFile', 'There are some problems with Azure service.', NOTIFICATION_STATUS.ERROR));
      return;
    }
    if (helpers.isEmpty(values.message) && (!newValuesAttachment || helpers.isEmpty(newValuesAttachment))) {
      dispatch(addNotification('sendFile', 'You must enter either a text message or select an image.', NOTIFICATION_STATUS.ERROR));
      return;
    }
    const attachmentFromMessage = !helpers.isEmpty(values.attachment) && fileToReplace !== null ? values.attachment : null;
    const attachmentToSend = !helpers.isEmpty(newValuesAttachment) ? newValuesAttachment : attachmentFromMessage;

    const sendData = {
      id,
      scheduledAt,
      body: !helpers.isEmpty(values.message) ? values.message : null,
      attachment: attachmentToSend,
      messageAttachmentContainer: attachmentContainerToSend
    };
    dispatch(sendOrEditScheduledMessage(sendData, chatKey, doWhenTry, doWhenCatch));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values,
  } = formik;

  const isLoading = isSubmitting || isMessageSending;

  const { getInputProps, open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png']
    },
  });

  const updateFileUploadToMessage = (value) => {
    dispatch(setFileUploadToMessage(value, isMessageEdit));
    setFieldValue('attachment', '', false);
    setFieldValue('messageAttachmentContainer', null, false);
  };

  const validateFileInput = (uploadFileData) => {
    if (!helpers.azure.validateFileSize(uploadFileData)) {
      dispatch(addNotification('attachment-size', VALIDATE_MESSAGE.validateFileSize, NOTIFICATION_STATUS.ERROR));
      return false;
    }
    if (!helpers.azure.validateFileType(uploadFileData)) {
      dispatch(addNotification('attachment-type', VALIDATE_MESSAGE.validateFileType, NOTIFICATION_STATUS.ERROR));
      return false;
    }
    return true;
  };

  const handleClose = () => {
    dispatch(setCurrentMessage({}));
    onCloseModal();
  };

  const handleOnSelectedFile = async (event) => {
    const { target: { files, value } } = event;
    if (!files || !value) {
      return;
    }
    const uploadFileData = {
      file: files[0],
      value
    };
    if (validateFileInput(uploadFileData)) {
      dispatch(setFileUploadToMessage(uploadFileData, isMessageEdit));
    }
  };

  return (
    <Dialog
      open={isModalVisible}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          padding: '24px',
        },
      }}
    >
      <Box
        sx={{
          padding: '0 0 0 16px',
          textAlign: 'center',
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '18px',
            padding: 0,
          }}
        >
          Update Message
        </DialogTitle>
        <Typography
          variant="subtitle1"
          color="#546E7A"
        >
          Update this scheduled message
        </Typography>
      </Box>
      <DialogContent>
        <form
          id="formEditScheduledMessage"
          onSubmit={handleSubmit}
        >
          <Spinner
            loading={isLoading}
            positionType="absolute"
          />
          {(fileToReplace || values.attachment) && (
            <AttachedFilePrewiew
              fileName={helpers.azure.getImageFileURL(values.attachment, user.id, azAccountName, STORAGE.sas, databaseBeingUsed, values.messageAttachmentContainer)}
              fileUpload={fileToReplace}
              isLoading={isLoading}
              validateFileInput={validateFileInput}
              setFileUploadToMessage={updateFileUploadToMessage}
            />
          )}
          <OutlinedInput
            name="message"
            color="secondary"
            placeholder="Write something..."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            disabled={isLoading}
            size="small"
            rows={8}
            endAdornment={(
              <ImputEndAdornment
                isLoading={isLoading}
                openUploadDialog={open}
              />
            )}
            sx={{
              marginTop: '8px',
            }}
            fullWidth
            multiline
          />
          <input {...getInputProps({ onChange: handleOnSelectedFile })} />
        </form>
      </DialogContent>
      <DialogActions
        sx={{
          padding: 0,
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            ...STYLE_OPTIONS.BUTTON_PRIMARY_REVERSED,
            minWidth: '105px',
            marginRight: '14px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={(!values.message && !fileToReplace && !values.attachment) || isLoading}
          sx={{
            ...STYLE_OPTIONS.BUTTON_PRIMARY,
            minWidth: '105px',
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalEditScheduledMessage.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  chatKey: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

ModalEditScheduledMessage.defaultProps = {};

export default ModalEditScheduledMessage;
