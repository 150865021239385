import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => !['textSize', 'onTheTopOfText', 'hideAsterisk'].includes(prop),
})(({
  theme,
  onTheTopOfText,
  textSize,
  hideAsterisk,
}) => ({
  marginTop: '7px',
  ...onTheTopOfText ? {
    alignItems: 'start',
  } : {},
  ...textSize ? {
    '& .MuiTypography-root': {
      fontSize: `${textSize}px`,
    },
  } : {},
  ...hideAsterisk ? {
    '& .MuiFormControlLabel-asterisk': {
      display: 'none',
    },
  } : {},
  [theme.breakpoints.down('lg')]: {
    marginTop: '15px',
  },
  '& .MuiTypography-body1': {
    marginLeft: '1px',
  },
}));

function FormControlLabelCheckboxV2({
  children,
  ...props
}) {
  return (
    <CustomFormControlLabel {...props}>{children}</CustomFormControlLabel>
  );
}

FormControlLabelCheckboxV2.propTypes = {
  children: PropTypes.node,
  onTheTopOfText: PropTypes.bool,
  textSize: PropTypes.oneOf([null, '12', '11', '10']),
  hideAsterisk: PropTypes.bool,
};

FormControlLabelCheckboxV2.defaultProps = {
  children: null,
  onTheTopOfText: null,
  textSize: null,
  hideAsterisk: null,
};

export default FormControlLabelCheckboxV2;
