const ROUTES = {
  APP_ACCOUNT: '/app/account',
  APP_MANAGEMENT_EVENTS: '/app/management/events',
  APP_MANAGEMENT_CUSTOMERS: '/app/management/customers',
  APP_MANAGEMENT_CHAT: '/app/management/chat',
  APP_HELP_AND_INFORMATION: '/app/help-and-information',
  APP_EVENT_SETTINGS: '/app/event-settings',
  APP_ORDER_SETTINGS: '/app/order-settings',
  APP_PERMISSION_DENIED: '/app/access-denied',
  APP_404: '/app/404',
};

export default ROUTES;
