import React from 'react';
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}));

function Error403View() {
  const { classes } = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page
      className={classes.root}
      title="403: Access Denied"
    >
      <Container
        maxWidth="lg"
      >
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          403: Access Denied
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
          sx={{
            mt: 1,
          }}
        >
          You don’t have permission to view this page. Please check your credentials or contact our support team.
        </Typography>
        <Box
          mt={1}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Error 403: Access Denied"
            className={classes.image}
            src="/static/images/error-403.svg"
          />
        </Box>
      </Container>
    </Page>
  );
}

export default Error403View;
