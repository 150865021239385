import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS, FORM_POST_HEADER } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const SET_LOADING = '@form/set-loading';

export const GET_FORM = '@form/get-form';
export const GET_FORM_SUCCESS = '@form/get-form-success';
export const GET_FORM_ERROR = '@form/get-form-error';

export const GET_FORM_OPT_IN = '@form/get-form-opt-in';
export const GET_FORM_OPT_IN_SUCCESS = '@form/get-form-opt-in-success';
export const GET_FORM_OPT_IN_ERROR = '@form/get-form-opt-in-error';

export const SUBMIT_FORM = '@form/submit-form';
export const SUBMIT_FORM_SUCCESS = '@form/submit-form-success';
export const SUBMIT_FORM_ERROR = '@form/submit-form-error';

export const SET_FORM_SUBMIT_FORM = '@form/set-form-submit-form';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const getForm = (formId) => async (dispatch) => {
  dispatch({
    type: GET_FORM,
  });
  try {
    const response = await axios.get(`/api/forms/${formId}`, FORM_POST_HEADER);
    dispatch({
      type: GET_FORM_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FORM_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_ERROR,
    });
  }
};

export const submitForm = (formSubmission, token, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_FORM,
    });
    const response = await axios.put('/api/forms', {
      formSubmission,
      gRecaptchaResponse: token,
    }, FORM_POST_HEADER);
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SUBMIT_FORM_ERROR, 'Could not submit form', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SUBMIT_FORM_ERROR,
      });
      return;
    }

    dispatch(addNotification(SUBMIT_FORM_SUCCESS, 'Successfully submitted', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SUBMIT_FORM_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SUBMIT_FORM_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SUBMIT_FORM_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const getFormOptIn = (formId, options) => async (dispatch) => {
  dispatch({
    type: GET_FORM_OPT_IN,
  });
  const {
    phone,
  } = options || {};
  try {
    const response = await axios.post('/api/forms/optIn', {
      formId,
      phone,
    }, FORM_POST_HEADER);
    dispatch({
      type: GET_FORM_OPT_IN_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FORM_OPT_IN_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FORM_OPT_IN_ERROR,
      payload: {
        phoneOptIn: false,
        phoneNumber: phone,
      },
    });
  }
};

export const setFormSubmitForm = (payload) => ({
  type: SET_FORM_SUBMIT_FORM,
  payload
});
