export const FIELDS = {
  // Fields Without Grouping
  type: 'Contact Type',
  companyContactId: 'Company Contact ID',
  // Name fields
  firstName: 'First',
  lastName: 'Last',
  //  Phone Fields
  phone1: 'Number 1',
  phone2: 'Number 2',
  email: 'Email Address',
  //  Birthday Fields
  birthday: 'Birthday (MM/DD/YYYY)',
  birthdayMonth: 'Month',
  birthdayDay: 'Day',
  birthdayYear: 'Year',
  //  Address Fields
  address1: 'Line 1',
  address2: 'Line 2',
  city: 'City',
  region: 'State/Province',
  postalCode: 'Postal Code',
  //  Less Common Fields
  hostFirstName: 'Host First Name Originally Purchased From',
  hostLastName: 'Host Last Name Originally Purchased From',
  firstOrderDate: 'First Order Date',
  lastOrderDate: 'Last Order Date',
  notes: 'Notes',
};

export const STEPS = {
  upload: {
    key: 0,
    label: 'Upload CSV file'
  },
  setup: {
    key: 1,
    label: 'Setup fields mapping'
  },
  results: {
    key: 2,
    label: 'Import results'
  },
};
