import PropTypes from 'prop-types';
import React from 'react';
import { List } from '@mui/material';
import NavItemsChild from 'src/layouts/DashboardLayout/NavBar/NavItemsChild';

// function NavItemsParent({ items, isPageBlocked, ...rest }) {
function NavItemsParent({
  items,
  isPageBlocked,
  isBetaUser,
  ...rest
}) {
  return (
    <List disablePadding>
      {/* {items.map((item) => ( */}
      {items.filter((item) => !item.betaOnly || isBetaUser).map((item) => (
        <NavItemsChild
          key={item.title}
          item={item}
          isPageBlocked={isPageBlocked}
          {...rest}
        />
      ))}
    </List>
  );
}

NavItemsParent.propTypes = {
  items: PropTypes.array.isRequired,
  isPageBlocked: PropTypes.bool.isRequired,
  isBetaUser: PropTypes.bool,
  userRolePermissions: PropTypes.array,
};

NavItemsParent.defaultProps = {
  isBetaUser: false,
  userRolePermissions: [],
};

export default NavItemsParent;
