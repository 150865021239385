import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';

export const GET_CHATS_LIST = '@chat/get-chats-list';
export const GET_CHATS_LIST_SUCCESS = '@chat/get-chats-list-success';
export const GET_CHATS_LIST_ERROR = '@chat/get-chats-list-error';

export const GET_CONTACTS = '@chat/get-contacts';
export const GET_CONTACTS_SUCCESS = '@chat/get-contacts-success';
export const GET_CONTACTS_ERROR = '@chat/get-contacts-error';
export const SET_CONTACTS_QUERY = '@chat/set-contacts-query';

export const GET_MORE_CHAT_MESSAGES = '@chat/get-more-chat-messages';
export const GET_MORE_CHAT_MESSAGES_SUCCESS = '@chat/get-more-chat-messages-success';
export const GET_MORE_CHAT_MESSAGES_ERROR = '@chat/get-more-chat-messages-error';

export const GET_MORE_SCHEDULED_CHAT_MESSAGES = '@chat/get-more-scheduled-chat-messages';
export const GET_MORE_SCHEDULED_CHAT_MESSAGES_SUCCESS = '@chat/get-more-scheduled-chat-messages-success';
export const GET_MORE_SCHEDULED_CHAT_MESSAGES_ERROR = '@chat/get-more-scheduled-chat-messages-error';

export const GET_FILTERED_CHAT_MESSAGES = '@chat/get-filtered-chat-messages';
export const GET_FILTERED_CHAT_MESSAGES_SUCCESS = '@chat/get-filtered-chat-messages-success';
export const GET_FILTERED_CHAT_MESSAGES_ERROR = '@chat/get-filtered-chat-messages-error';

export const CLEAR_FILTERED_CHAT_MESSAGES = '@chat/clear-filtered-chat-messages';

export const MARK_CHAT_AS_SEEN = '@chat/mark-chat-as-seen';
export const MARK_CHAT_AS_SEEN_SUCCESS = '@chat/mark-chat-as-seen-success';
export const MARK_CHAT_AS_SEEN_ERROR = '@chat/mark-chat-as-seen-error';

export const MARK_CHAT_AS_UNREAD = '@chat/mark-chat-as-unread';
export const MARK_CHAT_AS_UNREAD_SUCCESS = '@chat/mark-chat-as-unread-success';
export const MARK_CHAT_AS_UNREAD_ERROR = '@chat/mark-chat-as-unread-error';

export const ADD_MESSAGE = '@chat/add-message';
export const ADD_MESSAGE_SUCCESS = '@chat/add-message-success';
export const ADD_MESSAGE_ERROR = '@chat/add-message-error';

export const SOCKET_CHAT_TOTAL_UNREAD_COUNT = '@chat/socket-chat-total-unread-count';

export const SOCKET_CHAT_NEW_MESSAGE = '@chat/socket-chat-new-message';

export const SOCKET_CHAT_NEW_SCHEDULED_MESSAGE = '@chat/socket-chat-new-scheduled-message';

export const SOCKET_CHAT_MESSAGE_DELETE = '@chat/socket-chat-message-delete';

export const SOCKET_CHAT_SCHEDULED_MESSAGE_DELETE = '@chat/socket-chat-scheduled-message-delete';

export const SOCKET_CHAT_SCHEDULED_MESSAGE_UPDATE = '@chat/socket-chat-scheduled-message-update';

export const SOCKET_CHAT_MESSAGE_SENT = '@chat/socket-chat-message-sent';

export const SOCKET_CHAT_SCHEDULED_MESSAGE_SENT = '@chat/socket-chat-scheduled-message-sent';

export const SOCKET_CHAT_MESSAGE_READ = '@chat/socket-chat-message-read';

export const SOCKET_CHAT_MESSAGE_DELIVERED = '@chat/socket-chat-message-delivered';

export const SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE = '@chat/send-now-or-edit-scheduled-message';
export const SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_SUCCESS = '@chat/send-now-or-edit-scheduled-message-success';
export const SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_ERROR = '@chat/send-now-or-edit-scheduled-message-error';

export const SET_CURRENT_MESSAGE = '@chat/set-current-message';
export const SET_FILE_UPLOAD_TO_MESSAGE = '@chat/set-file-upload-to-message';

export const DELETE_CHAT_MESSAGE = '@chat/delete-chat-message';
export const DELETE_CHAT_MESSAGE_SUCCESS = '@chat/delete-chat-message-success';
export const DELETE_CHAT_MESSAGE_ERROR = '@chat/delete-chat-message-error';

export const getChatsList = () => async (dispatch) => {
  dispatch({
    type: GET_CHATS_LIST,
  });
  try {
    const response = await axios.get('/api/messages/chats');
    dispatch({
      type: GET_CHATS_LIST_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_CHATS_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CHATS_LIST_ERROR,
    });
  }
};

export const getContacts = (isSearch = false, query = null) => async (dispatch, getState) => {
  dispatch({
    type: GET_CONTACTS,
  });
  try {
    const response = await axios.post('/api/contacts', query || getState()?.chat?.allContactsList?.query || {});
    dispatch({
      type: GET_CONTACTS_SUCCESS,
      payload: {
        isSearch,
        data: response?.data || {},
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_CONTACTS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CONTACTS_ERROR,
    });
  }
};

export const setContactsQuery = (query) => ({
  type: SET_CONTACTS_QUERY,
  payload: query
});

export const getMoreChatMessages = (cursor, contactId, phone) => async (dispatch) => {
  dispatch({
    type: GET_MORE_CHAT_MESSAGES,
  });
  try {
    const response = await axios.get('/api/messages', {
      params: {
        cursor,
        contactId,
        phone,
      },
    });
    dispatch({
      type: GET_MORE_CHAT_MESSAGES_SUCCESS,
      payload: {
        contactId,
        data: response?.data || {},
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_MORE_CHAT_MESSAGES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_MORE_CHAT_MESSAGES_ERROR,
    });
  }
};

export const getMoreScheduledChatMessages = (cursor, contactId, phone) => async (dispatch) => {
  dispatch({
    type: GET_MORE_SCHEDULED_CHAT_MESSAGES,
  });
  try {
    const response = await axios.get('/api/messages/scheduled', {
      params: {
        cursor,
        contactId,
        phone,
      },
    });
    dispatch({
      type: GET_MORE_SCHEDULED_CHAT_MESSAGES_SUCCESS,
      payload: {
        contactId,
        data: response?.data || {},
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_MORE_SCHEDULED_CHAT_MESSAGES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_MORE_SCHEDULED_CHAT_MESSAGES_ERROR,
    });
  }
};

export const getFilteredChatMessages = ({ cursor, contactId, phone }) => async (dispatch) => {
  dispatch({
    type: GET_FILTERED_CHAT_MESSAGES,
  });
  try {
    const params = {
      cursor,
      contactId,
      phone,
    };

    const [messagesResponse, scheduledResponse] = await Promise.all([
      axios.get('/api/messages', { params }),
      axios.get('/api/messages/scheduled', { params })
    ]);

    dispatch({
      type: GET_FILTERED_CHAT_MESSAGES_SUCCESS,
      payload: {
        chatKey: contactId,
        filteredByPhone: phone || '',
        regularMessages: messagesResponse?.data || [],
        scheduledMessages: scheduledResponse?.data || [],
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_FILTERED_CHAT_MESSAGES_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_FILTERED_CHAT_MESSAGES_ERROR,
    });
  }
};

export const clearFilteredChatMessages = () => ({
  type: CLEAR_FILTERED_CHAT_MESSAGES,
});

export const markChatAsSeen = (chatKey, unreadMessageIds) => async (dispatch) => {
  dispatch({
    type: MARK_CHAT_AS_SEEN,
  });
  try {
    const response = await axios.patch('/api/messages/read', {
      id: unreadMessageIds,
    });
    dispatch({
      type: MARK_CHAT_AS_SEEN_SUCCESS,
      payload: {
        chatKey,
        updatedMessages: response?.data || [],
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(MARK_CHAT_AS_SEEN_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: MARK_CHAT_AS_SEEN_ERROR,
    });
  }
};

export const markChatAsUnread = (chatKey, doWhenTry = false, doWhenCatch = false,) => async (dispatch) => {
  dispatch({
    type: MARK_CHAT_AS_UNREAD,
  });
  try {
    const response = await axios.patch('/api/messages/unread', {
      contactId: chatKey
    });
    dispatch({
      type: MARK_CHAT_AS_UNREAD_SUCCESS,
      payload: {
        chatKey,
        updatedMessages: response?.data || [],
      }
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(MARK_CHAT_AS_UNREAD_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: MARK_CHAT_AS_UNREAD_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const addMessage = (isScheduledMessage = false, sendData, chatKey, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  dispatch({
    type: ADD_MESSAGE,
  });
  try {
    const url = isScheduledMessage ? '/api/messages/scheduled' : '/api/messages';
    const response = await axios.post(url, sendData);

    dispatch({
      type: ADD_MESSAGE_SUCCESS,
      payload: {
        chatKey,
        messages: isScheduledMessage ? [] : response?.data || [],
        scheduledMessages: isScheduledMessage ? response?.data || [] : [],
        contact: getState().customerDetails.customer,
      }
    });

    if (doWhenTry) {
      doWhenTry(isScheduledMessage);
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(ADD_MESSAGE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: ADD_MESSAGE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const socketChatTotalUnreadCount = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_TOTAL_UNREAD_COUNT,
    payload: message,
  });
};

export const socketChatNewMessage = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_NEW_MESSAGE,
    payload: message,
  });
};

export const socketChatNewScheduledMessage = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_NEW_SCHEDULED_MESSAGE,
    payload: message,
  });
};

export const socketChatMessageDelete = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_MESSAGE_DELETE,
    payload: message,
  });
};

export const socketChatScheduledMessageDelete = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_SCHEDULED_MESSAGE_DELETE,
    payload: message,
  });
};

export const socketChatScheduledMessageUpdate = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_SCHEDULED_MESSAGE_UPDATE,
    payload: message,
  });
};

export const socketChatMessageSent = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_MESSAGE_SENT,
    payload: message,
  });
};

export const socketChatMessageRead = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_MESSAGE_READ,
    payload: message,
  });
};

export const socketChatMessageDelivered = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_MESSAGE_DELIVERED,
    payload: message,
  });
};

export const socketChatScheduledMessageSent = (message) => async (dispatch) => {
  dispatch({
    type: SOCKET_CHAT_SCHEDULED_MESSAGE_SENT,
    payload: message,
  });
};

export const sendOrEditScheduledMessage = (sendData, chatKey, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  dispatch({
    type: SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE,
  });
  try {
    const isSendNow = sendData?.sendNow;
    const response = await axios.patch('/api/messages/scheduled', sendData);

    dispatch({
      type: SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_SUCCESS,
      payload: {
        chatKey,
        isSendNow,
        updatedMessage: response?.data || {},
      }
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setCurrentMessage = (message) => ({
  type: SET_CURRENT_MESSAGE,
  payload: message,
});

export const setFileUploadToMessage = (file, isMessageEdit = false) => ({
  type: SET_FILE_UPLOAD_TO_MESSAGE,
  payload: {
    file,
    isMessageEdit,
  }
});

export const deleteChatMessage = (chatKey, messageId, isScheduledMessage = false) => async (dispatch) => {
  dispatch({
    type: DELETE_CHAT_MESSAGE,
  });
  try {
    const response = await axios.delete('/api/messages', {
      data: { id: [messageId] },
    });
    dispatch({
      type: DELETE_CHAT_MESSAGE_SUCCESS,
      payload: {
        chatKey,
        messageId,
        isScheduledMessage,
        updatedMessages: response?.data || [],
      }
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_CHAT_MESSAGE_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: DELETE_CHAT_MESSAGE_ERROR,
    });
  }
};
