/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LOADING,
  SET_EMAIL_ADDRESS,
  SIGNUP_STEPS,
  SIGNUP_STEPS_ERROR,
  SIGNUP_STEPS_SUCCESS,
  SET_NON_FORM_ERROR_MESSAGE,
  CONFIRM_CONSULTANT,
  CONFIRM_CONSULTANT_ERROR,
  CONFIRM_CONSULTANT_SUCCESS,
  UPDATE_FILTERS,
  UPDATE_FILTERS_ERROR,
  UPDATE_FILTERS_SUCCESS,
  SET_WATCHED_FORWARDING_VIDEO,
  SET_WATCHED_WELCOME_VIDEO,
  GET_COMPANIES_LIST,
  GET_COMPANIES_LIST_SUCCESS,
  GET_COMPANIES_LIST_ERROR,
} from 'src/actions/signupActions';

const initialState = {
  loading: false,
  signupVersion: '2',
  nextStep: 'Get Email Address',
  emailProvider: null,
  emailAddress: '',
  companyIds: null,
  companyId: null, // FUTURE: Allow user to select which ONE they are setting up.
  companyName: '',
  message: null,
  screenMessage: null,
  tokenExpirationDateTime: null,
  watchedForwardingVideo: false,
  watchedWelcomeVideo: false,
  companies: [],
  signupInstructions: null,
  firstName: '',
  lastName: '',
  mobilePhone: '',
  country: '',
  address: '',
  city: '',
  region: '',
  postalCode: '',
  timeZone: '',
  company: '',
  pbAPIKey: '',
  pbPhone: '',
  referralCode: '',
};

const signupReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        draft.loading = payload;
      });
    }

    case SET_EMAIL_ADDRESS: {
      return produce(state, (draft) => {
        draft.emailAddress = payload;
      });
    }

    case SIGNUP_STEPS: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case SIGNUP_STEPS_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.message = payload?.message || null;
        draft.screenMessage = payload?.screenMessage || null;
      });

    case SIGNUP_STEPS_SUCCESS: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.emailProvider = payload?.emailProvider || state.emailProvider;
        draft.signupVersion = payload?.signupVersion || state.signupVersion;
        draft.nextStep = payload?.nextStep || state.nextStep;
        draft.companyIds = payload?.companyIds || state.companyIds;
        draft.companyId = payload?.companyId || state.companyId;
        draft.companyName = payload?.companyName || state.companyName;
        draft.message = null;
        draft.screenMessage = null;
        draft.tokenExpirationDateTime = payload?.tokenExpirationDateTime || state.tokenExpirationDateTime;
        draft.signupInstructions = payload?.signupInstructions || state.signupInstructions;
        draft.firstName = payload?.firstName || state.firstName;
        draft.lastName = payload?.lastName || state.lastName;
        draft.mobilePhone = payload?.mobilePhone || state.mobilePhone;
        draft.country = payload?.country || state.country;
        draft.address = payload?.address || state.address;
        draft.city = payload?.city || state.city;
        draft.region = payload?.region || state.region;
        draft.postalCode = payload?.postalCode || state.postalCode;
        draft.timeZone = payload?.timeZone || state.timeZone;
        draft.company = payload?.company || state.company;
        draft.pbAPIKey = payload?.pbAPIKey || state.pbAPIKey;
        draft.pbPhone = payload?.pbPhone || state.pbPhone;
        draft.referralCode = payload?.referralCode || state.referralCode;
      });
    }

    case SET_NON_FORM_ERROR_MESSAGE:
      return produce(state, (draft) => {
        draft.message = payload?.errorTitle || null;
        draft.screenMessage = payload?.errorMessage || null;
      });

    case CONFIRM_CONSULTANT: {
      return produce(state, (draft) => {
        draft.signup.loading = true;
      });
    }

    case CONFIRM_CONSULTANT_ERROR:
    case CONFIRM_CONSULTANT_SUCCESS: {
      return produce(state, (draft) => {
        draft.signup.loading = false;
      });
    }

    case UPDATE_FILTERS: {
      return produce(state, (draft) => {
        draft.signup.loading = true;
      });
    }

    case UPDATE_FILTERS_ERROR:
    case UPDATE_FILTERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.signup.loading = false;
      });
    }

    case SET_WATCHED_FORWARDING_VIDEO: {
      return produce(state, (draft) => {
        draft.watchedForwardingVideo = true;
      });
    }

    case SET_WATCHED_WELCOME_VIDEO: {
      return produce(state, (draft) => {
        draft.watchedWelcomeVideo = true;
      });
    }

    case GET_COMPANIES_LIST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case GET_COMPANIES_LIST_SUCCESS: {
      return produce(state, (draft) => {
        draft.companies = payload || {};
        draft.loading = false;
      });
    }

    case GET_COMPANIES_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default signupReducer;
