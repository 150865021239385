import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Error403View from 'src/views/pages/Error403View';
import helpers from 'src/helpers';

function UserPermissionGuard({
  permissions,
  pathToRedirect,
  children,
}) {
  const {
    permissions: userRolePermissions,
  } = useSelector((state) => state?.account?.user?.userRole || {});

  if (permissions && !helpers.permissions.doesUserHaveOneOfPermissions(permissions, userRolePermissions)) {
    return pathToRedirect ? <Redirect to={pathToRedirect} /> : <Error403View />;
  }

  return children;
}

UserPermissionGuard.propTypes = {
  permissions: PropTypes.array,
  pathToRedirect: PropTypes.string,
  children: PropTypes.any,
};

export default UserPermissionGuard;
