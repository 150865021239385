import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const CustomButton = styled(Button)(({ theme }) => ({
  height: '30px',
  border: '1px solid',
  borderRadius: '34px',
  textTransform: 'none',
  fontWeight: 'regular',
  padding: '6px 14px',
  color: theme.palette.secondary.main,
  bgcolor: 'transparent',
  '&:hover': {
    backgroundColor: '#A1A1F5',
    color: '#fff',
  },
}));

function LoadMoreButton({ children, startIcon, ...props }) {
  return (
    <CustomButton startIcon={startIcon} {...props}>
      {children}
    </CustomButton>
  );
}

LoadMoreButton.propTypes = {
  children: PropTypes.node,
  startIcon: PropTypes.node,
};

LoadMoreButton.defaultProps = {
  children: null,
  startIcon: null,
};

export default LoadMoreButton;
