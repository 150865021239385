import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { STYLE_OPTIONS } from 'src/constants';

function ButtonV1({
  children,
  styleType,
  ...props
}) {
  const CustomButton = useMemo(() => {
    const color = '#5850EC';
    const colorSecondary = '#BFBDC1';
    const colorDisabled = '#CCCCCC';

    return styled(Button)(() => ({
      fontFamily: STYLE_OPTIONS.FONT_FAMILY,
      textTransform: 'none',
      marginLeft: '12px',
      marginRight: '12px',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      padding: '12px 40px',
      '&.Mui-disabled, &.Mui-disabled.primary2, &.white-btn.Mui-disabled, &.white-btn.Mui-disabled.primary2': {
        background: colorDisabled,
        boxShadow: `0px 0px 0px 1px ${colorDisabled}`,
        color: '#777777',
      },

      ...styleType === 'close' ? {
        color: '#ffffff',
        background: colorSecondary,
        boxShadow: `0px 0px 0px 1px ${colorSecondary}`,
        '&:hover': {
          color: colorSecondary,
          boxShadow: `0px 0px 0px 1px ${colorSecondary}`
        },
      } : {},

      ...styleType === 'white' ? {
        color,
        boxShadow: `0px 0px 0px 1px ${color}`,
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: 'rgba(38, 50, 56, 0.04)',
        },
      } : {},

      ...styleType === 'ok' ? {
        color: '#ffffff',
        background: color,
        boxShadow: `0px 0px 0px 1px ${color}`,
        '&:hover': {
          color,
          boxShadow: `0px 0px 0px 1px ${color}`,
          backgroundColor: 'rgba(57, 73, 171, 0.04)',
        },
      } : {},
    }));
  }, [styleType]);

  return (
    <CustomButton {...props}>{children}</CustomButton>
  );
}

ButtonV1.propTypes = {
  children: PropTypes.node,
  styleType: PropTypes.oneOf([
    'close',
    'ok',
    'white',
  ]),
};

ButtonV1.defaultProps = {
  children: null,
  styleType: '',
};

export default ButtonV1;
