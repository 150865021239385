import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import ModalDialogMUI6 from 'src/components/ModalDialogMUI6';
import GrantEmailAccess from 'src/containers/GrantEmailAccountAccess';
import {
  setGetUsersEmailToken,
  getEmailTokenFromAccessCode,
} from 'src/actions/accountActions';
import helpers from 'src/helpers';
import FormCard from './styled/FormCard';

function GetUsersEmailToken({
  isVisible,
  emailAddress,
  emailProvider,
  showError,
  setBackBackEndError,
}) {
  const dispatch = useDispatch();

  const handleAuthSuccess = (response) => {
    const authCode = response?.code || (helpers.isString(response) ? response : null);
    dispatch(getEmailTokenFromAccessCode({
      authCode,
      emailProvider,
    }));
  };

  const handleClose = () => {
    dispatch(setGetUsersEmailToken(false));
  };

  return isVisible && (
    <ModalDialogMUI6
      isVisible={isVisible}
      isHeaderVisible={false}
      buttonsListOrder={[]}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      content={(
        <FormCard>
          <Box sx={{ p: 3 }}>
            <GrantEmailAccess
              loading={false}
              emailAddress={emailAddress}
              emailProvider={emailProvider}
              calledFrom="SignIn"
              onAuthSuccess={handleAuthSuccess}
              setBackEndError={setBackBackEndError}
              showError={showError}
            />
          </Box>
        </FormCard>
      )}
    />
  );
}

GetUsersEmailToken.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string.isRequired,
  emailProvider: PropTypes.string.isRequired,
  showError: PropTypes.node,
  setBackBackEndError: PropTypes.func.isRequired,
};

GetUsersEmailToken.defaultProps = {
  showError: null,
};

export default GetUsersEmailToken;
