import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { setCurrentNavigationTab, refreshCustomFields } from 'src/actions/accountSettingsActions';
import helpers from 'src/helpers';
import { needOtherInfoNow, needCustomFieldsNow } from './helpers/check';

function RedirectLogic({ defaultRedirects }) {
  const dispatch = useDispatch();
  const {
    user,
    pbAPIKeyValid,
  } = useSelector((state) => state.account);
  const { currentNavigationTab } = useSelector((state) => state.accountSettings);

  const {
    userRole,
  } = user || {};

  const {
    permissions: userRolePermissions,
  } = userRole || {};

  // Early return if no user
  if (helpers.isEmpty(user)) {
    return null;
  }

  const doesUserHaveSubscription = helpers.doesUserHaveSubscription(user);
  const isAdmin = helpers.isUserAdmin(user);
  const currentPath = window.location.pathname;

  // Only check redirects if we're not already on the account page
  const shouldCheckRedirects = currentPath.indexOf(ROUTES.APP_ACCOUNT) !== 0;
  const canShowHelpAndInformation = !helpers.isEmptyApiKey(user) && window.location.pathname.indexOf(ROUTES.APP_HELP_AND_INFORMATION) === 0;

  // Required Tab Check
  const currentIsRequiredTab = currentNavigationTab === 'required' && currentPath.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (shouldCheckRedirects && helpers.isPageBlocked(user) && !currentIsRequiredTab && !canShowHelpAndInformation) {
    dispatch(setCurrentNavigationTab(false));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  // Subscription Tab Check
  const currentIsSubscriptionTab = currentNavigationTab === 'subscription' && currentPath.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (shouldCheckRedirects && !isAdmin && !helpers.isPageBlocked(user) && !doesUserHaveSubscription && !currentIsSubscriptionTab) {
    dispatch(setCurrentNavigationTab('subscription'));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  // Other Tab Check
  const currentIsOtherTab = currentNavigationTab === 'other' && currentPath.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (shouldCheckRedirects && needOtherInfoNow(user) && !currentIsOtherTab && !canShowHelpAndInformation) {
    dispatch(setCurrentNavigationTab('other'));
    return <Redirect to={ROUTES.APP_ACCOUNT} />;
  }

  const currentIsCustomFieldsTab = currentNavigationTab === 'customFields' && window.location.pathname.indexOf(ROUTES.APP_ACCOUNT) === 0;
  if (needCustomFieldsNow(user, pbAPIKeyValid) && !currentIsCustomFieldsTab && !canShowHelpAndInformation) {
    dispatch(refreshCustomFields(user.id));
    if (needCustomFieldsNow(user, pbAPIKeyValid)) {
      dispatch(setCurrentNavigationTab('customFields'));
      return <Redirect to={ROUTES.APP_ACCOUNT} />;
    }
  }

  if (defaultRedirects && helpers.isArray(defaultRedirects) && defaultRedirects.length > 0) {
    // eslint-disable-next-line no-restricted-syntax,no-unreachable-loop
    for (const defaultRedirect of defaultRedirects) {
      if (defaultRedirect?.url && (!defaultRedirect?.permissions || helpers.permissions.doesUserHaveOneOfPermissions(defaultRedirect?.permissions, userRolePermissions))) {
        return <Redirect to={defaultRedirect.url} />;
      }
    }
  }

  return null;
}

RedirectLogic.propTypes = {
  defaultRedirects: PropTypes.array,
};
RedirectLogic.defaultProps = {
  defaultRedirects: null,
};

export default RedirectLogic;
