import axios from 'src/utils/axios';
import { addNotification } from 'src/actions/notificationActions';
import { getAccountInfo } from 'src/actions/accountActions';
import { NOTIFICATION_STATUS } from 'src/constants';
import helpers from 'src/helpers';

export const SET_LOADING = '@accountSettings/set-loading';
export const SET_FORM_ACCOUNT_TAB_OTHER = '@accountSettings/set-formAccountTabOther';
export const SET_FORM_ACCOUNT_TAB_CAMPAIGNS = '@accountSettings/set-formAccountTabCampaigns';
export const SET_CURRENT_NAVIGATION_TAB = '@accountSettings/set-current-navigation-tab';
export const GET_USER_INFO_BY_ID = '@accountSettings/get-user-info-by-id';
export const GET_USER_INFO_BY_ID_ERROR = '@accountSettings/get-user-info-by-id-error';
export const UPDATE_USER_API_KEY = '@accountSettings/update-user-api-key';
export const UPDATE_ACCOUNT_REQUIRED = '@accountSettings/update-account-required';
export const UPDATE_ACCOUNT_CAMPAIGNS_TAGS = '@accountSettings/update-account-campaigns-tags';
export const UPDATE_ACCOUNT_OTHER = '@accountSettings/update-account-other';
export const UPDATE_USER_PASSWORD = '@accountSettings/update-user-password';

export const RETRIEVE_USER_SUBSCRIPTION = '@accountSettings/retrieve-user-subscription';

export const GET_USER_SUBSCRIPTION = '@accountSettings/get-user-subscription';
export const GET_USER_SUBSCRIPTION_SUCCESS = '@accountSettings/get-user-subscription-success';
export const GET_USER_SUBSCRIPTION_ERROR = '@accountSettings/get-user-subscription-error';

export const SUBSCRIBE = '@accountSettings/subscribe';
export const SUBSCRIBE_SUCCESS = '@accountSettings/subscribe-success';
export const SUBSCRIBE_ERROR = '@accountSettings/subscribe-error';

export const CANCEL_SUBSCRIPTION_AT_PERIOD_END = '@accountSettings/cancel-subscription-at-period-end';
export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS = '@accountSettings/cancel-subscription-at-period-end-success';
export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR = '@accountSettings/cancel-subscription-at-period-end-error';

export const UNSUBSCRIBE = '@accountSettings/unsubscribe';
export const UNSUBSCRIBE_SUCCESS = '@accountSettings/unsubscribe-success';
export const UNSUBSCRIBE_ERROR = '@accountSettings/unsubscribe-error';

export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD = '@accountSettings/update-subscription-payment-method';
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS = '@accountSettings/unsubscribe-success';
export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR = '@accountSettings/unsubscribe-error';

export const CREATE_REFERRAL_CODE = '@accountSettings/create-referral-code';
export const CREATE_REFERRAL_CODE_SUCCESS = '@accountSettings/create-referral-code-success';
export const CREATE_REFERRAL_CODE_ERROR = '@accountSettings/create-referral-code-error';

export const APPLY_REFERRAL_CODE = '@accountSettings/save-referred-salesperson';
export const APPLY_REFERRAL_CODE_SUCCESS = '@accountSettings/save-referred-salesperson-success';
export const APPLY_REFERRAL_CODE_ERROR = '@accountSettings/save-referred-salesperson-error';

export const CONFIRM_CONSULTANT = '@accountSettings/confirm-consultant';
export const CONFIRM_CONSULTANT_SUCCESS = '@accountSettings/confirm-consultant-success';
export const CONFIRM_CONSULTANT_ERROR = '@accountSettings/confirm-consultant-error';

export const UPDATE_FILTERS = '@accountSettings/update-filters';
export const UPDATE_FILTERS_SUCCESS = '@accountSettings/update-filters-success';
export const UPDATE_FILTERS_ERROR = '@accountSettings/update-filters-error';

export const GET_USER_COMPANY_SETTINGS = '@accountSettings/get-user-company-settings';
export const GET_USER_COMPANY_SETTINGS_SUCCESS = '@accountSettings/get-user-company-settings-success';
export const GET_USER_COMPANY_SETTINGS_ERROR = '@accountSettings/get-user-company-settings-error';

export const GET_USER_CAMPAIGNS = '@accountSettings/get-user-campaigns';
export const GET_USER_CAMPAIGNS_SUCCESS = '@accountSettings/get-user-campaigns-success';
export const GET_USER_CAMPAIGNS_ERROR = '@accountSettings/get-user-campaigns-error';

export const SET_FILL_DEFAULT_VALUES = '@accountSettings/set-fill-default-values';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setFormAccountTabOther = (formAccountTabOther) => ({
  type: SET_FORM_ACCOUNT_TAB_OTHER,
  payload: formAccountTabOther
});

export const setFormAccountTabCampaigns = (formAccountTabCampaigns) => ({
  type: SET_FORM_ACCOUNT_TAB_CAMPAIGNS,
  payload: formAccountTabCampaigns
});

export const setCurrentNavigationTab = (tabName) => ({
  type: SET_CURRENT_NAVIGATION_TAB,
  payload: tabName
});

export const getUserInfoById = (salespersonId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(`/api/accountsettings/${salespersonId}`);
    const error = response?.data?.error;
    if (error) {
      dispatch(addNotification(GET_USER_INFO_BY_ID_ERROR, error, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_USER_INFO_BY_ID_ERROR,
      });
      return;
    }
    dispatch({
      type: GET_USER_INFO_BY_ID,
      payload: response?.data || {},
    });
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(GET_USER_INFO_BY_ID_ERROR, error || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_USER_INFO_BY_ID_ERROR,
    });
  }
};

export const getUserCompanySettings = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_COMPANY_SETTINGS,
    });

    const response = await axios.post('/api/accountsettings/companySettings', {});

    const { error } = response?.data || {};
    if (error) {
      dispatch(addNotification(GET_USER_COMPANY_SETTINGS_ERROR, error, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_USER_COMPANY_SETTINGS_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_USER_COMPANY_SETTINGS_SUCCESS,
      payload: response?.data || [],
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_USER_COMPANY_SETTINGS_ERROR, message || errorMessage || error || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_USER_COMPANY_SETTINGS_ERROR,
    });
  }
};

export const getUserCampaigns = (options = {}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_CAMPAIGNS,
    });
    const {
      types,
      status,
    } = options || {};
    const response = await axios.post('/api/accountsettings/userCampaignsList', {
      ...types ? { types } : {},
      ...status ? { status } : {},
    });

    const { error } = response?.data || {};
    if (error) {
      dispatch(addNotification(GET_USER_CAMPAIGNS_ERROR, error, NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: GET_USER_CAMPAIGNS_ERROR,
      });
      return;
    }

    dispatch({
      type: GET_USER_CAMPAIGNS_SUCCESS,
      payload: response?.data || [],
    });
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(GET_USER_CAMPAIGNS_ERROR, message || errorMessage || error || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_USER_CAMPAIGNS_ERROR,
    });
  }
};

export const updateUserApiKey = (userId, apiKey, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const accountChanges = { apiKey };
    const response = await axios.put('/api/accountsettings/apiKey', { accountChanges });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_USER_API_KEY, error, NOTIFICATION_STATUS.ERROR));
      dispatch(setLoading(false));
      return;
    }

    await dispatch(getUserInfoById(userId));
    dispatch(addNotification(UPDATE_USER_API_KEY, 'API Key updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_USER_API_KEY, error || 'Server error', NOTIFICATION_STATUS.ERROR));
    dispatch(setLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateAccountRequired = (userId, accountChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { fillDefaultValues } = getState()?.accountSettings || {};
    const response = await axios.put('/api/accountsettings/required', {
      accountChanges: {
        ...accountChanges,
        fillDefaultValues,
      },
    });
    const responseAddress = await axios.put('/api/accountsettings/requiredhomeaddress', { accountChanges });
    const error = response?.data?.error || responseAddress?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_ACCOUNT_REQUIRED, error, NOTIFICATION_STATUS.ERROR));
      dispatch(getUserInfoById(userId));
      return;
    }

    await dispatch(getUserInfoById(userId));
    await dispatch(getAccountInfo());
    dispatch(addNotification(UPDATE_ACCOUNT_REQUIRED, 'Your settings have been successfully updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ACCOUNT_REQUIRED, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getUserInfoById(userId));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateAccountCampaigns = (userId, accountChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put('/api/accountsettings/campaignstags', { accountChanges });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_ACCOUNT_CAMPAIGNS_TAGS, error, NOTIFICATION_STATUS.ERROR));
      dispatch(getUserInfoById(userId));
      return;
    }

    await dispatch(getUserInfoById(userId));
    dispatch(addNotification(UPDATE_ACCOUNT_CAMPAIGNS_TAGS, 'Your settings have been successfully updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ACCOUNT_CAMPAIGNS_TAGS, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getUserInfoById(userId));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const refreshCampaigns = (userId, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await axios.put('/api/accountsettings/campaignsrefresh');

    await dispatch(getUserInfoById(userId));
    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, 'Your Campaign list has been successfully refreshed.', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getUserInfoById(userId));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateAccountOther = (userId, accountChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put('/api/accountsettings/other', { accountChanges });
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_ACCOUNT_OTHER, error, NOTIFICATION_STATUS.ERROR));
      dispatch(getUserInfoById(userId));
      return;
    }

    await dispatch(getUserInfoById(userId));
    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, 'Your settings have been successfully updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getUserInfoById(userId));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const refreshCustomFields = (userId, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // const response = await axios.put('/api/accountsettings/customfieldsrefresh');
    await axios.put('/api/accountsettings/customfieldsrefresh');

    dispatch(getUserInfoById(userId));
    dispatch(getAccountInfo());

    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, 'Your Custom Fields have been successfully refreshed', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_ACCOUNT_OTHER, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(getUserInfoById(userId));
    dispatch(getAccountInfo());

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateUserPassword = (userId, accountChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.put('/api/accountsettings/pwdchange', accountChanges);
    const error = response?.data?.error;

    if (error) {
      dispatch(addNotification(UPDATE_USER_PASSWORD, error, NOTIFICATION_STATUS.ERROR));
      dispatch(setLoading(false));
      return;
    }

    await dispatch(getUserInfoById(userId));
    dispatch(addNotification(UPDATE_USER_PASSWORD, 'Your password has been successfully updated', NOTIFICATION_STATUS.SUCCESS));

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const error = e?.response?.data?.error;
    dispatch(addNotification(UPDATE_USER_PASSWORD, error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch(setLoading(false));

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const reloadSubscription = () => async (dispatch, getState) => {
  try {
    const { wasPaymentRequestSent } = getState()?.account?.user?.userSubscription || {};
    if (!wasPaymentRequestSent) {
      return;
    }

    const response = await axios.get('/api/usersubscription/');
    dispatch({
      type: RETRIEVE_USER_SUBSCRIPTION,
      payload: response?.data || {}
    });

    if (helpers.isPaymentRequestActive(response?.data?.wasPaymentRequestSent)) {
      setTimeout(() => {
        dispatch(reloadSubscription());
      }, 4000);
    }
  } catch (e) {
    dispatch(addNotification(RETRIEVE_USER_SUBSCRIPTION, 'Server error. Please refresh your page.', NOTIFICATION_STATUS.ERROR));
  }
};

export const getUserSubscription = (withoutNotification = false) => async (dispatch) => {
  dispatch({
    type: GET_USER_SUBSCRIPTION,
  });
  try {
    const response = await axios.get('/api/usersubscription/');

    dispatch({
      type: GET_USER_SUBSCRIPTION_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: GET_USER_SUBSCRIPTION_ERROR,
    });
    if (!withoutNotification) {
      dispatch(addNotification(GET_USER_SUBSCRIPTION_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
    }
  }
};

export const subscribe = (stripePaymentMethodId, subscriptionId) => async (dispatch, getState) => {
  dispatch({
    type: SUBSCRIBE,
  });
  try {
    const { referralCode } = getState()?.subscriptions?.formReferralCodeSendData || {};
    const { isReferredSalespersonId } = getState()?.account?.user?.userSubscription || {};
    const response = await axios.post('/api/usersubscription/subscribe', {
      subscriptionId,
      stripePaymentMethodId,
      ...referralCode && !isReferredSalespersonId ? { referralCode: helpers.removeSpace(referralCode) } : {},
    });

    dispatch({
      type: SUBSCRIBE_SUCCESS,
      payload: response?.data || {}
    });
    dispatch(addNotification(SUBSCRIBE_SUCCESS, 'Thank you! Payment information accepted.', NOTIFICATION_STATUS.SUCCESS));
    dispatch(reloadSubscription());
  } catch (e) {
    const {
      message
    } = e?.response?.data || {};
    dispatch({
      type: SUBSCRIBE_ERROR,
    });
    dispatch(addNotification(SUBSCRIBE_ERROR, message || 'Something went wrong. Payment information not accepted.', NOTIFICATION_STATUS.ERROR));
  }
};

export const cancelSubscriptionAtPeriodEnd = (userSubscriptionId, cancelAtPeriodEnd) => async (dispatch) => {
  dispatch({
    type: CANCEL_SUBSCRIPTION_AT_PERIOD_END,
  });
  try {
    const response = await axios.post('/api/usersubscription/cancel-at-period-end', {
      userSubscriptionId,
      cancelAtPeriodEnd,
    });

    dispatch({
      type: CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR,
    });
    dispatch(addNotification(CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const unsubscribe = (userSubscriptionId) => async (dispatch) => {
  dispatch({
    type: UNSUBSCRIBE,
  });
  try {
    const response = await axios.post('/api/usersubscription/unsubscribe', { userSubscriptionId });

    dispatch({
      type: UNSUBSCRIBE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: UNSUBSCRIBE_ERROR,
    });
    dispatch(addNotification(UNSUBSCRIBE_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const updateSubscriptionPaymentMethod = (userSubscriptionId, stripePaymentMethodId) => async (dispatch) => {
  if (!stripePaymentMethodId || !userSubscriptionId) {
    dispatch(addNotification(UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR, 'Values stripePaymentMethodId or userSubscriptionId are undefined', NOTIFICATION_STATUS.ERROR));
  }

  dispatch({
    type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
  });
  try {
    const response = await axios.put('/api/usersubscription/paymentmethod', {
      userSubscriptionId,
      stripePaymentMethodId,
    });

    dispatch({
      type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
      payload: response?.data || {}
    });
    dispatch(addNotification(UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS, 'Thank you! Payment information accepted.', NOTIFICATION_STATUS.SUCCESS));
    dispatch(reloadSubscription());
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR,
    });
    dispatch(addNotification(UPDATE_SUBSCRIPTION_PAYMENT_METHOD_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const createReferralCode = () => async (dispatch) => {
  dispatch({
    type: CREATE_REFERRAL_CODE,
  });
  try {
    const response = await axios.post('/api/usersubscription/create-referral-code', {});

    dispatch({
      type: CREATE_REFERRAL_CODE_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: CREATE_REFERRAL_CODE_ERROR,
    });
    dispatch(addNotification(CREATE_REFERRAL_CODE_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const applyReferralCode = (values, doWhenTry = () => { }) => async (dispatch) => {
  dispatch({
    type: APPLY_REFERRAL_CODE,
    payload: values,
  });
  try {
    const response = await axios.post('/api/usersubscription/save-referred-salesperson', values);

    dispatch({
      type: APPLY_REFERRAL_CODE_SUCCESS,
      payload: response?.data || {}
    });

    doWhenTry();
  } catch (e) {
    const { message } = e?.response?.data || {};
    dispatch({
      type: APPLY_REFERRAL_CODE_ERROR,
    });
    dispatch(addNotification(APPLY_REFERRAL_CODE_ERROR, message || 'Server error', NOTIFICATION_STATUS.ERROR));
  }
};

export const confirmIsAConsultant = (code, emailProvider, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIRM_CONSULTANT
    });

    const response = await axios.post('/api/emailSettings/confirmIsAConsultant', { code, emailProvider });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(CONFIRM_CONSULTANT_ERROR, 'Could Not Confirm Which Company You are With', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CONFIRM_CONSULTANT_ERROR,
      });
      return;
    }

    dispatch(addNotification(CONFIRM_CONSULTANT_SUCCESS, 'Successfully Confirmed Your Company', NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: CONFIRM_CONSULTANT_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CONFIRM_CONSULTANT_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CONFIRM_CONSULTANT_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const updateFiltersRules = (code, emailProvider, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_FILTERS
    });

    const response = await axios.put('/api/emailSettings/updateFiltersRules', { code, emailProvider });
    const {
      success,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_FILTERS_ERROR, "Could Not (Fully) Update Your Email's Filters/Rules", NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_FILTERS_ERROR,
      });
      return;
    }

    dispatch(addNotification(UPDATE_FILTERS_SUCCESS, "Successfully Updated Your Email's Filters/Rules", NOTIFICATION_STATUS.SUCCESS));

    dispatch({
      type: UPDATE_FILTERS_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_FILTERS_ERROR, message || errorMessage || error || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_FILTERS_ERROR,
    });
    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setFillDefaultValues = (fillDefaultValues) => ({
  type: SET_FILL_DEFAULT_VALUES,
  payload: fillDefaultValues
});
