import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import helpers from 'src/helpers';

function PhoneSelector({
  isToolbarSelector,
  onChange,
  options,
  value,
  ...props
}) {
  const allContactPhonesValue = '';
  const phoneNumbers = useMemo(() => (
    isToolbarSelector ? [allContactPhonesValue, ...options] : options
  ), [isToolbarSelector, options]);

  return (
    <Select
      value={value}
      onChange={onChange}
      displayEmpty
      size="small"
      color="secondary"
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', alignItems: 'center', color: '#545454' }}>
          {!helpers.isEmpty(selected) && <LocalPhoneOutlinedIcon fontSize="inherit" />}
          <Typography variant="caption">
            {helpers.isEmpty(selected)
              ? 'All contact phones'
              : helpers.transform.toPhoneNumber(selected)}
          </Typography>
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            padding: '0 5px',
            borderRadius: '12px',
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '14px',
        },
        '& .MuiSelect-select': {
          paddingY: '4px',
          fontSize: '14px',
        },

      }}
      {...props}
    >
      {phoneNumbers.map((phone) => (
        <MenuItem
          key={phone}
          value={phone}
          sx={{
            color: '#545454',
            borderRadius: '4px',
            padding: '0',
            marginBottom: '8px',

          }}
        >
          <Radio
            checked={value === phone}
            size="small"
            sx={{ padding: '4px' }}
          />
          {!helpers.isEmpty(phone) && <LocalPhoneOutlinedIcon fontSize="inherit" />}
          <Typography variant="caption" alignContent="center">
            {helpers.isEmpty(phone)
              ? 'All contact phones'
              : helpers.transform.toPhoneNumber(phone)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

PhoneSelector.propTypes = {
  isToolbarSelector: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

PhoneSelector.defaultProps = {
  value: '',
  isToolbarSelector: false,
};

export default PhoneSelector;
