import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PerfectScrollbarTable from 'src/components/PerfectScrollbarTable';
import ContactItem from 'src/views/management/ChatView/ChatSidebar/ContactsList/ContactItem';
import LoadMoreButton from 'src/views/management/ChatView/styled/LoadMoreButton';
import {
  getContacts,
  setContactsQuery,
} from 'src/actions/chatActions';
import helpers from 'src/helpers';

const isLoadMore = true;

function ContactsList({ setIsCreateNew }) {
  const dispatch = useDispatch();
  const { isContactsLoading, contacts, query } = useSelector((state) => state?.chat?.allContactsList || {});
  const {
    count,
    page,
    pageSize,
  } = query || {};

  const handleLoadMoreContacts = () => {
    dispatch(setContactsQuery({ page: page + 1 }));
    dispatch(getContacts(isLoadMore));
  };

  const handleClick = () => {
    setIsCreateNew(false);
  };

  return (
    <PerfectScrollbarTable
      options={{ suppressScrollX: true }}
    >
      <Box sx={{ padding: '0 16px' }}>
        <Box>
          <List
            subheader={(
              <ListSubheader
                sx={{
                  display: 'flex',
                  gap: '12px',
                  paddingX: 0,
                  paddingBottom: '4px',
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Contacts
                </Typography>
                {isContactsLoading && (
                  <CircularProgress
                    size="20px"
                    color="secondary"
                  />
                )}
              </ListSubheader>
            )}
          >
            {!helpers.isEmpty(contacts) && contacts.map((contact) => (
              <ContactItem
                key={contact.id}
                contact={contact}
                onClick={handleClick}
              />
            ))}
          </List>
          <Box
            sx={{
              marginBottom: '12px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {(!helpers.isEmpty(contacts) && contacts.length >= pageSize) && (
              <LoadMoreButton
                startIcon={<KeyboardArrowDownIcon />}
                disabled={isContactsLoading || contacts.length === count}
                onClick={handleLoadMoreContacts}
              >
                Load more
              </LoadMoreButton>
            )}
          </Box>
        </Box>
      </Box>
    </PerfectScrollbarTable>
  );
}

ContactsList.propTypes = {
  setIsCreateNew: PropTypes.func.isRequired,
};

export default ContactsList;
