export const SIGNUP_NEXT_STEP = {
  FIRST_SCREEN_GET_EMAIL_ADDRESS: 'Get Email Address',
  SECOND_SCREEN_GET_CONTACT_INFO: 'Get Contact Info',
  SECOND_SCREEN_GET_BUSINESS_INFO: 'Get Contact Info - Business Info',
  SECOND_SCREEN_GET_REFERRAL_CODE: 'Get Contact Info - Referral Code',
  CONFIRM_CONSULTANT_AND_COMPANY: 'Confirm Consultant & Company',
  FOLLOW_INSTRUCTIONS: 'Follow Instructions',
  CREATE_ACCOUNT: 'Create Account',
  WATCH_WELCOME_VIDEO: 'Watch Welcome Video',
  CONTACT_US: 'Contact Us',
  ADD_CP_FORWARDING_ADDRESS: 'Add CP Forwarding Address',
  CREATE_FILTERS: 'Create Filters or Rules',
  SIGN_IN: 'Sign-In',
};
