import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import TextFieldFormik from 'src/components/TextFieldFormik';
import DivForm from 'src/components/ModalDialogMUI6/styled/DivForm';
import { STYLE_OPTIONS } from 'src/constants';

const SCHEDULE_FIELD = 'scheduledDateTime';

function ModalScheduleMessage({
  isModalVisible,
  onCloseModal,
  formik,
  header,
  title,
}) {
  const {
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleSubmit,
    values,
  } = formik;

  const handleChangeDate = (name, date) => {
    if (moment(date).isValid()) {
      setFieldValue(name, moment(date), true);
      return;
    }
    setFieldValue(name, date, true);
  };

  const handleSchedule = () => {
    handleSubmit();
    if (!errors.scheduledDateTime) {
      onCloseModal();
    }
  };

  const handleClose = () => {
    handleChangeDate(SCHEDULE_FIELD, null);
    onCloseModal();
  };

  return (
    <Dialog
      open={isModalVisible}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          padding: '24px',
        },
      }}
    >
      <Box
        sx={{
          padding: '0 0 0 16px',
          textAlign: 'center',
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '18px',
            padding: 0,
          }}
        >
          {header}
        </DialogTitle>
        <Typography
          variant="subtitle1"
          color="#546E7A"
        >
          {title}
        </Typography>
      </Box>
      <DivForm>
        <TextFieldFormik
          touchedValue={touched.scheduledDateTime}
          errorValue={errors.scheduledDateTime}
          name={SCHEDULE_FIELD}
          handleChange={(date) => { handleChangeDate(SCHEDULE_FIELD, date); }}
          handleBlur={handleBlur}
          value={values.scheduledDateTime}
          label="Schedule Date & Time to Send"
          fieldType="dateTime"
          minDate={new Date().toISOString()}
        />
      </DivForm>
      <DialogActions
        sx={{
          padding: 0,
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            ...STYLE_OPTIONS.BUTTON_PRIMARY_REVERSED,
            minWidth: '105px',
            marginRight: '14px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSchedule}
          disabled={!values.scheduledDateTime}
          sx={{
            ...STYLE_OPTIONS.BUTTON_PRIMARY,
            minWidth: '105px',
          }}
        >
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalScheduleMessage.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ModalScheduleMessage;
