import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import { STYLE_OPTIONS } from 'src/constants';

function ErrorBox({
  onClick,
  errorTitle,
  errorMessage,
  buttonMessage,
  maxWidth,
}) {
  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        width: '100%',
        padding: '25px 25px',
        margin: '0px auto',
        overflow: 'hidden',
        boxShadow: '0px 0px 0px 1px #D83939',
        borderRadius: '20px',
        backgroundColor: '#FBF3F3',
        boxSizing: 'border-box',
        ...maxWidth ? { maxWidth } : {},
        '&:before': {
          position: 'absolute',
          display: 'block',
          left: '0px',
          top: '0px',
          width: '8px',
          height: '100%',
          content: '" "',
          backgroundColor: '#ED3F3F',
        },
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'relative',
          width: '100%',
          color: '#F15642',
          paddingBottom: '4px',
          '& svg': {
            width: '28px',
            height: '28px',
          },
        }}
      >
        <ErrorIcon />
      </Box>
      {errorTitle && (
        <Box
          component="div"
          sx={{
            padding: '10px 0px 4px 0px',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 500,
            fontFamily: STYLE_OPTIONS.FONT_FAMILY,
            color: '#263238',
          }}
        >
          {errorTitle}
        </Box>
      )}
      {errorMessage && (
        <Box
          component="div"
          sx={{
            padding: '4px 0px',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
            fontFamily: STYLE_OPTIONS.FONT_FAMILY,
            color: '#546E7A',
          }}
        >
          {errorMessage}
        </Box>
      )}
      {buttonMessage && (
        <Box
          component="div"
          sx={{
            padding: '16px 0px 1px 0px',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 400,
            fontFamily: STYLE_OPTIONS.FONT_FAMILY,
            '& .MuiButton-root': {
              fontFamily: STYLE_OPTIONS.FONT_FAMILY,
              lineHeight: '24px',
              fontSize: '14px',
              fontWeight: 400,
              color: '#FFFFFF',
              backgroundColor: '#ED3F3F',
              borderRadius: '4px',
              textTransform: 'none',
              padding: '5px 18px',
              boxShadow: '0px 0px 0px 1px #F49999',
              transitionDuration: '0.3s',
              '&:hover': {
                color: '#ED3F3F',
                boxShadow: '0px 0px 0px 1px #ED3F3F',
                backgroundColor: '#FFFFFF',
              },
            },
          }}
        >
          <Button
            {...onClick ? { onClick } : {}}
          >
            {buttonMessage}
          </Button>
        </Box>
      )}
    </Box>
  );
}

ErrorBox.propTypes = {
  onClick: PropTypes.func,
  errorTitle: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  buttonMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  maxWidth: PropTypes.string,
};

ErrorBox.defaultProps = {
  onClick: () => {},
  errorTitle: null,
  errorMessage: null,
  buttonMessage: null,
  maxWidth: null,
};

export default ErrorBox;
