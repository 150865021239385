import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Typography,
} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ScheduledMessage from 'src/views/management/ChatView/ChatDetails/ScheduledMessage';
import helpers from 'src/helpers';

const YELLOW_50 = '#FFFDE7';
const YELLOW_400 = '#FDD835';
const YELLOW_700 = '#A16207';
const YELLOW_800 = '#854D0E';

function ScheduledMessagesList({
  children,
  scheduledMessages,
  userRolePermissions,
  userCanDeleteTexts,
}) {
  const scheduledMessagesRef = useRef(null);

  useEffect(() => {
    if (scheduledMessagesRef.current) {
      scheduledMessagesRef.current?.scrollIntoView();
    }
  }, []);

  if (helpers.isEmpty(scheduledMessages)) return null;

  return (
    <Box>
      <Box
        ref={scheduledMessagesRef}
        sx={{
          width: {
            xs: '100%',
            md: 'max-content',
          },
          marginX: 'auto',
          marginBottom: '32px',
        }}
      >
        <Alert
          severity="warning"
          icon={<WarningRoundedIcon sx={{ fill: YELLOW_400 }} />}
          sx={{
            padding: '16px 20px',
            backgroundColor: YELLOW_50,
            borderRadius: '0',
            borderLeft: '8px solid',
            borderColor: YELLOW_400,
          }}
        >
          <Typography
            variant="subtitle2"
            color={YELLOW_800}
            component="span"
            sx={{
              lineHeight: 'inherit',
              marginRight: '2px'
            }}
          >
            Notice:
          </Typography>
          <Typography
            variant="body2"
            color={YELLOW_700}
            component="span"
          >
            The following messages are scheduled and will be sent automatically.
          </Typography>
          <br />
          <Typography
            variant="body2"
            color={YELLOW_700}
            component="span"
          >
            Click on
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            component="span"
            sx={{
              backgroundColor: '#EEEEEE',
              borderRadius: '2px',
              marginX: '4px',
              paddingX: '4px',
            }}
          >
            {`Scheduled messages (${scheduledMessages.length})`}
          </Typography>
          <Typography
            variant="body2"
            color={YELLOW_700}
            component="span"
          >
            button below to hide them.
          </Typography>
        </Alert>
      </Box>
      {scheduledMessages.map((scheduledMessage) => (
        <ScheduledMessage
          key={scheduledMessage.id}
          message={scheduledMessage}
          userRolePermissions={userRolePermissions}
          userCanDeleteTexts={userCanDeleteTexts}
        />
      ))}
      {children}
    </Box>
  );
}

ScheduledMessagesList.propTypes = {
  children: PropTypes.node,
  scheduledMessages: PropTypes.array,
  userRolePermissions: PropTypes.array,
  userCanDeleteTexts: PropTypes.bool,
};

ScheduledMessagesList.defaultProps = {
  children: null,
  scheduledMessages: [],
  userRolePermissions: [],
  userCanDeleteTexts: false,
};

export default ScheduledMessagesList;
