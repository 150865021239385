import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import helpers from 'src/helpers';
import { STORAGE } from 'src/constants';

function AttachedFilePrewiew({
  fileName,
  fileUpload,
  isLoading,
  setFileUploadToMessage,
  validateFileInput,
}) {
  const getFileToSrc = (file) => (file ? URL.createObjectURL(file) : null);

  const handleRemoveSelectedFile = () => {
    setFileUploadToMessage(null);
    validateFileInput(false);
  };

  return (
    <Badge
      overlap="circular"
      size="small"
      badgeContent={(
        <IconButton
          size="small"
          onClick={handleRemoveSelectedFile}
          disabled={isLoading}
          sx={{
            width: 18,
            height: 18,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 1)',
            },
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    >
      <Avatar
        alt="Attachment Preview"
        src={fileUpload ? getFileToSrc(fileUpload?.file) : helpers.azure.addSasToUrl(fileName, STORAGE.sas)}
        variant="rounded"
        sx={{
          width: 78,
          height: 78,
        }}
      />
    </Badge>
  );
}

AttachedFilePrewiew.propTypes = {
  setFileUploadToMessage: PropTypes.func.isRequired,
  validateFileInput: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  fileUpload: PropTypes.any,
  isLoading: PropTypes.bool,
};

AttachedFilePrewiew.defaultProps = {
  fileName: null,
  fileUpload: false,
  isLoading: false
};

export default AttachedFilePrewiew;
