/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  addMessageSuccessHandlers,
  deleteChatMessageSuccessHandlers,
  getMoreChatMessagesSuccessHandlers,
  sendNowOrEditScheduledMessageSuccessHandlers,
  socketChatMessageDeleteHandlers,
  socketChatScheduledMessageDeleteHandlers,
  socketChatNewMessageHandlers,
  socketChatScheduledMessageUpdateHandlers,
  socketChatMessageUpdateHandlers,
} from 'src/handlers/chatHandlers';
import {
  ADD_MESSAGE,
  ADD_MESSAGE_ERROR,
  ADD_MESSAGE_SUCCESS,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGE_ERROR,
  DELETE_CHAT_MESSAGE_SUCCESS,
  GET_MORE_CHAT_MESSAGES,
  GET_MORE_CHAT_MESSAGES_ERROR,
  GET_MORE_CHAT_MESSAGES_SUCCESS,
  GET_MORE_SCHEDULED_CHAT_MESSAGES,
  GET_MORE_SCHEDULED_CHAT_MESSAGES_SUCCESS,
  GET_MORE_SCHEDULED_CHAT_MESSAGES_ERROR,
  GET_CHATS_LIST,
  GET_CHATS_LIST_ERROR,
  GET_CHATS_LIST_SUCCESS,
  GET_CONTACTS,
  GET_CONTACTS_ERROR,
  GET_CONTACTS_SUCCESS,
  GET_FILTERED_CHAT_MESSAGES,
  GET_FILTERED_CHAT_MESSAGES_ERROR,
  GET_FILTERED_CHAT_MESSAGES_SUCCESS,
  CLEAR_FILTERED_CHAT_MESSAGES,
  MARK_CHAT_AS_SEEN_SUCCESS,
  MARK_CHAT_AS_UNREAD_SUCCESS,
  SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE,
  SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_ERROR,
  SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_SUCCESS,
  SET_CONTACTS_QUERY,
  SET_CURRENT_MESSAGE,
  SET_FILE_UPLOAD_TO_MESSAGE,
  SOCKET_CHAT_TOTAL_UNREAD_COUNT,
  SOCKET_CHAT_NEW_MESSAGE,
  SOCKET_CHAT_NEW_SCHEDULED_MESSAGE,
  SOCKET_CHAT_MESSAGE_DELETE,
  SOCKET_CHAT_SCHEDULED_MESSAGE_DELETE,
  SOCKET_CHAT_SCHEDULED_MESSAGE_UPDATE,
  SOCKET_CHAT_MESSAGE_SENT,
  SOCKET_CHAT_SCHEDULED_MESSAGE_SENT,
  SOCKET_CHAT_MESSAGE_READ,
  SOCKET_CHAT_MESSAGE_DELIVERED,
} from 'src/actions/chatActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isChatsLoading: false,
  isMessagesLoading: false,
  isMessageSending: false,
  isMessageUpdating: false,
  chatsContacts: {
    byId: {},
    allIds: []
  },
  chats: {
    byKey: {},
    allKeys: []
  },
  filteredChats: {
    byKey: {},
    allKeys: []
  },
  allContactsList: {
    isContactsLoading: false,
    contacts: [],
    query: {
      search: '',
      sort: [['firstName', 'asc'], ['lastName', 'asc']],
      page: 1,
      pageSize: 50,
      count: 0,
    },
  },
  userCanDeleteTexts: false,
  totalUnreadCount: 0,
  currentMessage: {},
  messagesFilteredByPhone: '',
};

const chatReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHATS_LIST: {
      return produce(state, (draft) => {
        draft.isChatsLoading = true;
      });
    }

    case GET_CHATS_LIST_SUCCESS: {
      const {
        chats,
        contacts,
        canDeleteTexts,
      } = payload;
      let totalUnreadCount = 0;
      const chatsWithNextCursor = {
        byKey: chats?.byKey || {},
        allKeys: chats?.allKeys || [],
      };
      chatsWithNextCursor.allKeys.forEach((key) => {
        const chat = chatsWithNextCursor.byKey[key];
        totalUnreadCount += chat?.unreadCount || 0;
        if (chat?.messages?.length) {
          chat.nextCursor = chat.messages[0].scheduledAt;
        }

        if (chat?.scheduledMessages?.length) {
          chat.scheduledNextCursor = chat.scheduledMessages.at(-1).scheduledAt;
        }
      });

      const chatsContacts = {
        byId: contacts?.byId || {},
        allIds: contacts?.allIds || [],
      };
      return produce(state, (draft) => {
        draft.chats = chatsWithNextCursor;
        draft.chatsContacts = chatsContacts;
        draft.userCanDeleteTexts = canDeleteTexts || false;
        draft.totalUnreadCount = totalUnreadCount;
        draft.isChatsLoading = false;
      });
    }

    case GET_CHATS_LIST_ERROR: {
      return produce(state, (draft) => {
        draft.isChatsLoading = false;
      });
    }

    case GET_CONTACTS: {
      return produce(state, (draft) => {
        draft.allContactsList.isContactsLoading = true;
      });
    }

    case GET_CONTACTS_SUCCESS: {
      const { isLoadMore, data } = payload;
      return produce(state, (draft) => {
        if (isLoadMore) {
          draft.allContactsList.contacts.push(...(data?.contacts || []));
        } else {
          draft.allContactsList.contacts = data?.contacts || [];
        }
        draft.allContactsList.query.count = data?.count || 0;
        draft.allContactsList.isContactsLoading = false;
      });
    }

    case GET_CONTACTS_ERROR: {
      return produce(state, (draft) => {
        draft.allContactsList.isContactsLoading = false;
      });
    }

    case SET_CONTACTS_QUERY: {
      return produce(state, (draft) => {
        draft.allContactsList.query = payload ? {
          ...state.allContactsList.query,
          ...payload
        } : initialState.allContactsList.query;
      });
    }

    case GET_MORE_CHAT_MESSAGES: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = true;
      });
    }

    case GET_MORE_CHAT_MESSAGES_SUCCESS: {
      return getMoreChatMessagesSuccessHandlers(state, payload);
    }

    case GET_MORE_CHAT_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = false;
      });
    }

    case GET_MORE_SCHEDULED_CHAT_MESSAGES: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = true;
      });
    }

    case GET_MORE_SCHEDULED_CHAT_MESSAGES_SUCCESS: {
      const {
        contactId,
        data
      } = payload;
      const {
        messages = [],
        nextCursor = null,
      } = data;
      return produce(state, (draft) => {
        const filteredChat = draft.filteredChats.byKey[contactId];
        if (!filteredChat) return;

        filteredChat.scheduledMessages.push(...messages);
        filteredChat.nextCursor = nextCursor;
        draft.isMessagesLoading = false;
      });
    }

    case GET_MORE_SCHEDULED_CHAT_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = false;
      });
    }

    case GET_FILTERED_CHAT_MESSAGES: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = true;
      });
    }

    case GET_FILTERED_CHAT_MESSAGES_SUCCESS: {
      const {
        chatKey,
        filteredByPhone,
        regularMessages,
        scheduledMessages
      } = payload;

      return produce(state, (draft) => {
        if (!draft.filteredChats.byKey[chatKey]) {
          draft.filteredChats.byKey[chatKey] = {
            key: chatKey,
            messages: [],
            nextCursor: null,
            scheduledMessages: [],
            scheduledNextCursor: null,
          };
        }
        draft.filteredChats.byKey[chatKey].messages = regularMessages.messages;
        draft.filteredChats.byKey[chatKey].nextCursor = regularMessages.nextCursor;
        draft.filteredChats.byKey[chatKey].scheduledMessages = scheduledMessages.messages;
        draft.filteredChats.byKey[chatKey].scheduledNextCursor = scheduledMessages.nextCursor;
        draft.messagesFilteredByPhone = filteredByPhone;
        draft.isMessagesLoading = false;
      });
    }

    case GET_FILTERED_CHAT_MESSAGES_ERROR: {
      return produce(state, (draft) => {
        draft.isMessagesLoading = false;
      });
    }

    case CLEAR_FILTERED_CHAT_MESSAGES: {
      return produce(state, (draft) => {
        draft.filteredChats = initialState.filteredChats;
        draft.messagesFilteredByPhone = initialState.messagesFilteredByPhone;
      });
    }

    case MARK_CHAT_AS_SEEN_SUCCESS: {
      const { chatKey, updatedMessages } = payload;
      const updates = objFromArray(updatedMessages);

      return produce(state, (draft) => {
        const chat = draft.chats.byKey?.[chatKey];
        if (!chat?.messages) return;

        chat.unreadCount = Math.max(chat.unreadCount - updatedMessages.length, 0);
        chat.messages = chat.messages.map((message) => (
          updates[message.id] ? { ...message, ...updates[message.id] } : message
        ));
      });
    }

    case MARK_CHAT_AS_UNREAD_SUCCESS: {
      const { chatKey, updatedMessages } = payload;
      const updates = objFromArray(updatedMessages);

      return produce(state, (draft) => {
        const chat = draft.chats.byKey?.[chatKey];
        if (!chat?.messages) return;

        chat.unreadCount += updatedMessages.length;
        chat.messages = chat.messages.map((message) => (
          updates[message.id] ? { ...message, ...updates[message.id] } : message
        ));
      });
    }

    case ADD_MESSAGE: {
      return produce(state, (draft) => {
        draft.isMessageSending = true;
      });
    }

    case ADD_MESSAGE_SUCCESS: {
      return addMessageSuccessHandlers(state, payload);
    }

    case ADD_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.isMessageSending = false;
      });
    }

    case SOCKET_CHAT_TOTAL_UNREAD_COUNT: {
      const {
        totalUnreadCount,
      } = payload || {};

      return produce(state, (draft) => {
        draft.totalUnreadCount = totalUnreadCount || 0;
      });
    }

    case SOCKET_CHAT_NEW_MESSAGE:
    case SOCKET_CHAT_NEW_SCHEDULED_MESSAGE: {
      return socketChatNewMessageHandlers(state, payload);
    }

    case SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE: {
      return produce(state, (draft) => {
        draft.isMessageSending = true;
      });
    }

    case SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_SUCCESS: {
      return sendNowOrEditScheduledMessageSuccessHandlers(state, payload);
    }

    case SEND_NOW_OR_EDIT_SCHEDULED_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.isMessageSending = false;
      });
    }

    case DELETE_CHAT_MESSAGE: {
      return produce(state, (draft) => {
        draft.isMessageUpdating = true;
      });
    }

    case DELETE_CHAT_MESSAGE_SUCCESS: {
      return deleteChatMessageSuccessHandlers(state, payload);
    }

    case DELETE_CHAT_MESSAGE_ERROR: {
      return produce(state, (draft) => {
        draft.isMessageUpdating = false;
      });
    }

    case SOCKET_CHAT_MESSAGE_DELETE: {
      return socketChatMessageDeleteHandlers(state, payload);
    }

    case SOCKET_CHAT_SCHEDULED_MESSAGE_DELETE: {
      return socketChatScheduledMessageDeleteHandlers(state, payload);
    }

    case SOCKET_CHAT_SCHEDULED_MESSAGE_UPDATE:
    case SOCKET_CHAT_SCHEDULED_MESSAGE_SENT: {
      return socketChatScheduledMessageUpdateHandlers(state, payload);
    }

    case SOCKET_CHAT_MESSAGE_SENT:
    case SOCKET_CHAT_MESSAGE_DELIVERED: {
      return socketChatMessageUpdateHandlers(state, payload);
    }

    case SOCKET_CHAT_MESSAGE_READ: {
      const newPayload = {
        ...payload,
        isMessageReadStatusChanged: true,
      };

      return socketChatMessageUpdateHandlers(state, newPayload);
    }

    case SET_CURRENT_MESSAGE: {
      return produce(state, (draft) => {
        draft.currentMessage = payload;
      });
    }

    case SET_FILE_UPLOAD_TO_MESSAGE: {
      const { isMessageEdit, file } = payload;
      return produce(state, (draft) => {
        draft.currentMessage = {
          ...state.currentMessage,
          [isMessageEdit ? 'fileToReplace' : 'fileUpload']: file,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
